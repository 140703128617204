import { Dimensions, Platform } from 'react-native';

/**
 * This file exports 3 functions and 2 variables for handling responsive design.
 * Example: https://medium.com/simform-engineering/create-responsive-design-in-react-native-f84522a44365#26ee
*/
const fullHeight = Dimensions.get('window').height;
const fullWidth = Dimensions.get('window').width;

const guidelineBaseWidth = 375;
const guidelineBaseHeight = 812;

const horizontalScale = (size) => (fullWidth / guidelineBaseWidth) * size;
const verticalScale = (size) => (fullHeight / guidelineBaseHeight) * size;
const moderateScale = (size, factor = 0.5) => size + (horizontalScale(size) - size) * factor;
const isWeb = Platform.OS === "web";

export { horizontalScale, verticalScale, moderateScale, fullWidth, fullHeight, isWeb };