import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../contexts/firebaseContext';
import { useMatomo } from 'matomo-tracker-react-native';
import * as Application from 'expo-application';
import {isWeb} from '../utils/responsive';
import Constants from 'expo-constants';

const APP_ENV = process.env.APP_ENV;

export default function AppMatomo() {
    const { trackAppStart } = useMatomo();
    const { firebaseUser } = useContext(FirebaseContext);

    const [matomoParams, setMatomoParams] = useState({
        environment: APP_ENV,
        version: isWeb ? Constants.manifest.version : `${Application.nativeApplicationVersion}.${Application.nativeBuildVersion}`,
    });

    useEffect(() => {
        setMatomoParams({
            environment: APP_ENV,
            version: isWeb ? Constants.manifest.version : `${Application.nativeApplicationVersion}.${Application.nativeBuildVersion}`,
            ...(firebaseUser ? { uid: firebaseUser.email } : {})
        })
    }, [firebaseUser])

    useEffect(() => {
        trackAppStart({ userInfo: matomoParams })
    }, [matomoParams])

    return (
        <></>
    );
}
