import React, { createContext, useContext, useEffect, useState } from 'react';
import { AxiosContext } from './axiosContext';
import { UserContext } from './userContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import reportError from '../utils/reportError';

export const ContractContext = createContext({
    contracts: [],
    loadContracts: () => Promise.resolve(),
    loadContract: () => Promise.resolve(),
    contract: null,
    setContract: () => Promise.resolve(),
    isInitialLoading: false,
    setIsInitialLoading: (v) => v,
});

export const ContractProvider = ({ children }) => {
    const [contracts, setContracts] = useState([]);
    const [contract, setCurrentContract] = useState(null);

    const [isInitialLoading, setIsInitialLoading] = useState(false);
    const [isCurrentContractLoading, setIsCurrentContractLoading] = useState(false);

    const { authAxios } = useContext(AxiosContext);
    const { user } = useContext(UserContext);

    const loadContracts = async () => {
        return await authAxios.get('ikos/contracts', {
            params: {
                query: `{
                    id, code, contractRef, label, startDate, endDate
                    
                    contractPersons {
                        person {
                            title, code, lastName, name
                        }
                    }
                    contractBuildingUnits {
                        isMain, startDate, endDate
                        buildingUnit {
                            door, 
                            building {
                              address { code, streetName, streetName2, postCode, city, latitude, longitude }
                            }
                        }
                    }
                }`
            }
        })
            .then(res => res.data)
            .then(res => {
                console.log(`ContractContext contracts loaded...`)
                setContracts(res)
                return res;
            })
            .catch(err => {
                reportError(true, err, 'Une erreur est survenue lors du chargement de vos contrats. Veuillez réessayer ultérieurement ou contacter ACM Habitat pour obtenir de l\'aide.')
                throw err;
            })
            .finally(() => AsyncStorage.setItem('lastActive', new Date().getTime().toString()))
    }

    const loadContract = async (id) => {
        setIsCurrentContractLoading(true);

        const requestCreationDate = moment().add(-12, 'month').format('YYYY-MM-DD');
        const relationshipEndDate = moment().add(1, 'month').format('YYYY-MM-DD');

        return await authAxios.get(`ikos/contracts/${id}`, {
            params: {
                query: `{
                    id, code, contractRef, label, startDate, endDate
                    contractEvents {
                        eventType { code }
                        startDate,
                        endDate
                    }
                    contractPersons {
                        isMain
                        startDate, endDate
                        role { code }
                        person {
                            id, code, title, name, lastName, birthDate, mail, mobilePhone, homePhone, sex
                            userPersons {
                                user { id, email, provider, lastLoginDate }
                            }
                        }
                    }
                    contractBuildingUnits {
                        isMain, startDate, endDate
                        buildingUnit {
                            id,
                            door, 
                            modelEquipment { code, label, orgLabel }
                            nature { code label orgLabel }
                            agency { code, label, streetName, streetName2, postCode, city, mail, phoneNumber, faxNumber }
                            building {
                              label
                              address { code, streetName, streetName2, postCode, city, latitude, longitude },
                            }
                        }
                    }
                    contractBalanceRecords(take: 5) {
                        id
                        operationType, operationDate, operationRank
                        description
                        amount, balanceAmount
                        startPeriodDate, endPeriodDate
                    }

                    contractRequests(
                        creationDate: "${requestCreationDate}"
                    ) {
                        code
                        requestBody
                        requestTopic
                        creationDate, updateDate
                        requestType { code, label }
                        requestStatus { code, label }
                        requestCategory { isVisible }
                        contactOrigin { code }
                        requestSteps {
                            stepRank
                            comment
                            stepDecision { code, label, isVisible }
                            stepType { code, label, isVisible }
                            stepStatus { code, label }
                            startDate, endDate
                        }
                    }
                    personRelationships(endDate: "${relationshipEndDate}") { startDate, endDate }
                }`
            }
        })
            .then(res => res.data)
            .then(res => setCurrentContract(res))
            .catch(err => {
                reportError(true, err, `Le contrat ${id} n'a pas été trouvé. Veuillez réessayer ultérieurement ou contacter ACM Habitat pour obtenir de l'aide.`)
            })
            .finally(() => setIsCurrentContractLoading(false))
    }

    useEffect(() => {
        if (!user) {
            setContracts([]);
            setCurrentContract(null);
        }
    }, [user]);

    return (
        <ContractContext.Provider value={{
            contracts,
            loadContracts,
            loadContract,
            contract,
            isInitialLoading,
            setIsInitialLoading,
            isCurrentContractLoading,
        }}>
            {children}
        </ContractContext.Provider>
    );
};