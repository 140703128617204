import Constants from 'expo-constants';
import React, { useContext } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import PeopleEnjoyingIcon from "../../../assets/image/people-enjoying-icon2.svg";
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import { CommunicationsContext } from '../../../core/contexts/communicationsContext';
import Loader from '../../../core/ui/Loader';
import Text from '../../../core/ui/Text';
import OptionsSection from '../components/OptionsSection';

const MoreOptions = () => {
    const { isLoading } = useContext(CommunicationsContext);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <View style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={[styles.container, customStyles.pageContainer]}>
                <Text style={customStyles.pageTitle}>Plus</Text>
                <OptionsSection />
                <View style={styles.versionContainer}>
                    <Text style={styles.version}>Version {Constants.manifest.version}.{Constants.expoConfig.extra.web?.versionCode||0}</Text>
                </View>
            </ScrollView>
            <PeopleEnjoyingIcon style={styles.peopleIcon} />
        </View >
    )
}
export default MoreOptions;

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        paddingHorizontal: 15,
        paddingBottom: 30,
    },
    versionContainer: {
        marginTop: 10,
        marginBottom: 20,
        alignSelf: 'left',
    },
    version: {
        alignSelf: 'center',
        color: colors.primary200,
        fontSize: fonts.subTitle,
    },
    peopleIcon: {
        position: "absolute",
        zIndex: -1,
        bottom: 0,
        right: 0
    },
});