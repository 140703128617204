import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import UserHome from './screens/UserHome';
import PdfPreview from './screens/PdfPreview';
import IconButton from '../../core/ui/IconButton';
import { defaultHeaderStyles } from './components/Header';
import LeftArrowIcon from "../../assets/image/white-left-arrow-icon.svg"
import { colors } from '../../core/constants/colors';

const Stack = createStackNavigator();

const Home = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name="UserHome" component={UserHome} options={{ headerShown: false }} />
            <Stack.Screen name="PdfPreview" component={PdfPreview}
                options={{
                    headerStyle: defaultHeaderStyles.headerStyle,
                    headerTitleStyle: defaultHeaderStyles.headerTitleStyle,
                    headerTintColor: colors.light,
                    headerTitleAlign: "center",
                    title: "Aperçu du PDF",
                    headerLeft: ({ onPress }) => (
                        <IconButton
                            style={defaultHeaderStyles.leftArrowIcon}
                            icon={<LeftArrowIcon />}
                            onPress={onPress}
                            accessibilityLabel="Retour"
                        />
                    ),
                }}
            />
        </Stack.Navigator>
    )
}

export default Home