import React, { useContext } from "react";
import HouseAvatarIcon from "../../assets/image/avatar-house-icon.svg";
import HouseCarIcon from "../../assets/image/house-car-icon.svg";
import { ContractContext } from '../../core/contexts/contractContext';

const address = (item) => item?.buildingUnit?.building?.address;

export function useContractInfo() {
    const { contract, isLoading } = useContext(ContractContext);

    const filteredContractPersons = contract?.contractPersons
        ?.filter((i) => !i.endDate || new Date(i.endDate) > new Date()) || [];
    const contractors = filteredContractPersons?.filter((it) => it.role?.code === 'C') || [];
    const occupants = filteredContractPersons?.filter((it) => it.role?.code === 'O') || [];

    const _lease = contract?.contractBuildingUnits
        ?.filter((i) => !i.endDate || new Date(i.endDate) > new Date())
        ?.sort((a, b) => (a.isMain > b.isMain ? -1 : b.isMain > a.isMain ? 1 : 0)) || [];

    const leasing = (_lease.length > 0 ? _lease : []).map((item) => {
        let icon;
        switch (item?.buildingUnit?.nature?.code) {
            case 'GAR':
            case 'PRK':
                icon = <HouseCarIcon aria-label="icône maison et voiture" />;
                break;
            default:
                icon = <HouseAvatarIcon aria-label="icône maison et avatar" />;
        }

        return {
            icon: icon,
            title: item?.buildingUnit?.building?.label,
            address: `${[
                typeof address(item)?.streetName === 'string' ? address(item)?.streetName.trim() : "",
                typeof address(item)?.streetName2 === 'string' ? address(item)?.streetName2.trim() : "",
                typeof item.buildingUnit?.door === 'string' ? `\nPorte N°${item.buildingUnit?.door.trim()}` : ""]
                .filter((i) => i)
                .join(' ')
                } \n ${typeof address(item)?.postCode === 'string' ? address(item)?.postCode.trim() : ''} ${typeof address(item)?.city === 'string' ? address(item)?.city.trim() : ''}`,
            info: `${item?.buildingUnit?.modelEquipment?.label || item?.buildingUnit?.nature?.orgLabel || ""} - Occupé depuis le ${item?.startDate && new Date(item?.startDate).toLocaleDateString('fr')}`,
        };
    }) || [];

    return { contractors, occupants, leasing, isLoading };
}