import moment from 'moment';
import { useContext, useEffect } from 'react';
import { ContractContext } from '../contexts/contractContext';
import useDataFetch from './useDataFetch';

const usePaymentsInfo = () => {
    const { contract } = useContext(ContractContext);

    const recordOperationDate = moment(contract?.contractBalanceRecords?.map((i) => i.operationDate)?.[0])
        .add(-12, 'month')
        .format('YYYY-MM-DD');

    const { data, isLoading, controller } = useDataFetch(`ikos/contracts/${contract?.id}`, {
        params: {
            query: `{
                id, code
                contractBalanceRecords(operationDate: "${recordOperationDate}") {
                    id
                    operationType, operationDate, operationRank, isPending
                    description
                    amount, balanceAmount
                    startPeriodDate, endPeriodDate
                }
                contractDocuments(documentType: "FONAVE", take: 12) {
                    id code fileName
                    storageCode
                    creationDate
                }
            }`,
        },
    }, [contract], contract,)

    useEffect(() => {
        if (controller) controller.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract])

    const payments = data?.contractDocuments?.sort((a, b) => {
        return (a && b && a.creationDate && b.creationDate) ? new Date(b.creationDate) - new Date(a.creationDate) : 0;
    }) || [];
    const paymentRecords = data?.contractBalanceRecords || [];

    return {
        payments,
        paymentRecords,
        isLoading,
    };
};

export default usePaymentsInfo;
