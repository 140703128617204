import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { colors } from '../constants/colors';

const RadioButtons = ({ value, onValueChange, data, containerStyle, radioButtonsStyle }) => {

    const handleButtonPress = (buttonValue) => {
        onValueChange && onValueChange(buttonValue)
    };

    return (
        <View
            style={containerStyle}
            testID="radio-buttons-container"
        >
            {
                data?.map((item, i) => (
                    <Pressable
                        key={i}
                        onPress={() => handleButtonPress(item.value)}
                        style={[styles.button, radioButtonsStyle]}
                        testID={`radio-button-option`}
                        accessibilityRole='button'
                        accessibilityLabel={"payer par " + item.value}
                    >
                        <View
                            style={[
                                styles.dot,
                                value === item.value && {
                                    backgroundColor: colors.secondary300
                                }
                            ]}
                            testID={`radio-button-dot-${item.id}`}
                        />
                    </Pressable>
                ))
            }
        </View>
    );
};

export default RadioButtons;

const styles = StyleSheet.create({
    button: {
        height: 22,
        width: 22,
        backgroundColor: colors.light,
        borderRadius: 50,
        borderWidth: 1,
        borderColor: colors.grey,
        justifyContent: "center",
        alignItems: "center"
    },
    dot: {
        height: 12,
        width: 12,
        borderRadius: 50,
    }
})
