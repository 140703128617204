import { Pressable, StyleSheet, View } from 'react-native'
import React, { useContext } from 'react'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import StatusInfo from '../../../core/ui/StatusInfo'
import IconButton from '../../../core/ui/IconButton'
import CircleArrowIcon from "../../../assets/image/circle-arrow-icon.svg"
import BubblesIcon from "../../../assets/image/bubbles-icon.svg"
import Text from '../../../core/ui/Text'
import { useNavigation } from '@react-navigation/native'
import { RequestContext } from '../../../core/contexts/requestContext'
import { isWeb } from '../../../core/utils/responsive'

const RequestCard = ({ code, subject, status, statusTitle, style, hideStartIcon = false, hideEndIcon = false, data, onPress }) => {
    const navigation = useNavigation();

    const handleRedirection = () => {
        if (isWeb) {
            navigation.navigate("RequestsList", { code });
        } else {
            navigation.navigate("RequestDetails", { data, status, statusTitle });
        }
    }

    const { requestsConfigData } = useContext(RequestContext);
    const topicObject = requestsConfigData?.requestTopics?.find(topic => {
        return Number(topic.code) === Number(data?.requestTopic)
    })?.object;

    return (
        <Pressable
            onPress={onPress || handleRedirection}
            style={({ pressed }) => [
                styles.cardContainer,
                style,
                pressed && styles.whenPressed,
            ]}
        >
            {!hideStartIcon && (
                <View style={styles.iconContainer}>
                    <BubblesIcon role='image' aria-label='icône bulles de dialogue' width={40} height={40} />
                </View>
            )}
            <View style={styles.textContainer}>
                <View style={styles.row}>
                    <Text style={styles.code}>
                        Demande N°
                        <Text style={styles.bold}>{/^\d+$/.test(code) ? code : 'en cours d\'attribution'}</Text>
                    </Text>
                    {(!isWeb && hideStartIcon) && <StatusInfo title={statusTitle} status={status} titleStyle={styles.status} />}
                </View>
                <View style={styles.row}>
                    {data && (
                        <Text style={styles.code}>
                            {"Le "}
                            {data.creationDate && new Date(data.creationDate).toLocaleDateString('fr')}
                        </Text>
                    )}
                    {(!isWeb && !hideStartIcon) && <StatusInfo title={statusTitle} status={status} titleStyle={styles.status} />}
                </View>
                <Text style={styles.subject} numberOfLines={2}>
                    Objet : {topicObject || subject}
                </Text>
            </View>
            {isWeb && (
                <StatusInfo title={statusTitle} status={status} titleStyle={styles.status} />
            )}
            {!hideEndIcon && (
                <View style={styles.linkButtonContainer}>
                    <IconButton
                        accessibilityRole='link'
                        accessibilityLabel='voir les détails de la demande'
                        onPress={handleRedirection}
                        icon={<CircleArrowIcon style={styles.linkButtonIcon} />}
                    />
                </View>
            )}
        </Pressable>
    )
}

export default RequestCard

const styles = StyleSheet.create({
    cardContainer: {
        flexDirection: "row",
        backgroundColor: colors.light,
        paddingVertical: 10,
        paddingHorizontal: 10,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
    },
    iconContainer: {
        justifyContent: "center",
        marginHorizontal: 10,
    },
    textContainer: {
        flex: 1,
        flexDirection: "column",
        alignItems: "stretch"
    },
    icon: {
        width: 40,
        height: 40,
    },
    linkButtonContainer: {
        justifyContent: "center",
    },
    linkButtonIcon: {
        transform: isWeb ? "rotate(180deg)scale(.8)" : [{ rotate: "180deg" }, { scale: .8 }],
        color: colors.warning,
    },
    row: {
        gap: 15,
        flexDirection: "row",
    },
    code: {
        fontSize: fonts.subTitle,
        color: colors.primary200,
        paddingTop: 1,
        marginBottom: isWeb ? 5 : 0
    },
    subject: {
        fontSize: fonts.body2,
        color: colors.primary300,
    },
    status: {
        fontSize: fonts.subTitle,
    },
    whenPressed: {
        opacity: .80
    },
    bold: isWeb ? {
        fontWeight: 'bold',
    } : {
        fontFamily: 'Helvetica-Bold',
    },
});
