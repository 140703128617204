import React from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import { SvgUri } from 'react-native-svg'
import CircleArrowIcon from "../../../assets/image/circle-arrow-icon.svg"
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import IconButton from '../../../core/ui/IconButton'
import Text from '../../../core/ui/Text'
import { isWeb } from '../../../core/utils/responsive'
import TextIconCard from '../../../core/ui/TextIconCard'

const NewRequestShortcutsCard = ({ title, desc, icon, onPress }) => {
    if (isWeb) {
        return (
            <TextIconCard
                title={title}
                description={desc}
                icon={<Image source={{ uri: icon }} style={{ height: 45, width: 45 }} />}
                onPress={onPress}
                direction="column"
                containerStyle={{
                    width: "22%", minWidth: 150,
                }}
            />
        )
    }

    return (
        <Pressable onPress={onPress} style={styles.container}>
            <SvgUri uri={icon} />

            <View style={styles.textContainer}>
                <Text style={styles.title}>{title}</Text>
                <Text ellipsizeMode='tail' numberOfLines={2} style={styles.desc}>
                    {desc}
                </Text>
            </View>

            <IconButton
                accessibilityRole='link'
                accessibilityLabel={`Effectuer une demande dans ${title}`}
                icon={<CircleArrowIcon style={styles.linkButtonIcon} />}
                onPress={onPress}
            />
        </Pressable >
    )
}

export default NewRequestShortcutsCard

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        paddingHorizontal: 20,
        paddingVertical: 15,
        borderWidth: 1,
        borderColor: colors.primary300,
        borderRadius: 10,
        flexDirection: "row",
        columnGap: 15,
        width: "100%",
    },
    textContainer: {
        flex: 1,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
    },
    desc: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
    },
    linkButtonIcon: {
        transform: [{ rotate: "180deg" }, { scale: .8 }],
        color: colors.warning,
    },
})