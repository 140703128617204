import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { FirebaseContext } from './firebaseContext';
import Constants from 'expo-constants';

export const API_URL = Constants.expoConfig.extra.APP_API_URL;

export const AxiosContext = createContext({
    authAxios: axios,
    serviceUnavailable: false,
});

export const AxiosProvider = ({ children }) => {
    const [serviceUnavailable, setServiceUnavailable] = useState(false);

    const { accessToken, refreshToken } = useContext(FirebaseContext);

    const authAxios = axios.create({
        baseURL: API_URL,
    });

    authAxios.interceptors.request.use(
        config => {
            setServiceUnavailable(false);
            if (!config.headers.Authorization) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );

    authAxios.interceptors.response.use(
        async (response) => {
            return response;
        },
        async (error) => {
            if (error.response?.status === 503) setServiceUnavailable(true);
            if (error.response?.status === 401) {
                const config = error.config;
                if (!config?._retry) {
                    await refreshToken()
                    config._retry = true;
                    config.headers.Authorization = `Bearer ${accessToken}`;
                    return authAxios(config);
                }
            }
            return Promise.reject(error);
        });

    return (
        <AxiosContext.Provider value={{ authAxios, serviceUnavailable }}>
            {children}
        </AxiosContext.Provider>
    );
};