import React from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import useRequestsData from '../../../core/hooks/useRequestsData'
import Text from '../../../core/ui/Text'
import { isDateWithinOneMonth } from '../../../core/utils/date-utils'
import ActionsToDoZoneCard from './ActionsToDoZoneCard'

const ActionsToDoZone = ({ data: actionsToDoData }) => {
    const { inProgressRequests, completedRequests } = useRequestsData();
    const allRequests = [...inProgressRequests, ...completedRequests];
    const latestRequestsWithinCurrMonth = allRequests.sort((a, b) => new Date(b?.creationDate) - new Date(a?.creationDate)).filter(request => isDateWithinOneMonth(request?.creationDate));

    const actionsToDoTopicCodes = actionsToDoData.map(i => i.attributes?.requestTopic?.data?.attributes?.code)
    const latestRequestsMatchingActionsToDoTopicCodes = latestRequestsWithinCurrMonth.filter(request => actionsToDoTopicCodes.includes(request?.requestTopic));
    const latestRequestsPerActionsToDoTopicCodes = latestRequestsMatchingActionsToDoTopicCodes.reduce((acc, request) => {
        if (actionsToDoTopicCodes.includes(request?.requestTopic) && !acc.some(accRequest => accRequest?.requestTopic === request?.requestTopic)) {
            acc.push(request);
        }
        return acc;
    }, []);

    const actionsInProgressAndRejected = latestRequestsPerActionsToDoTopicCodes.filter((item) => ["ENR", "ENG", "REJ"].includes(item.requestStatus?.code));
    const actionsToDo = actionsToDoData.filter(i => !actionsInProgressAndRejected.some(request => request?.requestTopic === i.attributes?.requestTopic?.data?.attributes?.code));

    if (actionsToDo.length === 0 && actionsInProgressAndRejected.length === 0) return null;

    return (
        <View>
            <Text style={styles.title}>Actions à traiter ({actionsToDo.length + actionsInProgressAndRejected.length})</Text>
            <View style={styles.actionCards}>
                {
                    actionsToDo && actionsToDo.length > 0
                    && (
                        actionsToDo
                            .map((item, index) => (
                                <ActionsToDoZoneCard
                                    key={index}
                                    status="todo"
                                    title={item?.attributes?.title}
                                    targetTopicCode={item?.attributes?.requestTopic?.data?.attributes?.code}
                                    {...item}
                                />
                            ))
                    )
                }
                {
                    actionsInProgressAndRejected && actionsInProgressAndRejected.length > 0
                    && (
                        actionsInProgressAndRejected.map((item) => (
                            <ActionsToDoZoneCard
                                key={item.code}
                                status={(item.requestStatus?.code === "ENR" || item.requestStatus?.code === "ENG") ? "inProgress" : "rejected"}
                                title={item.requestType.label}
                                requestData={item}
                            />
                        ))
                    )
                }
            </View>
        </View>
    )
}

export default ActionsToDoZone

const styles = StyleSheet.create({
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginTop: 20,
        marginBottom: 10,
        marginLeft: 15,
        fontWeight: 600,
    },
    actionCards: {
        marginHorizontal: 15,
        rowGap: 10
    },
})