import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { colors } from '../../../core/constants/colors';
import Button from '../../../core/ui/Button';
import WarningIcon from '../../../assets/image/red-warning-icon.svg';
import DeleteMeModal from './DeleteMeModal';
import { fonts } from '../../../core/constants/fonts';
import { isWeb } from '../../../core/utils/responsive';

const AccountOptionsSection = () => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <View>
            <Text style={styles.title}>Mon compte</Text>
            <Button
                style={[styles.button, { justifyContent: 'space-between', backgroundColor: 'white' }, isWeb && styles.flatListContainer]}
                color={colors.error}
                variant={'text'}
                endIcon={<WarningIcon style={{ marginRight: 5, }} />}
                onPress={() => setModalVisible(true)}
            >
                Supprimer mon compte
            </Button>
            <DeleteMeModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
        </View>
    );
};

export default AccountOptionsSection;

const styles = StyleSheet.create({
    flatListContainer: {
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        marginBottom: isWeb ? 20 : 0
    },
    title: {
        fontSize: fonts.body1,
        color: colors.primary300,
        paddingHorizontal: isWeb ? 0 : 15,
        paddingVertical: 15,
    },
    button: {
        alignSelf: 'stretch',
        borderRadius: 0,
        paddingVertical: 15,
        paddingHorizontal: 15,
    },
});
