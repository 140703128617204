import React, { useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import BubblesIcon from "../../../assets/image/interrogation-bubbles-icon.svg";
import LoadingWhiteIcon from "../../../assets/image/loading-white-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import { ContractContext } from '../../../core/contexts/contractContext';
import Button from '../../../core/ui/Button';
import Modal from '../../../core/ui/Modal';
import Text from '../../../core/ui/Text';
import Spinner from '../../../core/ui/animations/Spinner';
import reportError from '../../../core/utils/reportError';

const DeleteConfirmationModal = ({ modalVisible, setModalVisible, inputData }) => {
    const [isLoading, setModalIsLoading] = useState(false)

    const { user, person } = inputData || {};

    const { authAxios } = useContext(AxiosContext);
    const { loadContract, contract } = useContext(ContractContext);

    const onPress = async () => {
        if (!user?.id || !person?.id) return;

        setModalIsLoading(true)
        await authAxios.delete(`users/${user.id}/persons/${person.id}`)
            .catch(err => {
                reportError(true, err.message);
            })
            .finally(async () =>
                await loadContract(contract.id)
                    .finally(() => {
                        setModalIsLoading(false);
                        setModalVisible(false);
                    })
            )
    };

    return (
        <Modal modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <BubblesIcon aria-label="icône bulles"/>
            <Text style={styles.title}>
                Êtes vous sur de vouloir retirer l’accès
                à l’utilisateur suivant ?
            </Text>
            <Text style={styles.subTitle}>{user?.email}</Text>
            <Button
                style={[styles.button, { marginTop: 20 }]}
                onPress={onPress}
                endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
            >
                Oui, je confirme
            </Button>
            <Button
                style={styles.button}
                onPress={() => setModalVisible(false)}
                variant="outlined"
            >
                Annuler
            </Button>
        </Modal >
    )
}

const styles = StyleSheet.create({
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center",
        marginTop: 20,
    },
    subTitle: {
        color: colors.primary300,
        marginTop: 20,
    },
    button: {
        alignSelf: "stretch",
        marginTop: 10,
    },
});

export default DeleteConfirmationModal
