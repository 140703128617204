import React, { createContext, useContext, useState } from 'react';
import reportError from '../utils/reportError';
import { AxiosContext } from './axiosContext';

export const CommunicationsContext = createContext({
    communications: [],
    loadCommunications: () => Promise.resolve(),
    isLoading: false,
    setIsLoading: (v) => v,
});

let controller = new AbortController();

export const CommunicationsProvider = ({ children }) => {
    const [communications, setCommunications] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const { authAxios } = useContext(AxiosContext);

    const loadCommunications = async (contractCode) => {
        if (controller) controller.abort()
        controller = new AbortController()
        setCommunications([]);
        setIsLoading(true);
        return authAxios.get(`communications/${contractCode}`, {
            signal: controller.signal,
            params: {
                query: `{ data { id, attributes { 
                    title, summary, content, 
                    priority,
                    startDate, endDate,
                    environment,
                    conditions,
                    thumbnail { data { attributes { url, mime } } }, 
                    zone { data { attributes { code, title, menu, createdAt, updatedAt } } } 
                    requestTopic { data { attributes { code } } }
                    document { data { attributes { url } } }
                } } }`
            }
        })
            .then(res => {
                setCommunications(res.data)
                return res;
            })
            .catch(err => reportError(false, err))
            .finally(() => setIsLoading(false))
    }

    return (
        <CommunicationsContext.Provider value={{
            communications,
            loadCommunications,
            isLoading,
            setIsLoading,
        }}>
            {children}
        </CommunicationsContext.Provider>
    );
};