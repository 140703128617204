import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import Modal from '../../../core/ui/Modal'
import Text from '../../../core/ui/Text'

const CallClientServiceModal = ({ modalVisible, setModalVisible, clientServiceNumber }) => {
    const formattedNumber = clientServiceNumber.replace(/(\d{2})(\d{2})(\d{3})(\d{3})/g, '$1.$2.$3.$4');

    return (
        <Modal containerStyle={{ maxWidth: 300 }} closeIcon modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <Text style={styles.title}>
                Vous pouvez joindre le service de la relation client au &thinsp;
                <a href={"tel:" + clientServiceNumber}>{formattedNumber}</a>
            </Text>
        </Modal>
    )
}

export default CallClientServiceModal

const styles = StyleSheet.create({
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center"
    }
})