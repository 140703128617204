import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import MyContract from './screens/MyContract.web'

const Stack = createStackNavigator();

const Contract = () => {
    return (
        <Stack.Navigator screenOptions={{
            title: "Mon contrat",
            headerShown: false
        }}>
            <Stack.Screen
                name="MyContract"
                component={MyContract}
            />
        </Stack.Navigator>
    )
}

export default Contract