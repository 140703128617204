import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import WarningIcon from "../../assets/image/red-warning-icon.svg";
import ArrowIcon from "../../assets/image/right-round-circle-arrow.svg";
import { colors } from '../constants/colors';
import IconButton from "./IconButton";
import Text from './Text';

const Alert = ({ title, containerStyle, onPress }) => {
    return (
        <Pressable
            onPress={onPress}
            accessibilityRole='alert'
            accessibilityLabel="Ouvrir l'alerte"
            style={[styles.container, containerStyle]}
            testID="alert"
        >
            <View testID='alert-icon'>
              <WarningIcon />
            </View>
            <Text style={styles.title}>{title}</Text>
            <IconButton
                icon={<ArrowIcon />}
                onPress={onPress}
            />
        </Pressable>
    )
}

export default Alert

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#F8D1D1",
        paddingHorizontal: 20,
        paddingRight: 10,
        borderRadius: 10,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3
    },
    title: {
        flex: 1,
        color: colors.error,
        paddingVertical: 10,
        marginLeft: 10,
    }
})