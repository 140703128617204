import AsyncStorage from "@react-native-async-storage/async-storage";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged as onAuthStateChangedFb, signOut as signOutFb } from "firebase/auth";
import React, { createContext, useEffect, useState } from 'react';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const FirebaseContext = createContext({
    firebaseUser: null,
    accessToken: null,
    refreshToken: () => Promise.resolve(),
    signOut: () => Promise.resolve(),
    isLoading: false,
});

export const FirebaseProvider = ({ children }) => {
    const [firebaseUser, setFirebaseUser] = useState(null);
    const [accessToken, setAccessToken] = useState(localStorage.getItem('token'));

    const [isLoading, setIsLoading] = useState(false);

    const login = async (currentUser) => {
        setIsLoading(true);
        setFirebaseUser(currentUser);
        setAccessToken(await currentUser.getIdToken(true).catch(e => console.error(e)));
        setIsLoading(false);
    };

    const logout = async () => {
        setFirebaseUser(null);
        setAccessToken(null);
    };

    const refreshToken = async () => {
        firebaseUser && setAccessToken(await firebaseUser.getIdToken(true).catch(e => console.error(e)));
    };

    const signOut = async () => {
        setIsLoading(true);
        return signOutFb(auth)
            .catch(e => console.warn(e))
            .finally(async () => {
                await new Promise((resolve) => setTimeout(resolve, 250))
                    .finally(() => setIsLoading(false))
            });
    };

    // Handle user state changes
    let loading = false;
    function onAuthStateChanged(user) {
        if (loading) return;
        loading = true;
        if (user) login(user).then(_ => loading = false);
        else logout(user).then(_ => loading = false);
    }

    useEffect(() => {
        const subscriber = onAuthStateChangedFb(auth, onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
    }, []);

    useEffect(() => {
        if (accessToken) AsyncStorage.setItem('token', accessToken)
        else AsyncStorage.clear()
    }, [accessToken]);

    return (
        <FirebaseContext.Provider
            value={{
                firebaseUser,
                accessToken,
                refreshToken,
                signOut,
                isLoading,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};
