import { useIsFocused } from '@react-navigation/native';
import { useMatomo } from 'matomo-tracker-react-native';
import { useEffect } from 'react';

const useTrackScreenView = (screenName) => {
    const isFocused = useIsFocused();
    const { trackScreenView } = useMatomo();

    useEffect(() => {
        if (isFocused) {
            trackScreenView({ name: screenName });
        }
    }, [isFocused, trackScreenView, screenName]);
};

export default useTrackScreenView;