import { useLinkTo } from '@react-navigation/native';
import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import RightArrow from "../../../assets/image/right-arrow-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { ContractContext } from '../../../core/contexts/contractContext';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import { isWeb } from '../../../core/utils/responsive';
import PaymentCard from '../../Payment/components/PaymentCard';

const PaymentsSection = () => {
    const linkTo = useLinkTo();

    const { contract } = useContext(ContractContext)
    const paymentRecords = contract?.contractBalanceRecords

    if (!paymentRecords) return null

    return (
        <View>
            <View style={styles.row}>
                <Text style={styles.title}>Mes paiements</Text>
                <Button
                    onPress={() => linkTo("/paiements")}
                    variant='text'
                    style={styles.button}
                    endIcon={<RightArrow style={styles.icon} />}
                    accessibilityRole='link'
                    accessibilityLabel="voir tout les payements"
                >
                    Voir tout
                </Button>
            </View>
            <View style={styles.paymentContainer}>
                <PaymentCard data={paymentRecords} showSecondButton={true} />
            </View>
        </View>
    )
}

export default PaymentsSection

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginTop: isWeb ? 0 : 20,
        marginBottom: 10,
        marginLeft: 15,
        fontWeight: 600,
    },
    button: {
        alignSelf: "flex-end",
        marginRight: 15,
    },
    paymentContainer: {
        paddingHorizontal: 15,
        paddingBottom: 10,
    },
    icon: {
        marginTop: isWeb ? 0 : 3.5,
        marginLeft: 5
    }
})