import React from 'react';
import { CommunicationsProvider } from '../contexts/communicationsContext';
import { ContractProvider } from '../contexts/contractContext';
import { RequestProvider } from '../contexts/requestContext';

export default function CoreContractProvider ({ children }) {

  return (
      <ContractProvider>
          <RequestProvider>
              <CommunicationsProvider>
                {children}
              </CommunicationsProvider>
          </RequestProvider>
      </ContractProvider>
  );
}
