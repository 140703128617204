import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import AvatarIcon from "../../../assets/image/avatar-glass-icon.svg";
import PeopleEnjoying from "../../../assets/image/people-enjoying-icon-blue.svg";
import RightArrowIcon from "../../../assets/image/right-arrow-icon.svg";
import TextFieldIcon from "../../../assets/image/text-field-avatar-icon.svg";
import EyeIcon from "../../../assets/image/text-field-eye-icon.svg";
import LockIcon from "../../../assets/image/text-field-lock-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';

const validationSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, ({ min }) => `Le mot de passe doit contenir au minimum ${min} caractères`)
        .required('Un mot de passe est requis'),
})

const KnownUserConnection = ({ route, navigation }) => {
    const [error, setError] = useState(null);
    const [securedTextEntry, setSecuredTextEntry] = useState(true);

    const { email } = route.params || { email: '@' };

    const onSubmit = async (values) => {
        let { password } = values;

        await signInWithEmailAndPassword(getAuth(), email, password)
            .catch(err => {
                console.error(err);
                switch (err.code) {
                    case 'auth/wrong-password':
                        setError('Votre identifiant et/ou votre mot de passe sont erronés.');
                        break;
                    case 'auth/user-disabled':
                        setError('Votre identifiant et/ou votre mot de passe sont erronés.');
                        break;
                    default:
                        setError(err.message);
                        break;
                }
            });
    }

    return (
        <View style={styles.container}>
            <Formik
                initialValues={{ password: '' }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleChange, handleSubmit, values, errors, isValid, dirty }) => (
                    <View style={styles.formContainer}>
                        <AvatarIcon style={styles.avatar} />
                        <Text style={styles.title}>Connexion par email</Text>
                        {!!error && <Text style={styles.error}>{error}</Text>}

                        <TextField
                            disable={true}
                            value={email}
                            containerStyle={styles.textField}
                            startIcon={<TextFieldIcon color={colors.primary300} />}
                        />

                        <TextField
                            onChangeText={handleChange('password')}
                            value={values.password}
                            placeholder="Mot de passe"
                            containerStyle={styles.textField}
                            startIcon={<LockIcon color={values.password === "" ? colors.primary200 : colors.primary300} />}
                            endIcon={<EyeIcon color={!securedTextEntry ? colors.secondary300 : values.password === "" ? colors.primary200 : colors.primary300} />}
                            onEndIconPress={() => setSecuredTextEntry(!securedTextEntry)}
                            secureTextEntry={securedTextEntry}
                        />
                        {errors.password && values.password !== "" && <Text style={styles.error}>{errors.password}</Text>}

                        <Button
                            variant='text'
                            style={styles.forgotPasswordText}
                            endIcon={<RightArrowIcon style={styles.buttonIconStyle} />}
                            onPress={() => navigation.navigate("ForgotPassword", { email })}
                        >
                            J&apos;ai oublié mon mot de passe
                        </Button>

                        <Button
                            disabled={!isValid || !dirty}
                            onPress={handleSubmit}
                            style={styles.submitButton}
                        >
                            Me connecter
                        </Button>
                    </View>
                )}
            </Formik>
            <PeopleEnjoying style={styles.backgroundImage} />
        </View>
    )
}

export default KnownUserConnection

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.primary400,
        flex: 1
    },
    backgroundImage: {
        position: "absolute",
        bottom: -6,
        left: 0,
        zIndex: -1
    },
    formContainer: {
        padding: 50,
        backgroundColor: colors.light,
        borderRadius: 10,
        maxWidth: 528,
        width: "100%"
    },
    avatar: {
        alignSelf: "center"
    },
    title: {
        marginTop: 23,
        marginBottom: 40,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    forgotPasswordText: {
        fontSize: fonts.body1,
        color: colors.secondary300,
    },
    textField: {
        marginBottom: 10
    },
    error: {
        color: colors.error,
        marginBottom: 10
    },
    submitButton: {
        marginTop: 10,
        width: "100%"
    },
    buttonIconStyle: {
        marginTop: 2,
        marginLeft: 5,
    }
})