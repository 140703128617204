import { CommonActions } from '@react-navigation/native';
import React, { useContext, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import CheckIcon from "../../../assets/image/check-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import { ContractContext } from '../../../core/contexts/contractContext';
import { FirebaseContext } from '../../../core/contexts/firebaseContext';
import { UserContext } from '../../../core/contexts/userContext';
import useDidUpdateEffect from '../../../core/hooks/useDidUpdateEffect';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import { getMainContractAddress, getMainContractBuilding } from '../../../core/utils/parseContract';
import Spinner from '../../../core/ui/animations/Spinner';
import LoadingWhiteIcon from '../../../assets/image/loading-white-icon.svg';
import { customStyles } from '../../../core/constants/customStyles';
import reportError from '../../../core/utils/reportError';

const ContractFound = ({ route, navigation }) => {
    const { data, response } = route?.params || {};
    const prevData = data;
    const { contract, person } = response || {};
    const address = getMainContractAddress(contract)
    const building = getMainContractBuilding(contract)

    const [isLoading, setIsLoading] = useState(false)

    const { accessToken, refreshToken } = useContext(FirebaseContext);
    const { authAxios } = useContext(AxiosContext);
    const { user } = useContext(UserContext);
    const { loadContracts } = useContext(ContractContext);

    useDidUpdateEffect(() => {
        loadContracts()
    }, [accessToken])

    const onFirstChoicePress = async () => {
        const data = {
            ...prevData,
            relationType: 'owner'
        }

        setIsLoading(true)
        await authAxios.post(`users/${user.id}/persons`, data)
            .then(res => res.data)
            .then(res => {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [{
                            name: 'ContactNumberChoice',
                            params: { data: res }
                        },],
                    })
                );
            })
            .catch(err => Promise.reject(err.response.data))
            .catch(err => {
                reportError(false, err);
                navigation.navigate("IdentificationFailed")
            })
            .finally(async () => {
                await refreshToken()
                setIsLoading(false)
            })
    }

    return (
        <ScrollView contentContainerStyle={[styles.container, customStyles.reducedPageContainer]}>
            <CheckIcon style={styles.icon} />
            <Text style={styles.title}>Trouvé !</Text>
            <View style={styles.contractInfoContainer}>
                <Text style={styles.contractId}>Contrat N°{contract?.contractRef}</Text>
                <Text style={styles.name}>{person?.title.trim()} {person?.lastName.trim()}</Text>
                <Text style={styles.address}>{[address?.streetName.trim(), address?.streetName2?.trim(), building?.buildingUnit?.door?.trim()].filter(i => i).join(' ')} {'\n'} {address?.postCode.trim()} {address?.city.trim()}</Text>
            </View>
            <Button
                onPress={onFirstChoicePress}
                style={styles.buttonsStyle}
                endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
            >
                Je suis {person?.name.trim()} {person?.lastName.trim()}
            </Button>
        </ScrollView>
    )
}

export default ContractFound

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 30,
    },
    icon: {
        alignSelf: "center",
        width: 150,
        height: 150
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.h2,
        marginTop: 20,
        marginBottom: 40,
        textAlign: "center"
    },
    contractInfoContainer: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.light,
        borderRadius: 10,
        paddingVertical: 20,
        paddingHorizontal: 20,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        elevation: 4,
        marginBottom: 20
    },
    contractId: {
        fontSize: fonts.subTitle,
        color: colors.primary200,
    },
    name: {
        color: colors.primary300,
        fontSize: fonts.body2,
        textAlign: "center",
    },
    address: {
        color: colors.primary300,
        fontSize: fonts.body2,
        textAlign: "center",
        textTransform: "uppercase"
    },
    buttonsStyle: {
        width: "100%"
    }
})