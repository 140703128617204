import { StyleSheet, View } from 'react-native'
import React from 'react'
import { colors } from '../../../core/constants/colors'

/**
 * Valid values of progressStatus prop are  '1/3', '2/3' or '3/3'
 */
const ProgressBar = ({ progressStatus = "3/3" }) => {
    return (
        <View accessibilityRole='progressbar' style={styles.barContainer}>
            <View style={[styles.bar, { backgroundColor: colors.secondary300 }]} />
            <View style={styles.gap} />
            <View style={[styles.bar, progressStatus !== "1/3" && { backgroundColor: colors.secondary300 }]} />
            <View style={styles.gap} />
            <View style={[styles.bar, progressStatus === "3/3" && { backgroundColor: colors.secondary300 }]} />
        </View>
    )
}

export default ProgressBar

const styles = StyleSheet.create({
    barContainer: {
        height: 4,
        flexDirection: "row",
    },
    bar: {
        flexGrow: 1,
        backgroundColor: colors.primary100,
        borderRadius: 5,
    },
    gap: {
        width: 6,
    }
})