import { useLinkTo } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import BubbleIcon from "../../../assets/image/bubbles-icon.svg"
import PeopleEnjoying from "../../../assets/image/people-enjoying-icon-blue.svg"
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import Button from '../../../core/ui/Button'
import SeparatorLine from '../../../core/ui/SeparatorLine'
import { fullWidth } from '../../../core/utils/responsive'
import AppleSignInButton from '../components/AppleSignInButton'
import GoogleSignInButton from '../components/GoogleSignInButton'

const ConnectionChoice = () => {
    const linkTo = useLinkTo();

    return (
        <>
            <View style={styles.container}>
                <View style={styles.innerContainer}>
                    <View style={styles.formContainer}>
                        <Text style={styles.title}>Bienvenue !</Text>
                        <Text style={{ ...styles.desc, marginBottom: 40 }}>Connectez-vous sur MyACM et gérez vos paiements, vos contrats, vos échanges et bien plus encore.</Text>

                        <GoogleSignInButton />
                        <AppleSignInButton />

                        <SeparatorLine text="ou" />
                        <Button
                            style={styles.emailButton}
                            variant='outlined'
                            onPress={() => linkTo("/auth/email")}
                        >
                            Continuer avec mon adresse mail
                        </Button>
                    </View>
                </View>
                <PeopleEnjoying style={styles.backgroundImage} />
            </View>
            <View style={styles.footer}></View>
        </>
    )
}

export default ConnectionChoice;

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.light,
        flex: 1,
        overflow: "auto",
        justifyContent: "center",
        paddingVertical: 20
    },
    innerContainer: {
        justifyContent: fullWidth <= 780 ? 'center' : 'flex-end',
        rowGap: fullWidth <= 780 ? 20 : 0,
        alignItems: 'center',
        flexWrap: "wrap",
        height: "100%",
    },
    formContainer: {
        paddingVertical: '5%',
        paddingHorizontal: '5%',
        maxWidth: '40%',
        minWidth: fullWidth <= 780 ? '100%' : 400,
        width: "100%",
        borderRadius: 10,
        textAlign: "center",
        backgroundColor: colors.light,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
    },
    welcomeContainer: {
        paddingVertical: '2%',
        paddingHorizontal: '1.5%',
        maxWidth: '20%',
        minWidth: fullWidth <= 780 ? '100%' : 300,
        width: "100%",
        borderRadius: 10,
        textAlign: "center",
        backgroundColor: colors.light,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        marginHorizontal: '5%',
        gap: 15
    },
    backgroundImage: {
        position: "absolute",
        bottom: -6,
        left: 0,
        zIndex: -1
    },
    footer: {
        backgroundColor: "#213A8F",
        height: "16.5%",
        width: "100%",
        zIndex: -1
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.h1,
        marginBottom: 20
    },
    subTitle: {
        color: colors.primary300,
        fontSize: fonts.h3,
        marginVertical: 20,
    },
    desc: {
        color: colors.primary300,
        fontSize: fonts.body2,
    },
    emailButton: {
        paddingHorizontal: 20,
        marginTop: 5
    },
    bubbleIcon: {
        width: 80,
        height: 70,
        alignSelf: 'center',
    }
})