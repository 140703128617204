import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import ArrowIcon from "../../../assets/image/Frame-40065.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import CommunicationsModal from '../../../core/ui/CommunicationsModal';
import getThumbnail from '../../../core/utils/getThumbnail';
import handleOpenCommunication from '../../../core/utils/handleOpenCommunication';

const OptionCard = ({ title, content, summary, thumbnail, pdfUrl }) => {
    const [announceModalVisible, setAnnounceModalVisible] = useState(false);
    const navigation = useNavigation();

    return (
        <Pressable
            style={styles.container}
            onPress={() => handleOpenCommunication({
                content,
                setModalVisible: setAnnounceModalVisible,
                pdf: pdfUrl,
                handleShowPdf: () => navigation.navigate('PdfPreview', { title, uri: pdfUrl })
            })}
            accessibilityLabel="En savoir plus sur la communication"
            accessibilityRole="button"
        >
            <View>
                <Text style={styles.title}>{title}</Text>
            </View>
            <ArrowIcon />
            <CommunicationsModal
                modalVisible={announceModalVisible}
                setModalVisible={setAnnounceModalVisible}
                title={title}
                content={content || summary}
                icon={getThumbnail(thumbnail)}
            />
        </Pressable>
    );
}

export default OptionCard;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.light,
        paddingVertical: 15,
        paddingHorizontal: 15,
        marginBottom: 1,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body2
    },
})