import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { getAuth, fetchSignInMethodsForEmail, sendSignInLinkToEmail } from 'firebase/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import AvatarIcon from "../../../assets/image/avatar-glass-icon.svg";
import TextFieldIcon from "../../../assets/image/text-field-avatar-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';
import PeopleEnjoying from "../../../assets/image/people-enjoying-icon-blue.svg"

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .matches(/^[^@]+@[^@]+\.[^@]{2,}$/, "Le format saisi est invalide. Merci de saisir une adresse mail valide.")
        .required('Une adresse email est requise'),
})

const EmailConnection = ({ navigation }) => {
    const [error, setError] = useState(null);
    
    const onSubmit = async (values) => {
        let { email } = values;
        email = email?.toLowerCase();

        const signInMethods = await fetchSignInMethodsForEmail(getAuth(), values.email);
        if (signInMethods.includes('password')) {
            navigation.navigate("KnownUserConnection", { email })
        } else {
            await AsyncStorage.setItem('SignInLinkEmail', email);
            // await setLanguageCode('fr');
            await sendSignInLinkToEmail(getAuth(), email, {
                url: `${Constants.expoConfig.extra.APP_WEB_URL}/auth/action/signup`,
                handleCodeInApp: true,
                iOS: {
                    bundleId: `${Constants.expoConfig.schema}`,
                },
                android: {
                    packageName: `${Constants.expoConfig.schema}`,
                    installApp: true,
                },
            }).then(_ => {
                navigation.navigate("EmailConfirmation", {
                    email,
                    action: 'signup',
                })
            }).catch(err => {
                console.error(err);
                setError(err.message);
            });
        }
    }

    return (
        <View style={styles.container}>
            <Formik
                initialValues={{ email: '' }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleChange, handleSubmit, values, errors, isValid, dirty }) => (
                    <View style={styles.formContainer}>
                        <AvatarIcon style={styles.avatar} />
                        <Text style={styles.title}>Connexion par email</Text>
                        {!!error && <Text style={styles.error}>{error}</Text>}

                        <TextField
                            onChangeText={handleChange('email')}
                            value={values.email}
                            placeholder="Email"
                            keyboardType="email-address"
                            containerStyle={styles.textField}
                            startIcon={<TextFieldIcon color={!dirty ? colors.primary200 : colors.primary300} />}
                        />
                        {errors.email && values.email !== "" && <Text style={styles.error}>{errors.email}</Text>}

                        <Button
                            disabled={!isValid || !dirty}
                            onPress={handleSubmit}
                            style={styles.submitButton}
                        >
                            Suivant
                        </Button>
                    </View>
                )}
            </Formik >
            <PeopleEnjoying style={styles.backgroundImage} />
        </View>
    )
}

export default EmailConnection

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.primary400,
        flex: 1
    },
    backgroundImage: {
        position: "absolute",
        bottom: -6,
        left: 0,
        zIndex: -1
    },
    formContainer: {
        padding: 50,
        backgroundColor: colors.light,
        borderRadius: 10,
        maxWidth: 528,
        width: "100%"
    },
    avatar: {
        alignSelf: "center"
    },
    title: {
        marginTop: 23,
        marginBottom: 40,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    textField: {
        marginBottom: 10
    },
    error: {
        color: colors.error,
        marginBottom: 10
    },
    submitButton: {
        marginTop: 10,
        width: "100%"
    }
})