import React from 'react';
import { StyleSheet, Text as RNText } from 'react-native';
import { fonts } from '../constants/fonts';

const Text = ({ style, ...restProps }) => {
    return (
        <RNText
            style={[styles.text, style]}
            {...restProps}
        />
    );
};

export default Text;

const styles = StyleSheet.create({
    text: {
        fontFamily: fonts.fontFamily
    }
})