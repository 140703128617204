import React from 'react';
import './Spinner.css';

/**
 * A component that spins an icon.
 */
const Spinner = ({ children, style }) => {
    return (
        <div className="loaderIcon">
            {children}
        </div>
    );
};

export default Spinner;
