import Constants from 'expo-constants';
import { GoogleAuthProvider, getAuth, signInWithCredential, signInWithPopup } from "firebase/auth";
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import GoogleIcon from '../../../assets/image/google-icon.svg';
import { colors } from '../../../core/constants/colors';
import Button from "../../../core/ui/Button";
import reportError from "../../../core/utils/reportError";

const provider = new GoogleAuthProvider();
provider.addScope('openid');
provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
provider.addScope('https://www.googleapis.com/auth/userinfo.email');
provider.setCustomParameters({
    "webClientId": `${Constants.expoConfig.extra.GOOGLE_SIGNIN_CLIENTID_WEB}.apps.googleusercontent.com`
});

const GoogleSignInButton = () => {
    const [isAvailable, setIsAvailable] = useState(false);

    useEffect(() => {
        setIsAvailable(!!Constants.expoConfig.extra.GOOGLE_SIGNIN_CLIENTID_WEB);
    }, []);

    const signInAsync = useCallback(async () => {
        signInWithPopup(getAuth(), provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);

                // Ensure Google returned a user identityToken
                if (!credential.idToken) {
                    throw new Error('Google Sign-In failed - no identify token returned');
                }

                // Sign-in the user with the credential
                await signInWithCredential(getAuth(), credential);
            }).catch((error) => {
                if (error.code === 'auth/cancelled-popup-request') {
                    // user cancelled the login flow
                } else if (error.code === 'auth/popup-closed-by-user') {
                    // user cancelled the login flow
                } else {
                    // some other error happened
                    console.error(error);
                    const errorMessage = error.message;
                    reportError(true, errorMessage)
                }
            });
    }, [])

    return (
        <Button
            style={styles.socialMediaButton}
            variant='outlined'
            startIcon={<GoogleIcon style={styles.buttonIconStyle} />}
            color={colors.primary300}
            disabled={!isAvailable}
            onPress={signInAsync}
        >
            Continuer avec Google
        </Button>
    )
}

export default GoogleSignInButton;

const styles = StyleSheet.create({
    socialMediaButton: {
        borderRadius: 10,
        marginBottom: 15,
        width: "100%",
        height: 51,
    },
    buttonIconStyle: {
        marginRight: 10,
    }
})
