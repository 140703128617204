import moment from "moment";
import holidays from "../constants/holidays";

export default function isServiceOpen() {
    const START_HOUR = 8;
    const END_HOUR = 17;
    const WEEKEND_DAYS_INDEXES = [6, 0];

    const isWithinServiceHours = moment().hour() >= START_HOUR && moment().hour() < END_HOUR || (moment().hour() === END_HOUR && moment().minutes() === 0)
    const isWeekend = WEEKEND_DAYS_INDEXES.includes(moment().day());
    const isHoliday = holidays.isHoliday(moment().format("YYYY-MM-DD"));

    return isWithinServiceHours && !isWeekend && !isHoliday;
}