import useScrollToTop from '@react-navigation/native/src/useScrollToTop';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';
import LeftArrowIcon from '../../../assets/image/left-arrow-icon.svg';
import { breakpoints } from '../../../core/constants/breakpoints';
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import { ContractContext } from '../../../core/contexts/contractContext';
import useRequestsData from '../../../core/hooks/useRequestsData';
import useTrackScreenView from '../../../core/hooks/useTrackScreenView';
import Button from '../../../core/ui/Button';
import Loader from '../../../core/ui/Loader';
import Text from '../../../core/ui/Text';
import { isEmpty } from '../../../core/utils/isEmpty';
import RequestCard from '../components/RequestCard';
import NewRequest from './NewRequest';
import RequestDetails from './RequestDetails';

const RequestsList = ({ route, navigation }) => {
    const { code } = route?.params || {};
    const { contract } = useContext(ContractContext);
    const { inProgressRequests, completedRequests, isLoading } = useRequestsData();

    const [selectedRequest, setSelectedRequest] = useState({});

    const scrollViewRef = useRef();
    useScrollToTop(scrollViewRef);

    const { width } = useWindowDimensions();
    const styles = makeStyles(width)

    const handleCreateNewRequest = () => {
        navigation.navigate("NEW_REQUEST");
    };

    const handleSelectedRequest = ({ data, status, statusTitle }) => {
        setSelectedRequest({ data, status, statusTitle });
        scrollViewRef.current?.scrollTo({
            y: 0,
            animated: true,
        });
    }

    // Handle the request selection directly from the route params
    useEffect(() => {
        if (code) {
            let inProgressReq = inProgressRequests.find(i => i.code === code)
            if (inProgressReq) handleSelectedRequest({ data: inProgressReq, status: "inProgress", statusTitle: "En cours" })
            else {
                let completedReq = completedRequests.find(i => i.code === code)
                if (completedReq) handleSelectedRequest({ data: completedReq, status: "done", statusTitle: `Terminée le ${completedReq.updateDate && new Date(completedReq.updateDate).toLocaleDateString('fr')}` })
            }
            route.params.code = undefined
        }
    }, [code, completedRequests, inProgressRequests, route.params])

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            if (!code) {
                setSelectedRequest({})
            }
        });
        return unsubscribe;
    }, [navigation, code]);

    useEffect(() => {
        setSelectedRequest({})
    }, [contract])

    useTrackScreenView("RequestHome");

    if (isLoading) {
        return <Loader />;
    }

    return (
        <ScrollView ref={scrollViewRef} contentContainerStyle={[styles.container, customStyles.pageContainer]}>
            {(inProgressRequests.length === 0 && completedRequests.length === 0) ?
                <NewRequest />
                : (
                    <>
                        <Text style={customStyles.pageTitle}>Mes demandes</Text>
                        <View style={styles.requestContainer}>
                            {(isEmpty(selectedRequest) || width >= breakpoints.medium) && (
                                <View style={styles.requestList}>
                                    <Text style={styles.subTitle}>Demande en cours</Text>
                                    {inProgressRequests && inProgressRequests.length > 0 ? (
                                        inProgressRequests.map((item) => {
                                            const status = "inProgress";
                                            const statusTitle = "En cours";

                                            return <RequestCard
                                                onPress={() => handleSelectedRequest({ data: item, status, statusTitle })}
                                                key={item.code}
                                                data={item}
                                                code={item.code}
                                                subject={item.requestType?.label}
                                                status={status}
                                                statusTitle={statusTitle}
                                                style={styles.requestCard}
                                                hideStartIcon
                                                hideEndIcon
                                            />
                                        })
                                    ) : (
                                        <Text style={styles.fallbackText}>
                                            Vous n&apos;avez aucune demande en cours.
                                        </Text>
                                    )}
                                    {completedRequests && completedRequests.length > 0 && (
                                        <>
                                            <Text style={styles.subTitle}>Demandes clôturées</Text>
                                            {completedRequests.map(item => {
                                                const status = item.requestStatus?.code === "REJ" ? "todo" : "done";
                                                const statusTitle = `${item.requestStatus?.code === "REJ" ? "Rejetée" : "Terminée"} le ${item.updateDate && new Date(item.updateDate).toLocaleDateString('fr')}`;

                                                return (
                                                    <RequestCard
                                                        onPress={() => handleSelectedRequest({ data: item, status, statusTitle })}
                                                        key={item.code}
                                                        data={item}
                                                        code={item.code}
                                                        subject={item.requestType?.label}
                                                        status={status}
                                                        statusTitle={statusTitle}
                                                        style={styles.requestCard}
                                                        hideStartIcon
                                                        hideEndIcon
                                                    />
                                                );
                                            })}
                                        </>
                                    )}
                                    <Button
                                        style={styles.button}
                                        onPress={handleCreateNewRequest}
                                        accessibilityLabel="Crée une nouvelle demande"
                                        accessibilityRole='link'
                                    >
                                        Nouvelle demande
                                    </Button>
                                </View>
                            )}

                            {(!isEmpty(selectedRequest) && width <= breakpoints.medium) && (
                                <Button
                                    style={styles.goBackButton}
                                    onPress={() => setSelectedRequest({})}
                                    startIcon={<LeftArrowIcon style={styles.buttonIconStyle} aria-label="icône flèche à gauche" />}
                                    variant='text'
                                >
                                    Retour à la liste
                                </Button>
                            )}

                            {(!isEmpty(selectedRequest) || width >= breakpoints.medium) && (
                                <View style={styles.requestDetails}>
                                    {!isEmpty(selectedRequest) ? (
                                        <RequestDetails {...selectedRequest} />
                                    ) : (
                                        <Text style={styles.requestDetails.text}>
                                            Veuillez sélectionner une demande pour afficher un aperçu ici.
                                        </Text>
                                    )}
                                </View>
                            )}
                        </View>
                    </>
                )}

        </ScrollView>
    )
}

export default RequestsList

const makeStyles = (width) => StyleSheet.create({
    container: {
        flexGrow: 1,
        paddingHorizontal: 15,
        paddingBottom: 30,
    },
    requestContainer: {
        flexDirection: width >= breakpoints.medium ? "row" : "column",
        alignItems: width >= breakpoints.medium ? "flex-start" : "stretch",
        gap: 20
    },
    requestList: {
        flex: 1,
    },
    requestDetails: {
        flex: 1,
        justifyContent: "space-around",
        alignItems: "center",
        minHeight: 480,
        backgroundColor: "#E4E4E4",
        marginTop: width >= breakpoints.medium ? 28 : 0,
        text: {
            color: colors.primary300,
        }
    },
    subTitle: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginTop: 20,
        marginBottom: 10,
    },
    requestCard: {
        marginBottom: 1,
    },
    button: {
        marginVertical: 25
    },
    buttonIconStyle: {
        marginRight: 5,
    },
    fallbackText: {
        color: colors.primary200,
        fontSize: 13,
        marginTop: 7,
        marginBottom: 5,
    }
})