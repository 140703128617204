import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import AvatarIcon from "../../../assets/image/conseiller-casque.svg";
import { colors } from "../../../core/constants/colors";
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import StatusInfo from '../../../core/ui/StatusInfo';
import Text from '../../../core/ui/Text';
import isServiceOpen from '../../../core/utils/isServiceOpen';
import AstreinteModal from './AstreinteModal';
import { isWeb } from '../../../core/utils/responsive';

const ClientServiceCard = ({ containerStyle }) => {
    const navigation = useNavigation();
    const [astreinteModalVisible, setAstreinteModalVisible] = useState(false);

    const handleContactService = () => {
        if (isServiceOpen()) {
            navigation.navigate("Request", { screen: "NEW_REQUEST" });
        } else {
            setAstreinteModalVisible(true);
        }
    }

    return (
        <View style={[styles.container, containerStyle]}>
            <View>
                <AvatarIcon style={styles.icon} />
                <Text style={styles.title}>Service Relation Client</Text>
                <Text style={styles.openingHours}>
                    Ouvert du lundi au vendredi{"\n"}de 08h00 à 12h30 / 13h30 à 17h00.
                </Text>
            </View>
            <View>
                {isServiceOpen() ? (
                    <StatusInfo status={"done"} title={"Actuellement ouvert"} />
                ) : (
                    <StatusInfo status={"todo"} title={"Actuellement fermé"} />
                )}
                <Button
                    onPress={handleContactService}
                    variant="outlined"
                    style={styles.button}
                    textStyle={{ textAlign: "center" }}
                    color={isServiceOpen() ? colors.secondary300 : colors.error}
                    accessibilityLabel={isServiceOpen() ? "Contactez ACM" : "Une urgence? \n Contactez l'astreinte"}
                >
                    {isServiceOpen() ? "Contactez-nous" : "Une urgence? \n Contactez l'astreinte"}
                </Button>
            </View>
            <AstreinteModal
                modalVisible={astreinteModalVisible}
                setModalVisible={setAstreinteModalVisible}
            />
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: isWeb ? "space-around" : null,
        backgroundColor: colors.light,
        paddingVertical: 15,
        paddingHorizontal: 20,
        marginBottom: 8,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        borderRadius: isWeb ? 10 : null,
        width: "100%",
        flex: 1
    },
    title: {
        fontSize: fonts.body2,
        color: colors.primary300,
        marginTop: isWeb ? 0 : 15,
        textAlign: "center",
    },
    openingHours: {
        fontSize: fonts.subTitle,
        color: colors.primary200,
        marginVertical: 5,
        marginBottom: isWeb ? 7 : 5,
        textAlign: "center",
    },
    button: {
        paddingHorizontal: 20,
        paddingVertical: 5,
        marginTop: isWeb ? 7 : 20
    },
    icon: {
        alignSelf: "center",
        height: 47
    }
});

export default ClientServiceCard;