import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import AvatarIcon from '../../../assets/image/avatar-icon2.svg';
import useCommunicationsZoneData from '../../../core/hooks/useCommunicationsZoneData';
import getThumbnail from '../../../core/utils/getThumbnail';
import { isWeb } from '../../../core/utils/responsive';
import ClientAccountZoneCard from './ClientAccountZoneCard';

const ClientAccountZone = () => {
  const { clientAccount } = useCommunicationsZoneData();

  if (clientAccount.length === 0) {
    return null;
  }

  return (
    <FlatList
      style={styles.cardContainer}
      data={clientAccount}
      renderItem={({ item }) =>
        <ClientAccountZoneCard
          title={item.attributes?.title}
          summary={item.attributes?.summary}
          content={item.attributes?.content}
          icon={getThumbnail(item.attributes?.thumbnail?.data, <AvatarIcon />)}
          requestTopicCode={item.attributes?.requestTopic?.data?.attributes?.code}
        />}
      keyExtractor={item => item.id}
      scrollEnabled={false}
    />
  )
}

export default ClientAccountZone;

const styles = StyleSheet.create({
  cardContainer: {
    paddingHorizontal: isWeb ? 0 : 15,
    paddingBottom: 5,
  },
  tinyLogo: {
    width: 40,
    height: 40,
  }
})