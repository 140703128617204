import React, { useContext, useState } from "react";
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet } from 'react-native';
import { AxiosContext } from "../../../core/contexts/axiosContext";
import { UserContext } from "../../../core/contexts/userContext";
import AvatarIcon from "../../../assets/image/avatar-glass-icon.svg";
import TextField from "../../../core/ui/TextField";
import Button from "../../../core/ui/Button";
import Spinner from '../../../core/ui/animations/Spinner';
import LoadingWhiteIcon from "../../../assets/image/loading-white-icon.svg";
import Text from '../../../core/ui/Text';
import { fonts } from '../../../core/constants/fonts';
import { colors } from '../../../core/constants/colors';
import * as yup from 'yup';
import { Formik } from 'formik';
import reportError from "../../../core/utils/reportError";


const validationSchema = yup.object().shape({
    personCode: yup
        .string()
        .matches(/^\d{9}$/, 'Merci de saisir la référence tiers sous le bon format')
        .matches(/^[\d/]+$/, 'Seuls les chiffres sont autorisés dans la référence tiers')
        .max(9, 'La référence tiers ne doit pas dépasser 9 caractères')
        .required('Votre référence tiers est requise pour continuer'),
})

const CheckTenantPersonCode = ({ route, navigation }) => {
    const { data } = route?.params || {};
    const prevData = data;

    const { authAxios } = useContext(AxiosContext);
    const { user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false);
    const [allowChangeText, setAllowChangeText] = useState(true);

    const onSubmit = async (values) => {
        const data = {
            ...prevData,
            personCode: values.personCode
        }

        setIsLoading(true);
        await authAxios.post(`users/${user.id}/persons`, data)
            .then(res => res.data)
            .then(res => {
                navigation.navigate("ContractFound", { data, response: res })
            })
            .catch(err => Promise.reject(err.response.data))
            .catch(err => {
                reportError(false, err);
                navigation.navigate("IdentificationFailed")
            })
            .finally(() => setIsLoading(false))
    };

    const onChangeText = (text, setFieldValue) => {
        // Remove non-numeric characters from the input
        const input = text.replace(/[^0-9]/g, '');

        if (allowChangeText) {
            setFieldValue("personCode", input)
        }
    }

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : null}
            style={styles.container}>
            <Formik
                initialValues={{ personCode: '' }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, setFieldValue, values, errors, isValid, dirty }) => (
                    <ScrollView contentContainerStyle={styles.formContainer}>
                        <AvatarIcon />
                        <Text style={styles.title}>Vérifions votre identité</Text>
                        <Text style={styles.label}>Référence Tiers</Text>
                        <TextField
                            maxLength={10}
                            onChangeText={(text) => onChangeText(text, setFieldValue)}
                            placeholder="123456789"
                            value={values.personCode}
                            containerStyle={styles.textField}
                            keyboardType="numeric"
                        />
                        {errors.personCode && values.personCode !== "" && <Text style={styles.error}>{errors.personCode}</Text>}
                        <Button
                            disabled={!isValid || !dirty}
                            style={styles.submitButton}
                            onPress={handleSubmit}
                            endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
                        >
                            Suivant
                        </Button>

                    </ScrollView>
                )}
            </Formik>
        </KeyboardAvoidingView>


    )

}

export default CheckTenantPersonCode;

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
    },
    formContainer: {
        flexGrow: 1,
        padding: 30,
        paddingTop: 15,
    },
    title: {
        marginTop: 23,
        marginBottom: 40,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    label: {
        fontSize: fonts.body1,
        color: colors.primary300
    },
    textField: {
        marginVertical: 10
    },
    error: {
        color: colors.error,
        marginBottom: 10,
    },
    submitButton: {
        marginTop: 10,
        width: "100%"
    },
})