import React from 'react';
import { AxiosProvider } from '../contexts/axiosContext';
import { FirebaseProvider } from '../contexts/firebaseContext';
import { UserProvider } from '../contexts/userContext';

export default function CoreAuthProvider ({ children }) {

  return (
      <FirebaseProvider>
          <AxiosProvider>
              <UserProvider>
                  {children}
              </UserProvider>
          </AxiosProvider>
      </FirebaseProvider>
  );
}
