import { useNavigation } from '@react-navigation/native'
import React, { useContext } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import LoadingIcon from "../../../assets/image/loading-icon.svg"
import RightArrow from "../../../assets/image/right-arrow-icon.svg"
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import { RequestContext } from '../../../core/contexts/requestContext'
import useRequestsData from '../../../core/hooks/useRequestsData'
import Button from '../../../core/ui/Button'
import Text from '../../../core/ui/Text'
import Spinner from '../../../core/ui/animations/Spinner'
import RequestCard from '../../Request/components/RequestCard'
import NewRequestShortcutsSection from './NewRequestShortcutsSection'

const RequestsSection = () => {
    const navigation = useNavigation()
    const { isRequestLoading } = useContext(RequestContext);
    const { inProgressRequests } = useRequestsData();

    const handleShowAllRequests = () => {
        navigation.navigate("RequestsList");
    }

    return (
        <View>
            <View style={styles.row}>
                <Text style={styles.title}>Mes demandes</Text>
                <Button
                    variant='text'
                    style={styles.showAllButton}
                    onPress={handleShowAllRequests}
                    endIcon={<RightArrow style={styles.icon} />}
                    accessibilityRole='link'
                    accessibilityLabel="voir toutes les demandes"
                >
                    Voir tout
                </Button>
            </View>
            <Text style={styles.desc}>Techniques, administratives, mes communications</Text>
            <View style={styles.requestContainer}>
                {inProgressRequests.length === 0 ? (
                    <Pressable onPress={handleShowAllRequests} style={styles.infoCard}>
                        {isRequestLoading ? (
                            <Spinner>
                                <LoadingIcon />
                            </Spinner>
                        ) : (
                            <Text style={styles.infoCardText}>
                                Vous n&apos;avez aucune demande en cours
                            </Text>
                        )}
                    </Pressable>
                ) :
                    inProgressRequests.length === 1 ? (
                        isRequestLoading ? (
                            <View style={[styles.infoCard, { borderColor: colors.secondary200 },]}>
                                <Spinner>
                                    <LoadingIcon />
                                </Spinner>
                            </View>
                        ) : (
                            inProgressRequests.slice(0, 1).map((item) => (
                                <RequestCard
                                    key={item.code}
                                    data={item}
                                    code={item.code}
                                    subject={item.requestType?.label}
                                    status="inProgress"
                                    statusTitle="En cours"
                                    style={styles.requestCard}
                                />
                            )))
                    ) : (
                        <Pressable onPress={handleShowAllRequests} style={({ pressed }) => [styles.infoCard, { borderColor: colors.secondary300 }, pressed && { opacity: 0.8 }]}>
                            <Text style={[styles.infoCardText, { color: colors.secondary300 }]}>
                                Vous avez {inProgressRequests.length} demandes en cours
                            </Text>
                        </Pressable>
                    )}
            </View>
            {!isRequestLoading && (
                <NewRequestShortcutsSection />
            )}
        </View>
    )
}

export default RequestsSection

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginTop: 20,
        marginBottom: 10,
        marginLeft: 15,
        fontWeight: 600
    },
    desc: {
        color: colors.primary300,
        fontSize: fonts.subTitle,
        marginLeft: 15,
        marginTop: -8,
        marginBottom: 12,
    },
    showAllButton: {
        alignSelf: "flex-end",
        marginRight: 15,
    },
    infoCard: {
        paddingVertical: 25,
        backgroundColor: colors.light,
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 10,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        borderWidth: 1,
        borderColor: colors.primary200,
    },
    infoCardText: {
        color: colors.primary200,
        fontSize: fonts.body2,
    },
    requestContainer: {
        paddingHorizontal: 15,
        paddingBottom: 10,
    },
    requestCard: {
        marginBottom: 10
    },
    showMoreButton: {
        paddingVertical: 0,
        marginBottom: 10,
        alignSelf: "flex-end"
    },
    button: {
        marginTop: 5
    },
    icon: {
        marginTop: 3.5,
        marginLeft: 5
    }
})