import { StyleSheet, View } from 'react-native'
import React from 'react'
import { colors } from '../constants/colors'
import Text from './Text'

/**
 * A component that adds a line separator between a centered text.
*/
const SeparatorLine = ({ text, style }) => {
    return (
        <View style={[styles.separatorLineContainer, style]} testID="separatorLineContainer">
            <View style={styles.separatorLine} testID="lineAbove" />
            <View>
                <Text style={styles.text}>{text}</Text>
            </View>
            <View style={styles.separatorLine} testID="lineBelow" />
        </View>
    )
}

export default SeparatorLine

const styles = StyleSheet.create({
    separatorLineContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 15,
    },
    separatorLine: {
        flexGrow: 1,
        height: 1,
        backgroundColor: colors.primary100
    },
    text: {
        textAlign: "center",
        color: colors.primary300,
        paddingHorizontal: 10
    },
})