import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, View } from 'react-native';
import AvatarIcon from "../../../assets/image/avatar-glass-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import { UserContext } from '../../../core/contexts/userContext';
import * as yup from 'yup';
import Spinner from '../../../core/ui/animations/Spinner';
import LoadingWhiteIcon from '../../../assets/image/loading-white-icon.svg';
import { customStyles } from '../../../core/constants/customStyles';
import reportError from '../../../core/utils/reportError';

const validationSchema = yup.object().shape({
    lastName: yup
        .string()
        .required('Votre nom est requise pour continuer'),
    challengeFirstName: yup.boolean().required(),
    firstName: yup
        .string()
        .when('challengeFirstName', {
            is: true,
            then: () => yup.string().required('Votre prénom est requise pour continuer'),
            otherwise: () => yup.string().optional()
        }),
});

const CheckTenantName = ({ route, navigation }) => {
    const { data, response } = route?.params || {};
    const prevData = data;
    const challengeFirstName = response.challenge?.includes('firstName');

    const { authAxios } = useContext(AxiosContext);
    const { user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values) => {
        const data = {
            ...prevData,
            lastName: values.lastName,
            firstName: values.firstName,
        }

        setIsLoading(true);
        await authAxios.post(`users/${user.id}/persons`, data)
            .then(res => res.data)
            .then(res => {
                navigation.navigate("ContractFound", { data, response: res })
            })
            .catch(err => Promise.reject(err.response.data))
            .catch(err => {
                reportError(false, err);
                navigation.navigate("IdentificationFailed")
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : null}
            style={[customStyles.keyBoardAvoidingViewContainer, customStyles.reducedPageContainer]}
        >
            <Formik
                initialValues={{ lastName: '', firstName: '', challengeFirstName }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, values, errors, isValid, dirty }) => (
                    <ScrollView contentContainerStyle={styles.container}>
                        <AvatarIcon />
                        <Text style={styles.title}>Vérifions votre identité</Text>

                        <Text style={styles.label}>Nom de famille</Text>
                        <TextField
                            onChangeText={handleChange('lastName')}
                            value={values.lastName}
                            placeholder="Nom"
                            containerStyle={styles.textField}
                        />
                        {errors.lastName && values.lastName !== "" && <Text style={styles.error}>{errors.lastName}</Text>}

                        {!!challengeFirstName && <View>
                            <Text style={styles.label}>Prénom</Text>
                            <TextField
                                onChangeText={handleChange('firstName')}
                                value={values.firstName}
                                placeholder="Prénom"
                                containerStyle={styles.textField}
                            />
                            {errors.firstName && values.firstName !== "" && <Text style={styles.error}>{errors.firstName}</Text>}
                        </View>}

                        <Button
                            disabled={!isValid || !dirty}
                            onPress={handleSubmit}
                            style={styles.submitButton}
                            endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
                        >
                            Suivant
                        </Button>
                    </ScrollView>
                )}
            </Formik>
        </KeyboardAvoidingView>
    )
}

export default CheckTenantName

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 30,
        paddingTop: 15,
    },
    title: {
        marginTop: 23,
        marginBottom: 40,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    label: {
        fontSize: fonts.body1,
        color: colors.primary300
    },
    textField: {
        marginVertical: 10
    },
    error: {
        color: colors.error,
        marginBottom: 10,
    },
    submitButton: {
        marginTop: 10,
        width: "100%"
    },
})