import React, { useState } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import WarningIcon from "../../../assets/image/warning-icon.svg";
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import reportError from '../../../core/utils/reportError';

const IdentificationFailed = ({ navigation }) => {
    const [phoneNumber] = useState('0499920910');

    const onPress = () => {
        Linking.openURL(`tel://${phoneNumber}`)
            .then(_ => navigation.navigate("MultiContracts"))
            .catch(e => reportError(false, e));
    }

    return (
        <ScrollView contentContainerStyle={[styles.container, customStyles.reducedPageContainer]}>
            <WarningIcon />
            <Text style={styles.title}>Nous n’avons pas réussi à vous identifier</Text>
            <Text style={styles.desc}>Contactez l&apos;assistance ACM Habitat</Text>
            <Button
                style={styles.button}
                onPress={onPress}
            >
                {phoneNumber?.match(/.{1,2}/g)?.join(' ')}
            </Button>
        </ScrollView>
    )
}

export default IdentificationFailed

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 30,
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        fontSize: fonts.h2,
        color: colors.primary300,
        marginTop: 20,
        textAlign: "center"
    },
    desc: {
        color: colors.primary200,
        marginTop: 20,
        marginBottom: 20,
        textAlign: "center"
    },
    button: {
        width: "100%"
    }
})