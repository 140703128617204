import { createStackNavigator } from '@react-navigation/stack';
import * as WebBrowser from 'expo-web-browser';
import React, { useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import LeftArrowIcon from "../../assets/image/left-arrow-icon.svg";
import { colors } from '../../core/constants/colors';
import { FirebaseContext } from '../../core/contexts/firebaseContext';
import { UserContext } from '../../core/contexts/userContext';
import Button from '../../core/ui/Button';
import Loader from '../../core/ui/Loader';
import { isWeb } from '../../core/utils/responsive';
import ConnectionChoice from './screens/ConnectionChoice';
import EmailConfirmation from './screens/EmailConfirmation';
import EmailConnection from './screens/EmailConnection';
import ForgotPassword from './screens/ForgotPassword';
import KnownUserConnection from './screens/KnownUserConnection';
import NewUserConnection from './screens/NewUserConnection';

const Stack = createStackNavigator();

const Authentification = () => {
  const { isLoading: fbIsLoading } = useContext(FirebaseContext)
  const { isLoading: userIsLoading } = useContext(UserContext)
  
  useEffect(() => {
    if (!isWeb) return;

    WebBrowser.warmUpAsync().catch(e => console.warn(e));
    return () => {
      WebBrowser.coolDownAsync().catch(e => console.warn(e));
    };
  }, []);


  if (isWeb && (fbIsLoading || userIsLoading)) {
    return <Loader />;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: styles.headerTintColor,
        cardStyle: styles.cardStyle,
        headerStyle: styles.headerStyle,
        title: null,
        headerShown: !isWeb,
        headerLeft: ({ onPress }) => (
          <Button
            variant='text'
            startIcon={<LeftArrowIcon style={styles.buttonIconStyle} />}
            style={styles.goBackButton}
            onPress={onPress}
          >
            Retour
          </Button>
        ),
      }}
      initialRouteName="ConnectionChoice"
    >
      <Stack.Screen
        name="ConnectionChoice"
        component={ConnectionChoice}
        options={{
          headerShown: false,
          title: 'Connexion'
        }}
      />
      <Stack.Screen
        name="EmailConnection"
        component={EmailConnection}
        options={{
          title: 'Connexion'
        }}
      />
      <Stack.Screen
        name="NewUserConnection"
        component={NewUserConnection}
        options={{
          title: 'Choisissez votre mot de passe'
        }}
      />
      <Stack.Screen
        name="KnownUserConnection"
        component={KnownUserConnection}
        options={{
          title: 'Connexion'
        }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{
          title: 'Réinitialiser le mot de passe'
        }}
      />
      <Stack.Screen
        name="EmailConfirmation"
        component={EmailConfirmation}
        options={{
          headerShown: false,
          title: 'Un mail vient de vous être envoyé'
        }}
      />
    </Stack.Navigator>
  );
}

export default Authentification

const styles = StyleSheet.create({
  headerTintColor: {
    color: colors.secondary300
  },
  cardStyle: {
    backgroundColor: colors.light
  },
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
  },
  goBackButton: {
    marginLeft: 15
  },
  buttonIconStyle: {
    marginRight: 5,
  }
})