import { Linking } from "react-native";
import isLink from "./isLink";
import { isWeb } from "../utils/responsive";

const handleOpenCommunication = ({ content, setModalVisible, pdf, handleShowPdf }) => {
  if (pdf) {
    if (isWeb) window.open(pdf, '_blank');
    else handleShowPdf();
  } else if (isLink(content)) {
    Linking.openURL(content);
  } else {
    setModalVisible(true);
  }
};

export default handleOpenCommunication;