import { Feather, MaterialIcons } from '@expo/vector-icons';
import { launchCameraAsync, launchImageLibraryAsync, useCameraPermissions, useMediaLibraryPermissions } from "expo-image-picker";
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import Modal from '../../../core/ui/Modal';
import { colors } from '../../../core/constants/colors';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import verifyPermission from '../../../core/utils/verifyPermission';
import { isWeb } from '../../../core/utils/responsive';

const PictureModal = ({ onDocumentPicked, setTakedPicture, modalVisible, setModalVisible }) => {
    const [cameraPermissionInfo, requestCameraPermission] = useCameraPermissions();
    const [libraryPermissionInfo, requestLibraryPermission] = useMediaLibraryPermissions();
    const [selectedImages, setSelectedImages] = useState([]);

    async function handleTakePicture() {
        const hasPermission = await verifyPermission(cameraPermissionInfo, requestCameraPermission);

        if (!hasPermission) {
            return;
        }

        const image = await launchCameraAsync({
            allowsEditing: false,
        });

        if (image.assets) {
            onDocumentPicked(image.assets[0]);
            setTakedPicture(image.assets[0]);
            setSelectedImages([...selectedImages, image.assets[0].uri]);
        }
        setModalVisible(false);
    }

    async function handleSelectPicture() {
        const hasPermission = await verifyPermission(libraryPermissionInfo, requestLibraryPermission);

        if (!hasPermission) {
            return;
        }

        const image = await launchImageLibraryAsync({
            allowsEditing: false,
        });

        if (image.assets) {
            onDocumentPicked(image.assets[0]);
            setTakedPicture(image.assets[0]);
            setSelectedImages([...selectedImages, image.assets[0].uri]);
        }
        setModalVisible(false);
    }

    return (
        <Modal modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <Text style={styles.title}>Choisissez une option</Text>
            <Button
                style={styles.button}
                onPress={handleTakePicture}
                endIcon={<Feather name="camera" size={24} color={colors.light} />}
                disabled={isWeb}
            >
                Prendre avec l&apos;appareil
            </Button>
            <Button
                style={styles.button}
                onPress={handleSelectPicture}
                endIcon={<MaterialIcons name="photo-library" size={24} color={colors.light} />}
            >
                Sélectionner depuis la galerie
            </Button>
        </Modal>
    );
};

const styles = StyleSheet.create({
    title: {
        color: colors.primary300,
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 20,
    },
    button: {
        alignSelf: 'stretch',
        marginTop: 10,
        gap: 10,
    },
    imageContainer: {
        flex: 1,
        width: '100%',
        marginTop: 20,
    },
    image: {
        width: 100,
        height: 100,
        margin: 5,
    },
});

export default PictureModal;
