import React, { useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import RightArrow from "../../../assets/image/right-arrow-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import CommunicationsModal from '../../../core/ui/CommunicationsModal';
import { useNavigation } from '@react-navigation/native';
import { isWeb } from '../../../core/utils/responsive';

const ClientAccountZoneCard = ({ title, content, summary, icon, requestTopicCode }) => {
    const navigation = useNavigation();

    const [communicationModalVisible, setCommunicationModalVisible] = useState(false);

    const handleButtonPress = async () => {
        if (requestTopicCode) {
            navigation.navigate("RequestForm", { topicFromRoute: requestTopicCode })
        } else {
            setCommunicationModalVisible(true)
        }
    }

    return (
        <View style={styles.container}>
            {icon}
            <View style={styles.textContainer}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.dot} />
                    <Text style={styles.title} numberOfLines={2}>
                        {title}
                    </Text>
                </View>
                <Button
                    variant='text'
                    style={styles.button}
                    endIcon={<RightArrow style={styles.icon} />}
                    onPress={handleButtonPress}
                    accessibilityLabel="Ouvrir"
                    textProps={{ numberOfLines: 1 }}
                >
                    {summary || "En savoir plus"}
                </Button>
            </View>
            <CommunicationsModal
                modalVisible={communicationModalVisible}
                setModalVisible={setCommunicationModalVisible}
                title={title}
                content={content}
                icon={icon}
            />
        </View>
    )
}
export default ClientAccountZoneCard;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        columnGap: 20,
        paddingVertical: 15,
        paddingHorizontal: 20,
        borderRadius: 10,
        backgroundColor: colors.light,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        margin: isWeb ? 3 : 0,
        marginBottom: 8,
    },
    imgContainer: {
        height: 45,
        width: 45,
        overflow: 'hidden',
        margin: 5,
    },
    textContainer: {
        flex: 1,
        justifyContent: "space-between",
        rowGap: 5
    },
    dot: {
        width: 9.5,
        height: 9.5,
        marginTop: 4,
        marginRight: 5,
        borderRadius: 50,
        backgroundColor: colors.error
    },
    title: {
        fontSize: fonts.body2,
        color: colors.error
    },
    button: {
        paddingVertical: 0,
    },
    icon: {
        marginTop: 3.5,
        marginLeft: 5
    }
})