import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Linking, StyleSheet } from 'react-native';
import BubblePhoneIcon from "../../../assets/image/bubble-phone-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Modal from '../../../core/ui/Modal';
import Text from '../../../core/ui/Text';
import { isWeb } from '../../../core/utils/responsive';
import CallAstreinteModal from './CallAstreinteModal.web';

const ASTREINTE_PHONE_NUMBER = '0499527575';

const AstreinteModal = ({ modalVisible, setModalVisible }) => {
    const [callAstreinteModalVisible, setCallAstreinteModalVisible] = useState(false);

    const navigation = useNavigation();

    const handleContactButton = () => {
        if (isWeb) {
            setCallAstreinteModalVisible(true);
        } else {
            Linking.openURL(`tel://${ASTREINTE_PHONE_NUMBER}`)
                .then(() => setModalVisible(false))
                .catch(e => console.error(e));
        }
    }

    const handleCreateNewRequest = () => {
        setModalVisible(false);
        navigation.navigate("NEW_REQUEST")
    }

    return (
        <Modal closeIcon={isWeb} modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <BubblePhoneIcon aria-label="icône bulle et téléphone" style={styles.icon} />
            <Text style={styles.title}>
                Numéro d’urgence
            </Text>
            <Text style={styles.desc}>
                Un numéro d’urgence à contacter en dehors des horaires d’ouverture, après 17h en semaine, les jours fériés et le week-end, en cas d’incendie, de fuite gaz ou de rupture de canalisation.
            </Text>
            <Button
                style={[styles.button, { marginTop: 20 }]}
                onPress={handleCreateNewRequest}
            >
                Créer une demande
            </Button>
            <Button
                style={styles.button}
                onPress={handleContactButton}
                variant="outlined"
                color={colors.error}
            >
                Contacter l&apos;astreinte
            </Button>
            <CallAstreinteModal
                astreinteNumber={ASTREINTE_PHONE_NUMBER}
                modalVisible={callAstreinteModalVisible}
                setModalVisible={setCallAstreinteModalVisible}
            />
        </Modal>
    )
}

const styles = StyleSheet.create({
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginTop: 20,
        textAlign: "center"
    },
    desc: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginVertical: 20,
        textAlign: "center"
    },
    button: {
        alignSelf: 'stretch',
        marginTop: 10
    },
    asterix: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
        marginTop: 20
    }
});

export default AstreinteModal;
