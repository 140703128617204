import { StyleSheet, View } from 'react-native';
import React, { useState } from 'react';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import RightArrow from "../../../assets/image/right-arrow-icon.svg";
import Text from '../../../core/ui/Text';
import CommunicationsModal from "../../../core/ui/CommunicationsModal";
import handleOpenCommunication from '../../../core/utils/handleOpenCommunication';
import { useNavigation } from '@react-navigation/native';

const CarouselZoneCard = ({ title, desc, icon, color, content, pdfUrl }) => {
    const [communicationsModalVisible, setCommunicationsModalVisible] = useState(false);
    const navigation = useNavigation();

    return (
        <View style={[styles.cardContainer, { borderTopColor: color }]}>
            <View style={styles.iconContainer}>
                {icon}
            </View>
            <View style={styles.textContainer}>
                <Text numberOfLines={2} style={styles.title}>
                    {title}
                </Text>
                <Text style={styles.description} numberOfLines={2}>
                    {desc}
                </Text>
                <Button
                    variant='text'
                    style={styles.linkButton}
                    endIcon={<RightArrow style={styles.icon} />}
                    onPress={() => handleOpenCommunication({
                        content,
                        setModalVisible: setCommunicationsModalVisible,
                        pdf: pdfUrl,
                        handleShowPdf: () => navigation.navigate('PdfPreview', { title, uri: pdfUrl })
                    })}
                    accessibilityLabel="Ouvrir l'article"
                >
                    En savoir plus
                </Button>
            </View>
            <CommunicationsModal
                modalVisible={communicationsModalVisible}
                setModalVisible={setCommunicationsModalVisible}
                title={title}
                content={content}
                icon={icon}
            />
        </View>
    )
};

export default CarouselZoneCard;

const styles = StyleSheet.create({
    cardContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: colors.light,
        padding: 15,
        paddingTop: 10,
        paddingBottom: 5,
        borderTopWidth: 5,
        borderRadius: 10,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3
    },
    iconContainer: {
        width: 70,
        height: "100%",
        padding: 5,
        marginRight: 15,
        alignSelf: "center",
        alignItems: "center",
    },
    textContainer: {
        flex: 1,
        flexDirection: "column",
    },
    title: {
        fontSize: fonts.body2,
        color: colors.primary300,
        marginBottom: 5,
    },
    description: {
        flex: 1,
        fontSize: fonts.subTitle,
        color: colors.primary200,
    },
    linkButton: {
        paddingVertical: 5,
    },
    icon: {
        marginTop: 3.5,
        marginLeft: 5
    }
});
