import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import ArrowDownIcon from "../../../assets/image/arrow-down-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fullHeight } from '../../../core/utils/responsive';

const BuildingSelectionDropdown = ({ component, handleChange, value, dropDownItems }) => {
    const [openDropdown, setOpenDropDown] = useState(false);
    const [defaultSelected, setDefaultSelected] = useState(false);

    const dropdownValue = dropDownItems.length === 1 ? dropDownItems[0].value : value;

    useEffect(() => {
        if (!defaultSelected && dropDownItems.length > 0) {
            const defaultItem = dropDownItems.find(item => item.isMain);
            const defaultValue = defaultItem ? defaultItem.value : dropDownItems[0].value;
            handleChange({ target: { value: defaultValue } });
            setDefaultSelected(true);
        }
    }, [dropDownItems, handleChange, defaultSelected]);

    return (
        <>
            <View style={styles.row}>
                <Text style={styles.title2}>{component.label}</Text>
                {component.isRequired && (
                    <Text style={[styles.error, { fontSize: 20 }]}>*</Text>
                )}
            </View>
            <DropDownPicker
                listMode="SCROLLVIEW"
                open={openDropdown}
                value={dropdownValue}
                items={dropDownItems?.length > 0 ? dropDownItems : [{ label: "Aucun élément disponible", value: "not-found", key: "not-found" }]}
                setOpen={setOpenDropDown}
                setValue={value => handleChange({ target: { value: value() } })}
                ArrowDownIconComponent={() => <ArrowDownIcon />}
                ArrowUpIconComponent={() => <ArrowDownIcon style={{ transform: [{ rotate: "180deg" }] }} />}
                placeholder="Choisir votre location"
                style={styles.dropDown}
                textStyle={{ color: colors.primary300 }}
                listItemContainerStyle={styles.listItemContainer}
                dropDownContainerStyle={styles.dropDownContainer}
                scrollViewProps={{ nestedScrollEnabled: true }}
                maxHeight={fullHeight - 200}
            />
        </>
    )
}
export default BuildingSelectionDropdown;

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        gap: 2
    },
    title2: {
        color: colors.primary300,
        marginBottom: 15,
        marginTop: 10,
    },
    dropDown: {
        borderColor: colors.primary100,
        marginBottom: 10
    },
    dropDownContainer: {
        borderColor: colors.primary100,
        position: 'relative',
        top: -11
    },
    listItemContainer: {
        flex: 1,
        padding: 10,
        borderTopWidth: 1,
        borderColor: colors.primary100
    },
    error: {
        color: colors.error,
        marginBottom: 10,
    }
})