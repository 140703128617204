import { useNavigation } from '@react-navigation/native'
import React, { useContext } from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import CircleArrowIcon from '../../../assets/image/circle-arrow-icon.svg'
import ArrowIcon from '../../../assets/image/right-arrow-icon.svg'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import { RequestContext } from '../../../core/contexts/requestContext'
import Button from '../../../core/ui/Button'
import StatusInfo from '../../../core/ui/StatusInfo'
import Text from '../../../core/ui/Text'
import { isWeb } from '../../../core/utils/responsive'
import IconButton from '../../../core/ui/IconButton'

const ActionsToDoZoneCard = ({ status, title, targetTopicCode, requestData, attributes }) => {
    const navigation = useNavigation();

    const handleRedirection = () => {
        if (targetTopicCode) {
            navigation.navigate("RequestForm", { topicFromRoute: targetTopicCode })
        } else {
            if (isWeb) {
                navigation.navigate("RequestsList", { code: requestData?.code });
            } else {
                navigation.navigate("RequestDetails", {
                    data: requestData, status: requestData?.requestStatus?.code === "REJ" ? "todo" : "inProgress", statusTitle: requestData?.requestStatus?.code === "REJ" ? "Rejetée" : "En cours"
                })
            }
        }
    }

    const { requestsConfigData } = useContext(RequestContext);
    const topicObject = requestsConfigData?.requestTopics?.find(topic => {
        return Number(topic.code) === Number(requestData?.requestTopic)
    })?.object;

    const statusComponentMap = {
        todo: <Button
            variant='text'
            onPress={handleRedirection}
            endIcon={<ArrowIcon style={styles.icon} />}
            style={{ paddingVertical: 0 }}
            accessibilityRole='link'
            textProps={{ numberOfLines: 2 }}
        >
            {attributes?.summary || "Fournir mon document"}
        </Button>,
        inProgress: <StatusInfo status={"inProgress"} title="Votre document est en cours de traitement" />,
        rejected: <StatusInfo status={"todo"} title="Votre document a été refusé" />
    };

    return (
        <Pressable
            onPress={handleRedirection}
            style={[styles.container,
            status === 'rejected' && { borderLeftColor: colors.error },
            status === 'inProgress' && { borderLeftColor: colors.warning }]}
            aria-label={status !== "todo" ? 'Voir le statut de ma demande' : ""}
        >
            <View>
                <Text numberOfLines={2} style={styles.title}>
                    {topicObject || title}
                </Text>
                {statusComponentMap[status]}
            </View>
            {status !== "todo" && !isWeb ? (
                <CircleArrowIcon
                    style={[styles.linkButtonIcon,
                    status === 'rejected' ? { color: colors.error } :
                        status === 'inProgress' ? { color: colors.warning } : {}
                    ]}
                />
            ) : (
                <IconButton
                    accessibilityRole='link'
                    accessibilityLabel='voir les détails de la demande'
                    onPress={handleRedirection}
                    icon={<CircleArrowIcon s
                        style={styles.linkButtonIcon}
                        color={
                            status === 'rejected' ? colors.error :
                                status === 'inProgress' ? colors.warning : ''
                        }
                    />}
                />
            )}
        </Pressable>
    )
}

export default ActionsToDoZoneCard

const styles = StyleSheet.create({
    container: {
        borderLeftWidth: 10,
        borderLeftColor: colors.primary400,
        borderRadius: 10,
        backgroundColor: colors.light,
        padding: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body2,
        marginBottom: 3
    },
    icon: {
        marginTop: 3.5,
        marginLeft: 5
    },
    linkButtonIcon: {
        transform: isWeb ? "rotate(180deg)scale(.8)" : [{ rotate: "180deg" }, { scale: .8 }],
    },
})