import React from 'react'
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { breakpoints } from '../../../core/constants/breakpoints'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import getThumbnail from '../../../core/utils/getThumbnail'
import CarouselZoneCard from './CarouselZoneCard'

const AnnouncesZone = ({ data }) => {
    const { width } = useWindowDimensions();
    const styles = makeStyles(width)

    const sortedData = data?.sort((a, b) => {
        return a.attributes.priority === 'importante' ? -1 : b.attributes.priority === 'importante' ? 1 : 0;
    });

    if (!sortedData || sortedData.length === 0) return null;

    return (
        <View style={styles.container}>
            <Text style={styles.title}>L&apos;actu du moment</Text>
            {sortedData?.map(item =>
                <View
                    key={item.id}
                    style={styles.infoCard}
                >
                    <CarouselZoneCard
                        icon={getThumbnail(item.attributes.thumbnail.data)}
                        title={item.attributes.title}
                        desc={item.attributes.summary}
                        color={item.attributes.priority === "importante" ? colors.error : colors.warning}
                        content={item.attributes.content}
                        pdfUrl={item.attributes.document.data?.attributes?.url}
                    />
                </View>
            )}
        </View>
    )
}

export default AnnouncesZone

const makeStyles = (width) => StyleSheet.create({
    container: {
        paddingHorizontal: width >= breakpoints.medium ? 0 : 15,
        marginTop: 30,
    },
    infoCard: {
        marginVertical: 10,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        fontWeight: "bold",
        marginTop: 5,
        marginBottom: 5
    },
})