import { useState, useEffect, useContext } from 'react';
import { AxiosContext } from '../contexts/axiosContext';
import reportError from '../utils/reportError';

function useDataFetch(url, requestConfig, deps = [], autostart = true) {
    const { authAxios } = useContext(AxiosContext);

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [controller, setController] = useState(null);

    useEffect(() => {
        if (!autostart) return;
        let isMounted = true;

        const __controller = new AbortController()
        setController(__controller)

        setIsLoading(true);
        authAxios.request({
            url,
            method: 'GET',
            signal: __controller.signal,
            ...requestConfig,
        })
            .then((response) => response.data)
            .then((data) => {
                if (!isMounted) return;
                setData(data);
                setIsLoading(false);
            })
            .catch((error) => {
                if (!isMounted) return;
                setError(error);
                setIsLoading(false);
                reportError(false, `\nurl: ${url}\nerror: ${error.message}`);
            });

        return () => isMounted = false;
    }, [url, ...deps, autostart]);

    return { data, isLoading, error, controller };
}

export default useDataFetch;