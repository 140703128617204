import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { colors } from '../constants/colors';

const RadioButton = ({ value, setValue, accessibilityLabel = "Accepter" }) => {
    return (
        <Pressable
            onPress={() => setValue(!value)}
            style={styles.button}
            accessibilityRole='button'
            accessibilityLabel={accessibilityLabel}
        >
            <View
                style={[
                    styles.dot,
                    value === true && {
                        backgroundColor: colors.secondary300
                    }
                ]}
            />
        </Pressable>
    );
};

export default RadioButton;

const styles = StyleSheet.create({
    button: {
        height: 22,
        width: 22,
        backgroundColor: colors.light,
        borderRadius: 50,
        borderWidth: 1,
        borderColor: colors.grey,
        justifyContent: "center",
        alignItems: "center"
    },
    dot: {
        height: 12,
        width: 12,
        borderRadius: 50,
    }
})
