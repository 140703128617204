import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '../constants/colors';
import Button from './Button';

const ToggleButtonGroup = ({ value, onValueChange, data, direction = "column", containerStyle, buttonsStyle }) => {
    const [activeButton, setActiveButton] = useState(value);

    const handleButtonPress = (buttonValue) => {
        setActiveButton(buttonValue);
        onValueChange && onValueChange(buttonValue)
    };

    return (
        <View
            style={[
                direction === "row" ?
                    { flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" } : { flexDirection: "column" },
                containerStyle
            ]}
            testID="toggle-button-container"
        >
            {
                data?.map((item, i) => (
                    <Button
                        key={i}
                        variant='outlined'
                        color={colors.primary300}
                        onPress={() => handleButtonPress(item.value)}
                        style={[
                            styles.button,
                            {
                                borderColor: activeButton === item.value ? colors.secondary300 : colors.primary300
                            },
                            direction === "row" && { width: "47%" },
                            buttonsStyle
                        ]}
                        textStyle={{ textAlign: "center", color: activeButton === item.value ? colors.secondary300 : colors.primary300 }}
                    >
                        {item.title}
                    </Button>
                ))
            }
        </View >
    );
};

export default ToggleButtonGroup;

const styles = StyleSheet.create({
    button: {
        backgroundColor: colors.light,
        marginBottom: 15,
        borderRadius: 10,
    }
})
