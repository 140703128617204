import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import { fullWidth } from '../../../core/utils/responsive';
import PaymentModal from './PaymentModal';
import { isWeb } from '../../../core/utils/responsive';
import { breakpoints } from '../../../core/constants/breakpoints';

const sortData = (data) => {
    return (data || []).sort((a, b) => {
        if (a.operationDate === b.operationDate) {
            if (a.operationRank > b.operationRank) return -1
            if (b.operationRank > a.operationRank) return 1
        }
        if (a.operationDate === null) return 1
        if (b.operationDate === null) return -1
        return new Date(b.operationDate) - new Date(a.operationDate)
    });
}

const findPeriod = (data) => {
    return data.find(i => i.startPeriodDate && i.endPeriodDate);
}

const findPayment = (data) => {
    return data.find(i => i.amount < 0);
}

const findPRV = (data, period) => {
    return data.slice(0, data.indexOf(period)).find(i => i.operationType === 'PRV');
}

const AdditionalPaymentCard = ({ data, showSecondButton }) => {
    const navigation = useNavigation()
    const { width } = useWindowDimensions()
    const styles = makeStyles(width)

    const [showPaymentModal, setShowPaymentModal] = useState(false)

    const [isPaid, setIsPaid] = useState(false)
    const [isFuturPRV, setIsFuturPRV] = useState(false);

    const [amount, setAmount] = useState("0")

    useEffect(() => {
        const sortedData = sortData(data);
        const myPeriod = findPeriod(sortedData);
        const myRecord = sortedData[0];
        const myPayment = findPayment(sortedData);
        const PRV = findPRV(sortedData, myPeriod);

        const isPRV = PRV && new Date(PRV.operationDate) > new Date();
        const isZERO = myRecord?.balanceAmount <= 0;

        setIsPaid(isZERO)
        setIsFuturPRV(isPRV)

        if (myRecord?.balanceAmount >= 0) {
            if (isPRV) {
                // setAmount(PRV.amount * -1)
                setAmount(myRecord.balanceAmount)
            } else {
                setAmount(myRecord.balanceAmount)
            }
        } else {
            setAmount("0")
        }
    }, [data])

    if (isPaid) return null;

    return (
        <View style={styles.container}>
            <View style={[styles.contentContainer, isPaid && { alignItems: 'center' }]}>
                <Text style={styles.title}>Montant à régler</Text>

                {(!isPaid || isFuturPRV) &&
                    <Text style={styles.price}>{parseFloat(amount).toFixed(2)} €</Text>
                }

                {showSecondButton &&
                  <Button
                    variant='text'
                    onPress={() => navigation.navigate("Payment")}
                    style={styles.detailButton}
                    textStyle={styles.detailButtonText}
                    accessibilityRole='link'
                  >
                      Consulter le détail de votre solde
                  </Button>
                }
            </View>

            <View style={styles.buttonsContainer}>
                <Button
                    onPress={() => setShowPaymentModal(true)}
                    style={styles.button}
                >
                    Payer
                </Button>

                <PaymentModal
                    amount={parseFloat(amount).toFixed(2)}
                    setAmount={setAmount}
                    modalVisible={showPaymentModal}
                    setModalVisible={setShowPaymentModal}
                />
                {showSecondButton && (
                  <Button
                    variant='outlined'
                    onPress={() => navigation.navigate("Payment")}
                    style={styles.button}
                    accessibilityRole='link'
                  >
                      Mes échéances
                  </Button>
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        backgroundColor: colors.light,
        borderRadius: 10,
        paddingVertical: 15,
        paddingHorizontal: 15,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        marginTop: 10,
    },
    title: {
        fontSize: fonts.body2,
        color: colors.primary200
    },
    price: {
        fontSize: 22,
        color: colors.primary300,
        marginTop: 5,
    },
    buttonsContainer: {
        alignSelf: "stretch",
        flexDirection: fullWidth >= 360 ? "row" : "column",
        gap: 10,
        marginTop: 20,
        marginHorizontal: 5,
    },
    firstButton: {
        flex: 1,
        width: "100%",
    },
    secondButton: {
        flex: 1,
        width: "100%",
    },
    detailButton: {
        alignSelf: 'center',
        paddingVertical: 4,
    },
    detailButtonText: {
        color: colors.grey,
        textDecorationLine: 'underline',
    }
});

const makeStyles = (width) => StyleSheet.create({
    container: {
        flexDirection: isWeb && width >= breakpoints.mobile ? "row" : "column",
        alignItems: "center",
        backgroundColor: colors.light,
        borderRadius: 10,
        paddingVertical: 15,
        paddingHorizontal: 15,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        marginTop: 10
    },
    contentContainer: {
        flex: 1,
        alignItems: (isWeb && width >= breakpoints.mobile) ? "flex-start" : "center",
    },
    buttonsContainer: {
        flex: 1,
        alignSelf: "stretch",
        flexDirection: (isWeb && width >= breakpoints.mobile) ? "row" : "column",
        gap: 10,
        marginTop: (isWeb && width >= breakpoints.mobile) ? 0 : 15,
    },
    button: {
        flex: 1,
        width: "100%",
    },
    title: {
        fontSize: fonts.body2,
        color: colors.primary200,
    },
    title2: {
        fontSize: fonts.body2,
        color: colors.primary300,
        marginBottom: 5,
    },
    checkIcon: {
        transform: [{ translateY: 7 }, { translateX: -7 }],
        marginRight: isWeb ? 7 : null,
        marginBottom: isWeb ? -7 : null
    },
    price: {
        fontSize: 22,
        color: colors.primary300,
        marginTop: 5,
    },
    statusContainer: {
        marginTop: 5,
    },
    detailButton: {
        paddingVertical: 4,
    },
    detailButtonText: {
        color: colors.grey,
        textDecorationLine: 'underline',
    }
})

export default AdditionalPaymentCard;
