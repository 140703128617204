import React from 'react';
import Modal from '../../../core/ui/Modal';
import { Linking, StyleSheet } from 'react-native';
import Text from '../../../core/ui/Text';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import AppleIcon from "../../../assets/image/apple-icon.svg";
import GoogleIcon from "../../../assets/image/google-icon.svg";

const DownloadMobileAppModal = ({ modalVisible, setModalVisible }) => {

    const handleOpenLink = (link) => {
        Linking.openURL(link);
    }

    return (
        <Modal
            containerStyle={styles.container}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            closeIcon
        >
            <Text style={styles.title}>
                Télécharger l’application
            </Text>
            <Button
                style={styles.button}
                variant="outlined"
                color={colors.primary300}
                startIcon={<GoogleIcon width={25} style={styles.buttonIconStyle} />}
                onPress={() => handleOpenLink('https://play.google.com/store/apps/details?id=fr.altemed.acm.myAcm&pcampaignid=web_share')}
            >
                Avec Google
            </Button>
            <Button
                style={styles.button}
                variant="outlined"
                color={colors.primary300}
                startIcon={<AppleIcon width={25} style={styles.buttonIconStyle} />}
                onPress={() => handleOpenLink('https://apps.apple.com/fr/app/myacm/id6451966364?platform=iphone')}
            >
                Avec Apple Store
            </Button>
        </Modal>

    )
}

export default DownloadMobileAppModal;

const styles = StyleSheet.create({
    container: {
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 40,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center",
        marginTop: 20,
        marginBottom: 20,
    },
    button: {
        alignSelf: "stretch",
        marginBottom: 10,
    },
    buttonIconStyle: {
        marginRight: 10,
    },
})