import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import AppleIcon from '../../../assets/image/apple-icon.svg';
import { colors } from '../../../core/constants/colors';
import { getAuth, signInWithPopup, OAuthProvider, signInWithCredential } from "firebase/auth";
import Button from "../../../core/ui/Button";
import reportError from '../../../core/utils/reportError';

const provider = new OAuthProvider('apple.com');
provider.addScope('email');
provider.addScope('name');
provider.setCustomParameters({
    locale: 'fr'
});

const AppleSignInButton = () => {
    const [isAvailable, setIsAvailable] = useState(true);

    const signInAsync = useCallback(async () => {
        signInWithPopup(getAuth(), provider)
            .then(async (result) => {
                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result);

                // Ensure Apple returned a user identityToken
                if (!credential.idToken) {
                    throw new Error('Apple Sign-In failed - no identify token returned');
                }

                // Sign-in the user with the credential
                await signInWithCredential(getAuth(), credential);
            })
            .catch((e) => {
                if (e.code === 'auth/cancelled-popup-request') {
                    // handle that the user canceled the sign-in flow
                } else if (e.code === 'auth/popup-closed-by-user') {
                    // user cancelled the login flow
                } else {
                    // handle other errors
                    console.error(e);
                    const errorMessage = e.message;
                    reportError(true, errorMessage)
                }
            });
    }, [])

    return isAvailable && (
        <Button
            style={styles.socialMediaButton}
            variant='outlined'
            startIcon={<AppleIcon style={styles.buttonIconStyle} />}
            color={colors.primary300}
            disabled={false}
            onPress={signInAsync}
        >
            Continuer avec Apple
        </Button>
    )
}

export default AppleSignInButton;

const styles = StyleSheet.create({
    socialMediaButton: {
        borderRadius: 10,
        marginBottom: 15,
        width: "100%",
        height: 51,
    },
    buttonIconStyle: {
        marginRight: 10,
    }
})
