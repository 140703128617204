import React, { createContext, useContext, useEffect, useState } from 'react';
import { FirebaseContext } from './firebaseContext';
import { AxiosContext } from './axiosContext';
import * as Sentry from 'sentry-expo';
import { isWeb } from '../utils/responsive';
import reportError from '../utils/reportError';

export const UserContext = createContext({
    user: null,
    claims: null,
    isLoading: false,
});

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [claims, setClaims] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const { firebaseUser, accessToken } = useContext(FirebaseContext);
    const { authAxios } = useContext(AxiosContext);

    const me = async () => {
        setIsLoading(user === null);
        const me = await authAxios.patch('me', { lastSeenDate: new Date() })
            .then(res => res.data)
            .catch(err => reportError(false, err.message))
            .finally(() => setIsLoading(false));
        setUser(me);
    }
    const myClaims = async () => {
        const idTokenResult = await firebaseUser?.getIdTokenResult();
        setClaims(idTokenResult?.claims);
    }

    useEffect(() => {
        if (accessToken) {
            me();
            myClaims();
        } else {
            setUser(null);
            setClaims({});
        }
    }, [accessToken]);

    useEffect(() => {
        myClaims();
    }, [firebaseUser]);

    useEffect(() => {
        if (isWeb) {
            if (user) Sentry.Browser.setUser({ email: user.email });
            else Sentry.Browser.setUser(null);
        } else {
            if (user) Sentry.Native.setUser({ email: user.email });
            else Sentry.Native.setUser(null);
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ user, claims, isLoading }}>
            {children}
        </UserContext.Provider>
    );
};