import React, { useContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import WebView from 'react-native-webview';
import Spinner from '../../../core/ui/animations/Spinner';
import LoadingIcon from "../../../assets/image/loading-icon.svg"
import { ContractContext } from '../../../core/contexts/contractContext';
import { API_URL } from '../../../core/contexts/axiosContext';
import { CommonActions, useFocusEffect, useLinkTo, useNavigation } from '@react-navigation/native';
import reportError from '../../../core/utils/reportError';

export default function PaymentMake({ route }) {
    const navigation = useNavigation()
    const linkTo = useLinkTo()

    const { loadContracts } = useContext(ContractContext)

    const [isLoading, setIsLoading] = useState(false)

    const { formToken } = route.params;
    const url = `${API_URL}/payments/paymentForm?formToken=${formToken}&origin=${window.origin}`

    async function onMessage(event) {
        try {
            const result = JSON.parse(event.nativeEvent.data)
            console.log(result.clientAnswer?._type, result.clientAnswer?.orderStatus)
        } catch (e) {
            reportError(false, e)
        } finally {
            setIsLoading(true)
            await loadContracts()
                .finally(() => setIsLoading(false))
            linkTo('/paiements')
            linkTo('/p/paid')
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            return () => {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [{
                            name: 'PaymentsList',
                        },],
                    })
                );
            }
        }, [navigation])
    )

    if (!url) return null;

    return (
        <View style={styles.container}>
            {url && (
                <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <WebView
                        onMessage={onMessage}
                        originWhitelist={['*']}
                        onLoadStart={(e) => setIsLoading(true)}
                        onLoadEnd={(e) => setIsLoading(false)}
                        source={{ uri: url }}
                    ></WebView>
                </View>
            )}
            {isLoading && (
                <View style={[styles.loading]} accessibilityLabel="chargement en cours">
                    <Spinner style={styles.loading}>
                        <LoadingIcon width={35} height={40} aria-label="icône chargement" />
                    </Spinner>
                </View>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    }
});
