import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import AgencyIcon from "../../../assets/image/agency-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { ContractContext } from '../../../core/contexts/contractContext';
import Text from '../../../core/ui/Text';
import { getMainContractAgency } from '../../../core/utils/parseContract';
import { fullWidth, isWeb } from '../../../core/utils/responsive';
import { breakpoints } from '../../../core/constants/breakpoints';

const AgencyCard = ({ containerStyle }) => {
    const { contract } = useContext(ContractContext)
    const agency = getMainContractAgency(contract);

    if (!agency) return null;

    return (
        <View style={[styles.container, containerStyle]}>
            <AgencyIcon height={47} />
            <Text style={styles.title}>
                {agency.label}
            </Text>
            <Text style={styles.address}>
                {agency.streetName} {agency.streetName2}{"\n"}{agency.postCode} {agency.city}
            </Text>
            <Text style={styles.openingHours}>
                Ouverte du lundi au vendredi de 09h00 à 11h30{"\n"}Le mardi également ouverte de 14h30 à 16h00
            </Text>
        </View >
    )
}

export default AgencyCard

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        backgroundColor: colors.light,
        paddingVertical: 15,
        paddingHorizontal: 20,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        borderRadius: isWeb && fullWidth >= breakpoints.medium ? 10 : null
    },
    title: {
        fontSize: fonts.body2,
        color: colors.primary300,
        marginTop: isWeb ? 5 : 15,
        textAlign: "center",
    },
    address: {
        fontSize: fonts.subTitle,
        color: colors.primary200,
        marginTop: 5,
        textAlign: "center",
    },
    openingHours: {
        fontSize: fonts.subTitle,
        color: colors.primary200,
        marginTop: 15,
        textAlign: "center",
    },
    button: {
        marginTop: 12,
        paddingVertical: 5,
        marginBottom: 5
    }
})