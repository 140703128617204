import React from 'react';
import { Pressable, StyleSheet, View, useWindowDimensions } from 'react-native';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';
import Text from './Text';

const TextIconCard = ({ onPress, icon, title, description, containerStyle, direction }) => {
    const { width } = useWindowDimensions();
    const styles = makeStyles(width)

    return (
        <Pressable
            onPress={onPress}
            accessibilityRole='link'
            accessibilityLabel={"voir " + title}
            style={({ pressed }) =>
                [styles.container, containerStyle, direction === "column" && styles.containerColumn, pressed && styles.onPress]
            }
            testID="container"
        >
            {description &&
                <Text style={[styles.description, direction === "column" && { textAlign: "center" }]}>
                    {description}
                </Text>
            }
            <Text
                style={[styles.title, direction === "column" && styles.columnTitle]}
                testID="title-text"
            >
                {title}
            </Text>
            {
                icon &&
                <View style={[styles.iconContainer, direction === "column" && { flexGrow: 0 }]} testID="icon-container">
                    {icon}
                </View>
            }
        </Pressable >
    );
};

export default TextIconCard;

const makeStyles = (width) => StyleSheet.create({
    container: {
        flexDirection: width > 360 ? "row" : "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "48%",
        padding: 15,
        backgroundColor: colors.light,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: colors.secondary300,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        marginBottom: 10,
    },
    containerColumn: {
        flexDirection: "column-reverse",
        justifyContent: "center"
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body2,
        maxWidth: 90,
        textAlign: width > 360 ? "left" : "center",
        marginBottom: width > 360 ? null : 10,
    },
    columnTitle: {
        textAlign: "center",
        marginVertical: 15,
        fontSize: fonts.body1,
        maxWidth: "100%"
    },
    description: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
        textAlign: width > 360 ? "left" : "center",
        marginBottom: 10,
    },
    iconContainer: {
        flexGrow: 1,
        alignItems: "flex-end"
    },
    onPress: {
        opacity: .8,
        transform: [{ scale: 0.97 }],
    }
})