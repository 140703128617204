import React from 'react';
import Modal from '../../../core/ui/Modal';
import AgencyCard from './AgencyCard';
import { StyleSheet } from 'react-native';

const AgencyModal = ({ modalVisible, setModalVisible }) => {
    return (
        <Modal
            containerStyle={styles.modalContainer}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            closeIcon
        >
            <AgencyCard containerStyle={styles.container} />
        </Modal>
    )
}

export default AgencyModal;

const styles = StyleSheet.create({
    modalContainer: {
        padding: 0
    },
    container: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0,
        shadowRadius: 0,
        elevation: 0,
    }
})