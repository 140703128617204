import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import * as yup from 'yup';
import BubblesIcon from "../../../assets/image/interrogation-bubbles-icon.svg";
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';
import ToggleButtonGroup from '../../../core/ui/ToggleButtonGroup';
import reportError from '../../../core/utils/reportError';

const validationSchema = yup.object().shape({
    other: yup.boolean().required(),
    number: yup
        .string()
        .when('other', {
            is: true,
            then: () => yup.string()
                .matches(/^(0[1-9])[-\.\s]?(\d{2}[-\.\s]?){4}$/, "Merci de saisir un n° de téléphone valide.")
                .required('Un numéro de téléphone est requis'),
            otherwise: () => yup.string().optional()
        }),
})

const ContactNumberChoice = ({ route, navigation }) => {
    const [userChoice, setUserChoice] = useState("");
    const [showNumberTextField, setShowNumberTextField] = useState(false);

    const { authAxios } = useContext(AxiosContext);

    const { data } = route?.params || {};
    const buttonGroupData = [data?.person?.mobilePhone]
        ?.filter(i => !!i)
        ?.map((i, k) => {
            return {
                id: k,
                value: i,
                title: i?.match(/.{1,2}/g)?.join(' ')
            }
        });
    buttonGroupData.push({
        id: 1000,
        value: "autre",
        title: "Autre",
    });

    const onButtonChange = (userChoice, setFieldValue) => {
        setUserChoice(userChoice);

        if (userChoice === "autre") {
            setShowNumberTextField(true);
            setFieldValue('other', true);
        } else {
            setShowNumberTextField(false);
            setFieldValue('number', "");
            setFieldValue('other', false);
        }
    };

    const onSubmit = async (values) => {
        if (!data?.person?.id) return;

        try {
            authAxios.patch(`ikos/persons/${data.person.id}`, {
                mobilePhone: values.number || userChoice,
                requestedMobilePhone: values.number || userChoice,
            })
        } catch (e) {
            reportError(true, e);
        }

        navigation.navigate("ContactEmailChoice", { data });
    };

    return (
        <ScrollView contentContainerStyle={[styles.container, customStyles.reducedPageContainer]}>
            <Formik
                initialValues={{ number: "", other: showNumberTextField }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleChange, handleSubmit, setFieldValue, values, errors, isValid, dirty }) => (
                    <>
                        <BubblesIcon />
                        <Text style={styles.title}>À quel numéro pouvons-nous vous joindre ?</Text>

                        <ToggleButtonGroup
                            buttonsStyle={styles.buttonsStyle}
                            data={buttonGroupData}
                            value={userChoice}
                            onValueChange={(userChoice) => onButtonChange(userChoice, setFieldValue)}
                        />

                        {showNumberTextField && (
                            <TextField
                                containerStyle={styles.numberInput}
                                placeholder={"Entrez votre numéro"}
                                keyboardType="numeric"
                                onChangeText={handleChange("number")}
                            />
                        )}
                        {errors.number && values.number !== "" && <Text style={styles.error}>{errors.number}</Text>}

                        <Button
                            style={styles.buttonsStyle}
                            onPress={handleSubmit}
                            disabled={userChoice === "" || (userChoice === "autre" && (!isValid || !dirty))}
                        >
                            Valider
                        </Button>
                    </>
                )}
            </Formik>
        </ScrollView >
    );
};

export default ContactNumberChoice

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 30,
        paddingTop: 50,
    },
    title: {
        fontSize: fonts.h2,
        color: colors.primary300,
        marginTop: 23,
        marginBottom: 40,
    },
    numberInput: {
        marginVertical: 10
    },
    buttonsStyle: {
        width: "100%"
    },
    error: {
        color: colors.error,
        marginBottom: 10,
        alignSelf: "flex-start"
    }
})