import { StyleSheet, View } from 'react-native'
import React from 'react'
import Text from './Text'
import { fonts } from '../constants/fonts'
import { colors } from '../constants/colors'

const validStatusValues = ['todo', 'inProgress', 'done', 'default'];

/**
   * Valid values of status prop are 'todo', 'inProgress' or 'done'
**/
const StatusInfo = ({ status = "default", title, containerStyle, titleStyle, titleNbOfLines = 1 }) => {
    if (!validStatusValues.includes(status)) {
        throw new Error(`Invalid status value: ${status}`);
    }

    return (
        <View
            accessible={true}
            accessibilityLabel={`statut: ${title}`}
            style={[styles().container, containerStyle]}
            testID="status-info-container"
        >
            <View  style={styles(status).dot} testID="status-info-dot" />
            <Text  style={[styles(status).title, titleStyle]} numberOfLines={titleNbOfLines} testID="status-info-title">{title}</Text>
        </View>
    )
}

export default StatusInfo

const styles = (status) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: "center"
    },
    dot: {
        width: 9.5,
        height: 9.5,
        marginTop: 0,
        marginRight: 5,
        borderRadius: 50,
        backgroundColor: status === "todo" ? colors.error : status === "inProgress" ? colors.warning : status === "done" ? colors.secondary300 : colors.primary300
    },
    title: {
        fontSize: fonts.body2,
        color: status === "todo" ? colors.error : status === "inProgress" ? colors.warning : status === "done" ? colors.secondary300 : colors.primary300
    },
})