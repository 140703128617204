import React, { useContext } from 'react';
import { ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';
import { breakpoints } from '../../../core/constants/breakpoints';
import { customStyles } from '../../../core/constants/customStyles';
import { ContractContext } from '../../../core/contexts/contractContext';
import useCommunicationsZoneData from '../../../core/hooks/useCommunicationsZoneData';
import useTrackScreenView from '../../../core/hooks/useTrackScreenView';
import Loader from '../../../core/ui/Loader';
import ActionsToDoZone from '../components/ActionsToDoZone';
import AlertsZone from '../components/AlertsZone';
import AnnouncesZone from '../components/AnnouncesZone.web';
import CarouselZone from '../components/CarouselZone.web';
import ClientAccountSection from '../components/ClientAccountSection';
import ContractAddSection from '../components/ContractAddSection';
import DownloadMobileApp from '../components/DownloadMobileApp';
import PaymentsSection from '../components/PaymentsSection';
import RequestsSection from '../components/RequestsSection';

const UserHome = () => {
    const { alerts, carousel, announces, actionsToDo } = useCommunicationsZoneData();
    const { width } = useWindowDimensions();
    const styles = makeStyles(width);

    useTrackScreenView("Homepage");

    return (
        <ScrollView contentContainerStyle={[styles.container, customStyles.pageContainer]}>
            <View style={styles.firstContainer}>
                {width <= breakpoints.medium && (
                    <DownloadMobileApp />
                )}
                <AlertsZone data={alerts} />
                <PaymentsSection />
                <ActionsToDoZone data={actionsToDo} />
                <RequestsSection />
                <ClientAccountSection />
            </View>
            <View style={styles.secondContainer}>
                {width >= breakpoints.medium && (
                    <DownloadMobileApp />
                )}
                <CarouselZone data={carousel} />
                <AnnouncesZone data={announces} />
                <ContractAddSection />
            </View>
        </ScrollView>
    )
};

export default UserHome;

const makeStyles = (width) => StyleSheet.create({
    container: {
        marginVertical: width >= breakpoints.medium ? 50 : 20,
        flexDirection: width >= breakpoints.medium ? "row" : "column",
        alignItems: width >= breakpoints.medium ? null : "center",
        justifyContent: "space-between"
    },
    alert: {
        marginHorizontal: 15,
        marginBottom: 20,
    },
    firstContainer: {
        width: width >= breakpoints.medium ? "60%" : "100%",
    },
    secondContainer: {
        width: width >= breakpoints.medium ? "30%" : "100%",
        marginRight: width >= breakpoints.medium ? 20 : 0
    },
    spinnerContainer: {
        height: "95%",
        justifyContent: "center",
        alignItems: "center"
    }
});