import { CommonActions, useLinkTo, useNavigation } from '@react-navigation/native';
import Markdown from '@ronradtke/react-native-markdown-display';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import ArrowDownIcon from "../../assets/image/arrow-down-scroll.svg";
import { colors } from '../../core/constants/colors';
import { fonts } from '../../core/constants/fonts';
import { AxiosContext } from '../../core/contexts/axiosContext';
import { CommunicationsContext } from '../../core/contexts/communicationsContext';
import Button from '../../core/ui/Button';
import IconButton from '../../core/ui/IconButton';
import Loader from '../../core/ui/Loader';
import RadioButton from '../../core/ui/RadioButton';
import reportError from '../../core/utils/reportError';
import { isWeb } from '../../core/utils/responsive';

const GeneralConditions = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isReachedBottom, setIsReachedBottom] = useState(false);

  const scrollViewRef = useRef(null);

  const linkTo = useLinkTo();

  const { isLoading } = useContext(CommunicationsContext);
  const [isCommunicationsLoading, setIsCommunicationsLoading] = useState(true);

  const { authAxios } = useContext(AxiosContext);

  const navigation = useNavigation();

  const [conditionsData, setConditionsData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setIsCommunicationsLoading(true);
        const communicationsConditions = await authAxios.get(`communications/conditions`).then(res => res?.data?.data?.[0]?.attributes?.zone?.data?.attributes?.communications?.data?.[0]?.attributes);
        setConditionsData(communicationsConditions);
      } catch (error) {
        reportError(false, error);
      } finally {
        setIsCommunicationsLoading(false);
      }
    })();
  }, [authAxios]);

  const handleScrollToBottom = () => {
    scrollViewRef.current?.scrollToEnd({ animated: true });
  }

  const handleScrolledToBottom = (event) => {
    const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent;
    const isAtBottom = contentOffset.y + layoutMeasurement.height >= contentSize.height - 1;

    if (isAtBottom) {
      setIsReachedBottom(true);
    }
  }

  const handleSubmit = async () => {
    await authAxios.patch('me', { termsApprovalDate: new Date() })
      .then(res => res.data)
      .catch(err => reportError(true, err))
      .finally(() => {
        if (isWeb) {
          linkTo('/');
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{
                name: 'MultiContracts',
              },],
            })
          );
        }
      })
  }

  if (isLoading || isCommunicationsLoading) {
    return <Loader />;
  }

  return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) => (
        <View style={[styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
          <Text style={styles.title}>
            {conditionsData?.title}
          </Text>
          <ScrollView
            ref={scrollViewRef}
            scrollEventThrottle={200}
            onScroll={handleScrolledToBottom}
            persistentScrollbar
            showsVerticalScrollIndicator
            style={styles.contentContainer}>
            <Markdown style={styles.markdown}>
              {conditionsData?.content || ""}
            </Markdown>
          </ScrollView>

          <IconButton onPress={handleScrollToBottom} icon={<ArrowDownIcon />} style={styles.arrowDown} />

          <View style={styles.radioButtonContainer}>
            {isReachedBottom && (
              <Pressable onPress={() => setIsAccepted(!isAccepted)} style={{ flexDirection: "row", columnGap: 10 }}>
                <RadioButton
                  value={isAccepted}
                  setValue={setIsAccepted}
                >
                </RadioButton>
                <Text style={styles.agreeText}>J&apos;accepte les conditions générales d&apos;utilisation</Text>
              </Pressable>
            )}
          </View>

          <Button onPress={handleSubmit} disabled={!isAccepted || !isReachedBottom} style={styles.nextButton}>
            Suivant
          </Button>
        </View>
      )}
    </SafeAreaInsetsContext.Consumer>
  )
}

export default GeneralConditions

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.light,
    paddingHorizontal: 20,
    margin: isWeb ? 40 : 0,
  },
  title: {
    fontSize: fonts.h2,
    color: colors.primary300,
    marginBottom: 20,
    marginTop: isWeb ? 20 : 0,
    textAlign: "center"
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    borderWidth: 1,
    borderColor: colors.primary100,
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  arrowDown: {
    marginTop: -36,
    alignSelf: "flex-end",
    width: 82,
    height: 82,
  },
  radioButtonContainer: {
    flexDirection: "row",
    columnGap: 10,
    marginBottom: 20
  },
  agreeText: {
    color: colors.primary300,
    fontSize: fonts.body1,
    width: "90%"
  },
  markdown: {
    ordered_list: {
      color: colors.primary300,
      marginTop: 15,
    },
    ordered_list_icon: {
      marginLeft: 0
    },
    ordered_list_content: {
      marginBottom: 12
    },
    text: {
      color: colors.primary300
    },
    body: {
      marginBottom: 11.5
    }
  },
  nextButton: {
    marginVertical: isWeb ? 0 : 5,
    paddingHorizontal: 20,
    marginBottom: isWeb ? 20 : 0,
  }
})
