import React, { useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import Modal from '../../../core/ui/Modal';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import Spinner from '../../../core/ui/animations/Spinner';
import LoadingWhiteIcon from '../../../assets/image/loading-white-icon.svg';
import reportError from '../../../core/utils/reportError';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import { FirebaseContext } from '../../../core/contexts/firebaseContext';
import BubblesIcon from '../../../assets/image/interrogation-bubbles-icon.svg';

const DeleteMeModal = ({ modalVisible, setModalVisible }) => {
    const [isLoading, setModalIsLoading] = useState(false);

    const { authAxios } = useContext(AxiosContext);
    const { signOut } = useContext(FirebaseContext);

    const onPress = async () => {
        setModalIsLoading(true)
        await authAxios.delete(`me`)
            .catch(err => {
                reportError(true, err.message);
            })
            .finally(async () => {
                setModalIsLoading(false);
                setModalVisible(false);
                signOut()
            })
    };

    return (
        <Modal modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <BubblesIcon />
            <Text style={styles.title}>
                Vous êtes sur le point de supprimer votre compte à MyACM.
            </Text>
            <Text style={styles.subTitle}>Confirmez-vous?</Text>
            <Button
                style={[styles.button, { marginTop: 20 }]}
                color={colors.error}
                onPress={onPress}
                endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
            >
                Oui, je confirme
            </Button>
            <Button
                style={styles.button}
                onPress={() => setModalVisible(false)}
                variant="outlined"
            >
                Annuler
            </Button>
        </Modal >
    )
}

export default DeleteMeModal

const styles = StyleSheet.create({
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center",
        marginTop: 20,
    },
    subTitle: {
        color: colors.primary300,
        marginTop: 20,
    },
    button: {
        alignSelf: "stretch",
        marginTop: 10,
    },
});
