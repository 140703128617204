import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import PlaneIcon from "../../../assets/image/plane-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import PeopleEnjoying from "../../../assets/image/people-enjoying-icon-blue.svg"

const EmailConfirmation = ({ route, navigation }) => {
    const { action } = route.params;

    const onPress = (values) => {
        navigation.navigate("ConnectionChoice");
    }

    useEffect(() => {
        if (!['signup', 'reset'].includes(action)) navigation.goBack()
    }, [])

    return (
        <View style={styles.container}>
            <View style={styles.formContainer}>
                <PlaneIcon style={styles.avatar} aria-label="icône avion en papier" />
                {action === 'signup' && (<>
                    <Text style={styles.title}>Un mail vient de vous être envoyé pour initialiser votre mot de passe.</Text>
                    <Text style={styles.desc}>Une fois initialisé, vous pourrez vous connecter.</Text>
                </>)}
                {action === 'reset' && (<>
                    <Text style={styles.title}>Un mail vient de vous être envoyé pour ré-initialiser votre mot de passe.</Text>
                    <Text style={styles.desc}>Une fois réinitialisé, vous pourrez vous connecter.</Text>
                </>)}
                <Button
                    onPress={onPress}
                    style={styles.submitButton}
                >
                    Revenir à l&apos;écran de connexion
                </Button>
            </View>
            <PeopleEnjoying style={styles.backgroundImage} />
        </View>
    )
}

export default EmailConfirmation

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.primary400,
        flex: 1
    },
    backgroundImage: {
        position: "absolute",
        bottom: -6,
        left: 0,
        zIndex: -1
    },
    formContainer: {
        padding: 50,
        backgroundColor: colors.light,
        borderRadius: 10,
        maxWidth: 528,
        width: "100%"
    },
    avatar: {
        alignSelf: "center"
    },
    title: {
        marginTop: 23,
        marginBottom: 20,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    desc: {
        marginBottom: 20,
        fontSize: fonts.body1,
        color: colors.primary200,
        textAlign: "center"
    },
    submitButton: {
        marginTop: 0
    }
})