import { StyleSheet, View } from 'react-native'
import React from 'react'
import Text from '../../../core/ui/Text'
import { fonts } from '../../../core/constants/fonts'
import { colors } from '../../../core/constants/colors'

const LeaseInfoCard = ({ icon, title, address, houseInfo, style }) => {
    return (
        <View style={[styles.container, style]}>
            {icon}
            <Text style={styles.title}>
                {title}
            </Text>
            <Text style={styles.address}>
                {address}
            </Text>
            <Text style={styles.houseInfo}>
                {houseInfo}
            </Text>
        </View>
    )
}

export default LeaseInfoCard

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        backgroundColor: colors.light,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginTop: 10,
        borderRadius: 10,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
    },
    title: {
        fontSize: fonts.body2,
        color: colors.primary300,
        textAlign: "center",
        marginTop: 19
    },
    address: {
        fontSize: fonts.body2,
        color: colors.primary300,
        textAlign: "center",
        textTransform: "capitalize"
    },
    houseInfo: {
        fontSize: fonts.subTitle,
        color: colors.primary200,
        marginTop: 19,
        textAlign: "center",
    }
})