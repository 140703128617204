import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet } from 'react-native';
import * as yup from 'yup';
import AvatarIcon from "../../../assets/image/avatar-glass-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import { UserContext } from '../../../core/contexts/userContext';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';
import isValidDate from '../../../core/utils/isValidDate';
import Spinner from '../../../core/ui/animations/Spinner';
import LoadingWhiteIcon from "../../../assets/image/loading-white-icon.svg";
import { customStyles } from '../../../core/constants/customStyles';
import reportError from '../../../core/utils/reportError';

const validationSchema = yup.object().shape({
    birthDate: yup
        .string()
        .required('Votre date de naissance est requise pour continuer')
        .test('isValidDate', 'Le format attendu est jj/mm/aaaa', (value) => {
            // Extract day, month, and year from the value
            const [day, month, year] = value.split('/').map(Number);

            // Validate day, month, and year ranges
            const isDayValid = day >= 1 && day <= 31;
            const isMonthValid = month >= 1 && month <= 12;
            const isYearValid = year >= 1900;

            return isDayValid && isMonthValid && isYearValid && isValidDate(value);
        }),
});

const CheckTenantBirthDate = ({ route, navigation }) => {
    const { data, response } = route?.params || {};
    const prevData = data;

    const { authAxios } = useContext(AxiosContext);
    const { user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (values) => {
        const data = {
            ...prevData,
            birthDate: values.birthDate.split('/').reverse().join('-')
        }

        const challengeName = response.challenge?.includes('lastName') || response.challenge?.includes('firstName')
        if (challengeName) {
            navigation.navigate("CheckTenantName", { data, response })
        } else {
            setIsLoading(true);
            await authAxios.post(`users/${user.id}/persons`, data)
                .then(res => res.data)
                .then(res => {
                    navigation.navigate("ContractFound", { data, response: res })
                })
                .catch(err => Promise.reject(err.response.data))
                .catch(err => {
                    reportError(false, err);
                    navigation.navigate("IdentificationFailed")
                })
                .finally(() => setIsLoading(false))
        }
    }

    const [allowChangeText, setAllowChangeText] = useState(true);

    const onChangeText = (text, setFieldValue) => {
        // Remove non-numeric characters from the input
        const input = text.replace(/[^0-9]/g, '');

        // Insert slashes after jj, mm
        let formattedInput = input;
        if (allowChangeText) {
            if (input.length >= 2) {
                formattedInput = `${input.slice(0, 2)}/${input.slice(2)}`;
            }
            if (input.length >= 4) {
                formattedInput = `${formattedInput.slice(0, 5)}/${formattedInput.slice(5)}`;
            }
            setFieldValue('birthDate', formattedInput);
        }
    };


    const onKeyPress = (nativeEvent, setFieldValue, values) => {
        let input = values.birthDate;

        // remove slashes before mm and aaaa
        if (nativeEvent.key === 'Backspace') {
            if (input.length === 3) {
                setFieldValue('birthDate', input.slice(0, 2));
                setAllowChangeText(false);
            } else if (input.length === 6) {
                setFieldValue('birthDate', `${input.slice(0, 2)}/${input.slice(3, 5)}`);
                setAllowChangeText(false);
            } else {
                setAllowChangeText(true);
            }
        } else {
            setAllowChangeText(true);
        }
    };


    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : null}
            style={[customStyles.keyBoardAvoidingViewContainer, customStyles.reducedPageContainer]}
        >
            <Formik
                initialValues={{ birthDate: '' }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, setFieldValue, values, errors, isValid, dirty }) => (
                    <ScrollView contentContainerStyle={styles.container}>
                        <AvatarIcon />
                        <Text style={styles.title}>Vérifions votre identité</Text>

                        <Text style={styles.label}>Date de naissance</Text>
                        <TextField
                            maxLength={10}
                            onChangeText={(text) => onChangeText(text, setFieldValue)}
                            onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, setFieldValue, values)}
                            value={values.birthDate}
                            placeholder="JJ/MM/AAAA"
                            containerStyle={styles.textField}
                            keyboardType="numeric"
                        />
                        {errors.birthDate && values.birthDate !== "" && <Text style={styles.error}>{errors.birthDate}</Text>}

                        <Button
                            disabled={!isValid || !dirty}
                            onPress={handleSubmit}
                            style={styles.submitButton}
                            endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
                        >
                            Suivant
                        </Button>
                    </ScrollView>
                )}
            </Formik>
        </KeyboardAvoidingView>
    )
}

export default CheckTenantBirthDate

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 30,
        paddingTop: 15,
    },
    title: {
        marginTop: 23,
        marginBottom: 40,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    label: {
        fontSize: fonts.body1,
        color: colors.primary300
    },
    textField: {
        marginVertical: 10
    },
    error: {
        color: colors.error,
        marginBottom: 10,
    },
    submitButton: {
        marginTop: 10,
        width: "100%"
    },
})