import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

const IconButton = ({ icon, onPress, style, accessibilityLabel, accessibilityRole = "button" }) => {
  return (
    <Pressable
      onPress={onPress}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={accessibilityRole}
      style={({ pressed }) => [styles.button, pressed && styles.onPress, style]}
      testID="icon-button"
    >
      {icon && icon}
    </Pressable>
  )
}

export default IconButton

const styles = StyleSheet.create({
  button: {
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    minWidth: 40
  },
  onPress: {
    opacity: .80,
    transform: [{ scale: .97 }] 
  }
})