
const sortFn = (a, b) => {
    // Sort by endDate, with null values first
    if (a.endDate === null && b.endDate !== null) return -1;
    if (a.endDate !== null && b.endDate === null) return 1;
    if (a.endDate !== null && b.endDate !== null) {
        if (a.endDate > b.endDate) return -1;
        if (a.endDate < b.endDate) return 1;
    }

    // If endDate is the same or both are null, sort by isMain in descending order
    return b.isMain - a.isMain;
};

const getMainContractBuilding = (c) => c?.contractBuildingUnits?.sort(sortFn)?.[0];
const getMainContractAgency = (c) => getMainContractBuilding(c)?.buildingUnit?.agency;
const getMainContractAddress = (c) => getMainContractBuilding(c)?.buildingUnit?.building?.address;
const getMyContractPerson = (c, p = []) => c?.contractPersons?.find(i => p?.includes(i.person?.code));

export { getMainContractBuilding, getMainContractAgency, getMainContractAddress, getMyContractPerson };