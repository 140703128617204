import { StyleSheet } from 'react-native';
import { colors } from './colors';
import { fonts } from './fonts';
import { breakpoints } from './breakpoints';

export const customStyles = StyleSheet.create({
    row: {
        flexDirection: "row"
    },
    pageTitle: {
        color: colors.primary300,
        fontSize: fonts.h2,
        marginTop: 30,
        marginBottom: 20
    },
    pageContainer: {
        maxWidth: breakpoints.large,
        margin: "auto",
        width: "100%"
    },
    reducedPageContainer: {
        maxWidth: breakpoints.mobile + 200,
        width: "100%",
        margin: "auto"
    },
    keyBoardAvoidingViewContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center'
    }
});