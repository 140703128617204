import React, { createContext, useContext, useState } from 'react';
import useDataFetch from '../hooks/useDataFetch';
import { UserContext } from './userContext';

export const RequestContext = createContext({
    lastMenuCode: null,
    setLastMenuCode: null,
    breadcrumbs: [],
    setBreadcrumbs: [],
    isRequestLoading: false,
    setIsRequestLoading: (v) => v,
    requestsConfigData: null
});

export const RequestProvider = ({ children }) => {
    const [lastMenuCode, setLastMenuCode] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [isRequestLoading, setIsRequestLoading] = useState(false);

    const { user } = useContext(UserContext);
    const { data: requestsConfigData } = useDataFetch(`ikos/requests/config`, {
        params: {
            menusQuery: `{
                id, code, topics, menus, question, description, shortTitle, pictoUrl, style { code }
            }`,
            topicsQuery: `{
                id, code, title, components, allowComment, connectors, object
            }`
        }
    }, [user], user) 


    return (
        <RequestContext.Provider value={{
            breadcrumbs,
            setBreadcrumbs,
            lastMenuCode,
            setLastMenuCode,
            isRequestLoading,
            setIsRequestLoading,
            requestsConfigData
        }}>
            {children}
        </RequestContext.Provider>
    );
};