import { Alert } from "react-native";

export default async function verifyPermission(permission, requestPermission) {
    if (permission.status === "undetermined" || permission.status === "denied") {
        if (permission.canAskAgain) permission = await requestPermission();
    }

    if (permission.status === "denied") {
        Alert.alert(
            "Permissions insuffisantes",
            "Vous devez accorder les permissions pour l'application via les paramètres de l'appareil pour utiliser cette fonctionnalité"
        );
        return false;
    }
    return true;
}