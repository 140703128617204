import Markdown from '@ronradtke/react-native-markdown-display';
import { default as React } from 'react';
import { Linking, ScrollView, StyleSheet, Text, View } from 'react-native';
import { breakpoints } from '../constants/breakpoints';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';
import Button from './Button';
import Modal from './Modal';

const CommunicationsModal = ({ modalVisible, setModalVisible, title, content, icon }) => {
    const formattedContent = content?.replace(/<u>(.*?)<\/u>/g, '[$1](url)');

    const markdownRules = {
        link: (node, children, parent, styles) => {
            return (
                <Text key={node.key} onPress={() => Linking.openURL(node.children[0].content)}>
                    {children}
                </Text>
            );
        },
    };

    return (
        <Modal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            containerStyle={styles.container}
        >
            <View style={styles.iconContainer}>
                {icon}
            </View>
            <Text style={styles.title}>
                {title}
            </Text>
            <ScrollView
                persistentScrollbar
                showsVerticalScrollIndicator
                style={styles.contentContainer}
            >
                {content && (
                    <Markdown style={styles.markdownStyle} rules={markdownRules}>
                        {formattedContent}
                    </Markdown>
                )}
            </ScrollView>
            <Button
                style={styles.button}
                onPress={() => setModalVisible(false)}
                variant="outlined"
            >
                Fermer
            </Button>
        </Modal >
    )
};

export default CommunicationsModal

const styles = StyleSheet.create({
    container: {
        maxWidth: breakpoints.medium
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center",
        marginTop: 20,
        marginBottom: 7,
        fontWeight: 700
    },
    iconContainer: {
        maxHeight: 100,
        width: "100%",
        alignItems: "center"
    },
    button: {
        alignSelf: "stretch",
        marginVertical: 10,
    },
    contentContainer: {
        alignSelf: "stretch",
    },
    markdownStyle: {
        text: {
            color: colors.primary300,
            marginTop: 20,
        },
        body: {
            marginRight: 6
        },
        image: {
            width: "100%",
            height: 250,
            margin: 20,
            marginLeft: 5,
            marginRight: 2
        },
        ordered_list: {
            color: colors.primary300,
            marginTop: 20,
        },
        heading1: {
            flexDirection: 'row',
            fontSize: 32,
            marginTop: 10,
        },
        heading2: {
            flexDirection: 'row',
            fontSize: 24,
            marginTop: 10,
        },
        heading3: {
            flexDirection: 'row',
            fontSize: 18,
            marginTop: 10,
        },
        heading4: {
            flexDirection: 'row',
            fontSize: 16,
            marginTop: 10,
        },
        heading5: {
            flexDirection: 'row',
            fontSize: 13,
            marginTop: 10,
        },
        heading6: {
            flexDirection: 'row',
            fontSize: 11,
            marginTop: 10,
        },
    },
    icon: {
        width: 62,
        height: 62,
    },
});