import { useNavigation } from '@react-navigation/native'
import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import { RequestContext } from '../../../core/contexts/requestContext'
import Text from '../../../core/ui/Text'
import NewRequestShortcutsCard from './NewRequestShortcutsCard'

const NewRequestShortcutsSection = () => {
    const { requestsConfigData } = useContext(RequestContext);

    const firstMenu = requestsConfigData?.requestMenus?.find(menu => menu.code === "NEW_REQUEST")
    const firstMenuChildsData = requestsConfigData?.requestMenus?.filter(menu => firstMenu?.menus.includes(menu.code))

    const navigation = useNavigation();

    if (!firstMenuChildsData) return null;

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Effectuer une demande</Text>
            <View style={styles.shortcutsCard}>
                {firstMenuChildsData?.map((menu) => (
                    <NewRequestShortcutsCard
                        key={menu.id}
                        title={menu.shortTitle}
                        desc={menu.description}
                        icon={menu.pictoUrl}
                        onPress={() => navigation.navigate(menu.code, { isFromShortcutCard: true })}
                    />
                ))}
            </View>
        </View>
    )
}

export default NewRequestShortcutsSection

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.light,
        paddingVertical: 14,
        paddingHorizontal: 15,
        marginHorizontal: 15,
        marginTop: 5,
        borderRadius: 10,
        alignItems: "center",
        rowGap: 10,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginBottom: 5
    },
    shortcutsCard: {
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        width: "100%",
        rowGap: 10,
    }
})