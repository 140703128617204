import { Alert } from 'react-native';
import { isWeb } from './responsive';

const reportError = (isCritical, error, userMsg = 'La requête vers le serveur a échoué. Veuillez réessayer ultérieurement ou contacter ACM Habitat pour obtenir de l\'aide.') => {
    logError(`critical?: ${isCritical}`, error);

    if (isCritical && is401(error)) return showSessionExpiredAlert();

    if (isCritical) return showCriticalErrorAlert(userMsg);
}

export default reportError;

const logError = (sev, error) => {
    // Perform error logging logic here
    // For example, you can use a logging library or send error data to a server
    console.error(sev, error);
}

const is401 = (error) => {
    return error instanceof Error && error.message === "Request failed with status code 401";
}
const showSessionExpiredAlert = () => {
    if (isWeb) {
        alert("Votre session a expiré. Veuillez rafraichir le navigateur.")
        return;
    }

    Alert.alert("Erreur 401", "Votre session a expiré. Veuillez rafraichir l'application.", [{
        text: 'OK',
        onPress: () => { }
    }]);
}

const showCriticalErrorAlert = (userMsg) => {
    if (isWeb) {
        alert(userMsg)
        return;
    }

    Alert.alert('Erreur', userMsg, [
        {
            text: 'OK',
            onPress: () => { }
        }
    ]);
}