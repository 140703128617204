import { useLinkTo, useNavigation } from '@react-navigation/native';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Pressable, StyleSheet, TextInput, View } from 'react-native';
import CardIcon from "../../../assets/image/card-icon.svg";
import LoadingWhiteIcon from '../../../assets/image/loading-white-icon.svg';
import MoneyTransfertIcon from '../../../assets/image/money-transfert-icon.svg';
import Modal from '../../../core/ui/Modal';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { API_URL, AxiosContext } from '../../../core/contexts/axiosContext';
import { ContractContext } from '../../../core/contexts/contractContext';
import Button from '../../../core/ui/Button';
import RadioButtons from '../../../core/ui/RadioButtons';
import Text from '../../../core/ui/Text';
import Spinner from '../../../core/ui/animations/Spinner';
import { isWeb } from '../../../core/utils/responsive';
import { useMatomo } from 'matomo-tracker-react-native';
import reportError from '../../../core/utils/reportError';
import Constants from "expo-constants";
import * as Linking from "expo-linking";
import AsyncStorage from "@react-native-async-storage/async-storage";

const radioButtonsData = [
    {
        id: 1,
        value: "CB",
        orderDesc: "CB",
        orderInfo2: "MYACMCB",
        web: {
            orderInfo2: "EXTRACB",
        },
        paymentMethods: ["CARDS"],
    },
    // {
    //     id: 2,
    //     value: "Virement",
    //     orderDesc: "VIR",
    //     orderInfo2: "MYACMVIR",
    //     web: {
    //         orderInfo2: "EXTRAVIR",
    //     },
    //     paymentMethods: ["IP_WIRE", "IP_WIRE_INST"],
    // }
]

const PaymentModal = ({ amount, modalVisible, setModalVisible }) => {
    const navigation = useNavigation();
    const linkTo = useLinkTo();

    const [userChoice, setUserChoice] = useState("CB");
    const [amountToPay, setAmountToPay] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isMore = useMemo(() => parseFloat(amountToPay) > parseFloat(amount), [amountToPay, amount]);

    const { contract } = useContext(ContractContext)
    const { authAxios } = useContext(AxiosContext)

    const { trackAction } = useMatomo();

    useEffect(() => {
        setAmountToPay(amount);
    }, [amount]);

    const onRadioButtonChange = (userChoice) => {
        setUserChoice(userChoice);
    }

    const onPayButtonPress = async () => {
        trackAction({ name: 'Payment' })

        setIsLoading(true)
        const paymentMethod = radioButtonsData.find(i => i.value === userChoice) || radioButtonsData[0]
        await authAxios.post('payments/create', {
            // amount: parseFloat(amountToPay).toFixed(2) * 100, // NOK!
            amount: Math.round(parseFloat(amountToPay).toFixed(2) * 100),
            contract: contract.code,
            metadata: {
                orderInfo2: isWeb ? paymentMethod.web.orderInfo2 : paymentMethod.orderInfo2,
                description: `${paymentMethod.orderDesc} ${contract.code} ${moment().format('MM/YY')}`
            },
            paymentMethods: paymentMethod.paymentMethods,
        })
            .then(res => res.data)
            .then(res => {
                const formToken = res.answer.formToken

                if (isWeb) {
                    linkTo("/paiements/payer?formToken=" + formToken)
                } else {
                    const paymentURL = `${API_URL}/payments/paymentForm?formToken=${formToken}`

                    if (userChoice === 'Virement') {
                        const paymentURLwCallback = paymentURL + `&successUrl=${Constants.expoConfig.extra.APP_WEB_URL}/p/paid`

                        Linking.openURL(paymentURLwCallback).then(async () => {
                            // this forces the data to reload when the app comes back to the foreground
                            const oneHourAgo = new Date().setHours(new Date().getHours() - 1)
                            await AsyncStorage.setItem('lastActive', oneHourAgo.toString())
                        })
                    } else {
                        navigation.navigate("PaymentMake", { url: paymentURL });
                    }
                }

                setModalVisible(false);
            })
            .catch(err => {
                reportError(true, err)
            })
            .finally(() => setIsLoading(false))
    }

    const onCancelButtonPress = () => {
        setModalVisible(false);
        setAmountToPay(amount);
    }

    const onAmountChange = (val) => {
        const hasTwoDecimal = /^\d*(\.\d{0,2})?$/

        if (hasTwoDecimal.test(val)) {
            setAmountToPay(val);
        }
    }

    return (
        <Modal modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <Text style={styles.title}>
                Montant à régler
            </Text>

            <View style={styles.priceContainer}>
                <TextInput
                    style={[styles.price, isWeb && { width: 75 }]}
                    value={amountToPay}
                    onChangeText={(val) => onAmountChange(val)}
                    keyboardType={"numeric"}
                />
                <Text style={styles.price}>
                    €
                </Text>
            </View>
            {isMore && <Text style={styles.error}>Votre montant est supérieur à ce que vous devez</Text>}

            <Text style={styles.subTitle}>Comment souhaitez-vous régler?</Text>
            <View style={styles.paymentOptionContainer}>
                <RadioButtons
                    radioButtonsStyle={{ marginBottom: 25 }}
                    value={userChoice}
                    onValueChange={onRadioButtonChange}
                    data={radioButtonsData}
                />
                <View style={{ marginLeft: 20 }}>
                    <Pressable onPress={() => onRadioButtonChange('CB')}>
                        <View style={styles.optionRow}>
                            <CardIcon style={styles.icon} />
                            <Text style={styles.optionTitle}>Paiement par CB</Text>
                        </View>
                    </Pressable>
                    {/* <Pressable onPress={() => onRadioButtonChange('Virement')}>
                        <View style={[styles.optionRow, { marginTop: 20 }]}>
                            <MoneyTransfertIcon width={40} height={30} />
                            <Text style={styles.optionTitle}>Paiement par Virement</Text>
                        </View>
                    </Pressable> */}
                </View>
            </View>

            <Button
                disabled={!contract || isLoading || isMore}
                style={[styles.button, { marginTop: 20 }]}
                onPress={onPayButtonPress}
                endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
            >
                Payer
            </Button>
            <Button
                variant="outlined"
                style={styles.button}
                onPress={onCancelButtonPress}
            >
                Annuler
            </Button>
        </Modal >
    )
}

const styles = StyleSheet.create({
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center",
        marginTop: 20,
    },
    subTitle: {
        color: colors.primary300,
        fontSize: fonts.body1,
        alignSelf: "flex-start",
        marginTop: 30,
    },
    priceContainer: {
        borderBottomWidth: 1,
        borderBottomColor: colors.primary300,
        paddingBottom: 5,
        flexDirection: "row",
        marginTop: 30,
        width: isWeb ? 77 : null
    },
    price: {
        color: colors.primary300,
        fontSize: 22,
        marginRight: 5,
        outlineWidth: 0
    },
    error: {
        color: colors.error,
        marginTop: 10,
    },
    paymentOptionContainer: {
        flexDirection: "row",
        alignItems: "flex-start",
        alignSelf: "flex-start",
        marginTop: 20,
    },
    optionRow: {
        flexDirection: "row",
        gap: 10,
    },
    optionTitle: {
        color: colors.primary300,
        fontSize: fonts.body1,
    },
    button: {
        alignSelf: "stretch",
        marginTop: 10,
    },
    icon: {
        marginTop: -5,
        width: 40,
        height: 30
    }
});

export default PaymentModal;
