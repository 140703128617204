export const colors = {
    primary100: "rgba(30, 60, 160, 0.2)",
    primary200: "rgba(30, 60, 160, 0.5)",
    primary300: "#1E3CA0",
    primary400: "#162B70",
    secondary100: "rgba(30, 190, 80, .2)",
    secondary200: "rgba(30, 190, 80, 0.5)",
    secondary300: "#1EBE50",
    secondary400: "#2C5353",
    light: "#FFFFFF",
    lightGrey: "#F5F5F5",
    grey: "#BBBBBB",
    warning: "#FFB902",
    error: "#E02828",
};