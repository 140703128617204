import { useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import CircleArrowIcon from "../../../assets/image/circle-arrow-icon.svg";
import HappyStickMan from "../../../assets/image/happy-stick-man.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { ContractContext } from '../../../core/contexts/contractContext';
import { UserContext } from '../../../core/contexts/userContext';
import IconButton from '../../../core/ui/IconButton';
import Text from '../../../core/ui/Text';
import { getMainContractAddress, getMainContractBuilding, getMyContractPerson } from '../../../core/utils/parseContract';

const Header = () => {
    const navigation = useNavigation();

    const { contracts, contract } = useContext(ContractContext);
    const { claims } = useContext(UserContext);

    const isMultiContracts = contracts?.length > 1;
    const person = getMyContractPerson(contract, claims?.persons)?.person;
    const address = getMainContractAddress(contract);
    const building = getMainContractBuilding(contract);

    return (
        <View style={styles.headerContainer}>
            <HappyStickMan style={styles.headerImage} />
            {isMultiContracts && <IconButton
                icon={<CircleArrowIcon style={styles.leftArrowIcon} />}
                onPress={() => navigation.goBack()}
                accessibilityLabel="Retour à la liste des contrats"
            />}
            <View style={styles.infosContainer}>
                <Text style={styles.greetingText}>
                    Bonjour {"\n"}
                    {person?.title.trim()} {person?.name.trim()} {person?.lastName.trim()}
                </Text>
                <Text style={styles.address} numberOfLines={1}>
                    {[address?.streetName.trim(), address?.streetName2?.trim(), building?.buildingUnit?.door?.trim()].filter(i => i).join(' ')} {address?.postCode.trim()} {address?.city.trim()}
                </Text>
            </View>
        </View>
    )
}

export default Header

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: colors.primary400,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        paddingHorizontal: 20,
        position: "relative",
        height: 189,
    },
    headerImage: {
        position: "absolute",
        zIndex: -1,
        right: 40,
        bottom: 30
    },
    infosContainer: {
        flex: 1,
        justifyContent: "center",
    },
    greetingText: {
        fontSize: fonts.body1,
        color: colors.light,
    },
    address: {
        fontSize: fonts.subTitle,
        color: colors.light,
        marginTop: 4,
        textTransform: "uppercase"
    },
    leftArrowIcon: {
        justifyContent: "center",
        paddingRight: 15,
        color: colors.warning
    }
})

export const defaultHeaderStyles = StyleSheet.create({
    headerStyle: {
        backgroundColor: colors.primary400,
    },
    headerTitleStyle: {
        marginTop: Platform.OS === 'android' ? 8 : 0,
        transform: [{ translateY: -8 }]
    },
    leftArrowIcon: {
        marginTop: Platform.OS === 'android' ? 8 : 0,
        marginBottom: 15,
        marginLeft: 15,
        transform: [{ scale: .9 }]
    },
    settingsIcon: {
        marginTop: Platform.OS === 'android' ? 8 : 0,
        marginBottom: 15,
        marginRight: 15,
    },
})