import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import LoadingWhiteIcon from "../../../assets/image/loading-white-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import { ContractContext } from '../../../core/contexts/contractContext';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';
import Spinner from '../../../core/ui/animations/Spinner';
import reportError from '../../../core/utils/reportError';
import Modal from '../../../core/ui/Modal';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .matches(/^[^@]+@[^@]+\.[^@]{2,}$/, "Merci de saisir une adresse mail valide.")
        .required('Une adresse email est requise'),
    mobilePhone: yup
        .string()
        .matches(/^(06|07)[-\.\s]?(\d{2}[-\.\s]?){4}$/, "Merci de saisir un n° de portable valide.")
        .required('Un numéro de téléphone est requis'),
    homePhone: yup
        .string()
        .when('mobilePhone', {
            is: (v) => !v,
            then: () => yup.string()
                .matches(/^(?!06|07)[-\.\s]?(\d{2}[-\.\s]?){5}$/, "Merci de saisir un n° de domicile valide.")
                .required('Un numéro de téléphone est requis'),
            otherwise: () => yup.string()
                .matches(/^(?!06|07)[-\.\s]?(\d{2}[-\.\s]?){5}$/, "Merci de saisir un n° de domicile valide.")
                .notRequired()
        }),
})

const UpdateContractorInfoModal = ({ modalVisible, setModalVisible, inputData }) => {
    const [isLoading, setModalIsLoading] = useState(false)

    const { person } = inputData || {};

    const { authAxios } = useContext(AxiosContext);
    const { loadContract, contract } = useContext(ContractContext);

    const onSubmit = async (values) => {
        if (!person?.id) return;

        setModalIsLoading(true)
        await authAxios.patch(`ikos/persons/${person.id}`, {
            mail: values.email?.toLowerCase(),
            requestedMail: values.email?.toLowerCase(),
            mobilePhone: values.mobilePhone,
            requestedMobilePhone: values.mobilePhone,
            homePhone: values.homePhone,
            requestedHomePhone: values.homePhone,
        })
            .catch(err => {
                reportError(true, err.message);
            })
            .finally(async () =>
                await loadContract(contract.id)
                    .finally(() => {
                        setModalIsLoading(false);
                        setModalVisible(false);
                    })
            )
    }

    return (
        <Modal modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <Formik
                initialValues={{ email: person?.mail, mobilePhone: person?.mobilePhone, homePhone: person?.homePhone }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleChange, handleSubmit, values, errors, isValid, dirty }) => (
                    <>
                        <Text style={styles.title}>{person?.title.trim()} {person?.name.trim()} {person?.lastName.trim()}</Text>

                        <Text style={styles.label}>E-Mail</Text>
                        <TextField
                            placeholder="Email"
                            onChangeText={handleChange('email')}
                            value={values.email}
                            keyboardType="email-address"
                            containerStyle={styles.textField}
                        />
                        {errors.email && <Text style={styles.error}>{errors.email}</Text>}

                        <Text style={styles.label}>N° portable</Text>
                        <TextField
                            placeholder="N° portable"
                            onChangeText={handleChange('mobilePhone')}
                            value={values.mobilePhone}
                            keyboardType="numeric"
                            containerStyle={styles.textField}
                        />
                        {errors.mobilePhone && <Text style={styles.error}>{errors.mobilePhone}</Text>}

                        <Text style={styles.label}>N° domicile</Text>
                        <TextField
                            placeholder="N° Domicile"
                            onChangeText={handleChange('homePhone')}
                            value={values.homePhone}
                            keyboardType="numeric"
                            containerStyle={styles.textField}
                        />
                        {errors.homePhone && <Text style={styles.error}>{errors.homePhone}</Text>}

                        <Button
                            disabled={!isValid || !dirty}
                            style={[styles.button, { marginTop: 20 }]}
                            onPress={handleSubmit}
                            endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
                            accessibilityLabel="confirmer la modification"
                        >
                            Modifier
                        </Button>
                        <Button
                            style={styles.button}
                            onPress={() => setModalVisible(false)}
                            variant="outlined"
                        >
                            Annuler
                        </Button>
                    </>
                )}
            </Formik>
        </Modal >
    )
}

const styles = StyleSheet.create({
    textField: {
        marginVertical: 10,
        width: "100%"
    },
    error: {
        color: colors.error,
        alignSelf: "flex-start",
        marginBottom: 10,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center",
        marginTop: 20,
        marginBottom: 20,
    },
    label: {
        color: colors.primary300,
        alignSelf: "flex-start",
    },
    button: {
        alignSelf: "stretch",
        marginTop: 10,
    },
});

export default UpdateContractorInfoModal;
