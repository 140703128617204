import { StyleSheet, View } from 'react-native'
import React from 'react'
import Text from '../../../core/ui/Text'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import { isWeb } from '../../../core/utils/responsive';

const PaymentMonitoringCard = ({ title, price, date, isPending }) => {
  const priceToString = price.toFixed(2).toString();
  const formattedPrice = price < 0 ? priceToString.substr(1) : priceToString;
  const sign = price > 0 ? "-" : "+";
  const renderedPrice = `${sign} ${formattedPrice}`;

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.date}>{date && new Date(date).toLocaleDateString('fr')}</Text>
        <Text style={styles.title}>{title}</Text>
        {isPending && (<Text style={[styles.date, styles.italic]}>Écriture provisoire - En attente de validation Banque</Text>)}
      </View>
      <View>
        <Text style={[styles.price, parseInt(price) > 0 && { color: colors.primary300 }, isPending && styles.italic]}>
          {renderedPrice} €
        </Text>
      </View>
    </View>
  )
}

export default PaymentMonitoringCard

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.light,
    paddingVertical: 15,
    paddingHorizontal: 15,
    marginBottom: 1,
  },
  textContainer: {
    flex: 1,
    flexDirection: "column",
    gap: 5,
  },
  date: {
    color: colors.primary200,
    fontSize: fonts.subTitle,
  },
  title: {
    color: colors.primary300,
    fontSize: fonts.body2
  },
  price: {
    color: colors.secondary300,
    fontSize: fonts.body1
  },
  italic: isWeb ? {
    fontStyle: 'italic',
  } : {
    fontFamily: 'Helvetica-Oblique',
  },
})