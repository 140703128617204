import { getStateFromPath, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import MatomoTracker, { MatomoProvider } from 'matomo-tracker-react-native';
import React from 'react';
import { View } from 'react-native';
import * as Sentry from 'sentry-expo';
import AppMatomo from './core/components/AppMatomo';
import CoreAuthProvider from "./core/components/CoreAuthProvider";
import CoreContractProvider from './core/components/CoreContractProvider';
import Header from './core/components/Header.web';
import { colors } from './core/constants/colors';
import { FirebaseContext } from './core/contexts/firebaseContext';
import { UserContext } from './core/contexts/userContext';
import Authentification from './features/Authentification';
import ContractAdding from './features/ContractAdding';
import GeneralConditions from './features/GeneralConditions/GeneralConditions';
import NavigationTabs from './features/NavigationTabs';
import PaymentComplete from "./features/Payment/screens/PaymentComplete";

const Stack = createStackNavigator();

/**
 * Authentification
 * @type {{resetPassword: string, signIn: string}}
 */
const AUTH_ACTIONS = {
    signIn: 'signup',
    resetPassword: 'reset',
};

/**
 * ContractAdding
 * @type {{parse: {data: (function(*): string), response: (function(*): string)}, stringify: {data: (function(*): string), response: (function(*): string)}}}
 */
const PARAM_SERIALIZATION = {
    parse: {
        data: (v) => JSON.parse(atob(v)),
        response: (v) => JSON.parse(atob(v)),
    },
    stringify: {
        data: (v) => btoa(JSON.stringify(v)),
        response: (v) => btoa(JSON.stringify(v)),
    },
}

/** Linking */
const linking = {
    config: {
        screens: {
            Authentification: {
                initialRouteName: 'ConnectionChoice',
                path: 'auth',
                screens: {
                    ConnectionChoice: '',
                    EmailConfirmation: 'confirmation/:action',
                    EmailConnection: 'email',
                    KnownUserConnection: 'login',
                    ForgotPassword: 'reset',
                    NewUserConnection: 'action/:action',
                }
            },
            GeneralConditions: {
                path: 'conditions-generales',
            },
            ContractAdding: {
                initialRouteName: 'TenantReference',
                path: 'rattacher-contrat',
                screens: {
                    TenantReference: 'reference',
                    CheckTenantBirthDate: {
                        path: 'date-naissance',
                        ...PARAM_SERIALIZATION,
                    },
                    CheckTenantName: {
                        path: 'nom',
                        ...PARAM_SERIALIZATION,
                    },
                    ContractFound: {
                        path: 'contrat-trouve',
                        ...PARAM_SERIALIZATION,
                    },
                    IdentificationFailed: 'identification-echoue',
                    ContactNumberChoice: {
                        path: 'contact-numero',
                        ...PARAM_SERIALIZATION,
                    },
                    ContactEmailChoice: {
                        path: 'contact-email',
                        ...PARAM_SERIALIZATION,
                    },
                    InscriptionComplete: 'inscription-complete',
                }
            },
            NavigationTabs: {
                initialRouteName: 'Home',
                path: '',
                screens: {
                    Home: {
                        path: 'accueil',
                        screens: {
                            UserHome: '',
                        }
                    },
                    Contract: {
                        path: 'contrat',
                        screens: {
                            MyContract: '',
                        }
                    },
                    Request: {
                        path: 'demandes',
                        screens: {
                            RequestsList: '',
                            NEW_REQUEST: 'nouvelle-requete',
                            RequestForm: 'requete-formulaire',
                            RequestSend: "requete-envoyee",
                        }
                    },
                    Payment: {
                        path: 'paiements',
                        screens: {
                            PaymentsList: '',
                            PaymentMake: 'payer',
                        }
                    },
                    More: {
                        path: 'plus',
                        screens: {
                            MoreOptions: '',
                        }
                    }
                }
            },
            PaymentComplete: {
                path: 'p/paid',
            }
        },
    },
    getStateFromPath: (path, options) => {
        const params = new URLSearchParams(path)
        const newPath = path.startsWith('/auth/action?')
            ? path.replace('/auth/action?', `/auth/action/${AUTH_ACTIONS[params.get('mode')]}?`)
            : path;
        return getStateFromPath(newPath, options);
    },
};

/** App */
export default function App() {
    return <MatomoContainer />
}

/** App container */
function AppContainer() {
    return (
        <>
            <CoreAuthProvider>
                <CoreContractProvider>
                    <FirebaseContext.Consumer>
                        {({ accessToken }) =>
                            <UserContext.Consumer>
                                {({ user }) =>
                                    <NavigationContainer
                                        linking={linking}
                                    >
                                        {/* App tracking */}
                                        <AppMatomo />

                                        <View style={{ flexGrow: 1, flexDirection: 'column' }}>
                                            <Header />
                                            <Stack.Navigator
                                                initialRouteName={user ? "NavigationTabs" : "Authentification"}
                                                screenOptions={{ headerShown: false, }}
                                            >
                                                {(user && !user.termsApprovalDate) && (
                                                    <Stack.Group>
                                                        <Stack.Screen
                                                            name="GeneralConditions"
                                                            component={GeneralConditions}
                                                            options={{ title: 'Conditions générales', animationEnabled: false }} />
                                                    </Stack.Group>
                                                )}
                                                {(user || accessToken) && (
                                                    <Stack.Group>
                                                        <Stack.Screen
                                                            name="NavigationTabs"
                                                            component={NavigationTabs}
                                                            options={{ cardStyle: { backgroundColor: colors.primary400 } }}
                                                        />
                                                        <Stack.Screen name="ContractAdding" component={ContractAdding} />
                                                    </Stack.Group>
                                                )}

                                                {/* Auth screens */}
                                                {!user && (
                                                    <Stack.Group>
                                                        <Stack.Screen name="Authentification" component={Authentification} />
                                                    </Stack.Group>
                                                )}

                                                {/* Other screens */}
                                                <Stack.Group>
                                                    <Stack.Screen
                                                      name="PaymentComplete"
                                                      component={PaymentComplete}
                                                      options={{ cardStyle: { backgroundColor: colors.light }, title: 'Payé avec succès' }}
                                                    />
                                                </Stack.Group>
                                            </Stack.Navigator>
                                        </View>
                                    </NavigationContainer>
                                }
                            </UserContext.Consumer>
                        }
                    </FirebaseContext.Consumer>
                </CoreContractProvider>
            </CoreAuthProvider>
        </>
    )
}

/** Matomo container */
function MatomoContainer() {
    const instance = new MatomoTracker({
        urlBase: 'https://yzydigital.matomo.cloud', // required
        siteId: 2, // required, number matching your Matomo project
        // disabled: process.env.APP_ENV === 'development', // optional, default value: false. Disables all tracking operations if set to true.
        // log: true  // optional, default value: false. Enables some logs if set to true.
    });

    return (
        <MatomoProvider instance={instance}>
            <AppContainer />
        </MatomoProvider>
    );
}

/** Sentry */
Sentry.init({
    // debug: true,
    enabled: process.env.APP_ENV !== 'development',
    environment: process.env.APP_ENV,
    // enableInExpoDevelopment: true,
    dsn: 'https://3e088ad44c11faa6a2c534fa9d70be77@o1258815.ingest.sentry.io/4505623935582208',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 0.2,
});