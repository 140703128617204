import React from 'react';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { breakpoints } from '../../../core/constants/breakpoints';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import getThumbnail from '../../../core/utils/getThumbnail';
import './CarouselZone.css';
import AnnouncesZoneCard from './AnnouncesZoneCard';

const CarouselZone = ({ data }) => {
    const { width } = useWindowDimensions();
    const styles = makeStyles(width);

    const sortedData = data?.sort((a, b) => {
        return a.attributes.priority === 'importante' ? -1 : b.attributes.priority === 'importante' ? 1 : 0;
    });

    if (!sortedData || sortedData.length === 0) return null;

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Mes actualités</Text>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,

                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="CarouselZone"
            >
                {sortedData.map(item => (
                    <SwiperSlide key={item.id}>
                        <AnnouncesZoneCard
                            title={item.attributes.title}
                            content={item.attributes.content}
                            icon={getThumbnail(item.attributes.thumbnail.data)}
                            pdfUrl={item.attributes.document.data?.attributes?.url}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </View>
    )
}

export default CarouselZone;

const makeStyles = (width) => StyleSheet.create({
    container: {
        width: "100%",
        marginTop: 30,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginBottom: 15,
        paddingHorizontal: width >= breakpoints.medium ? 0 : 15,
        fontWeight: "bold"
    }
});
