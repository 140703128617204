import React, { useContext } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { CommunicationsContext } from '../../../core/contexts/communicationsContext';
import { isWeb } from '../../../core/utils/responsive';
import OptionCard from './OptionCard';
import AccountOptionsSection from './AccountOptionsSection';

const groupCommunicationsByZone = (communications) => {
    return communications.reduce((groups, item) => {
        const zoneCode = item?.attributes?.zone?.data?.attributes?.code;

        if (zoneCode) {
            if (!groups[zoneCode]) {
                groups[zoneCode] = [];
            }

            groups[zoneCode].push(item);
        }

        return groups;
    }, {});
};

const renderSection = ([zoneCode, items]) => {
    return (
        <View key={zoneCode}>
            <Text style={styles.title}>{items[0]?.attributes?.zone?.data?.attributes?.title}</Text>
            <FlatList
                ListHeaderComponent={<Text style={styles.title}>{items[0]?.attributes?.zone?.data?.attributes?.title}</Text>}
                data={items}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) =>
                    <OptionCard
                        title={item.attributes.title}
                        content={item.attributes.content}
                        summary={item.attributes.summary}
                        thumbnail={item.attributes.thumbnail?.data}
                        pdfUrl={item.attributes.document?.data?.attributes?.url}
                    />}
                scrollEnabled={false}
            />
        </View>
    );
};

const OptionsSection = () => {
    const { communications } = useContext(CommunicationsContext);

    const filteredCommunicationsByMenu = communications?.data?.filter(
        (item) => item?.attributes?.zone?.data?.attributes?.menu
    ) || [];

    const groupedCommunications = groupCommunicationsByZone(filteredCommunicationsByMenu)

    return (
        <View>
            {groupedCommunications && Object.entries(groupedCommunications)?.map(renderSection)}
            <AccountOptionsSection />
        </View>
    )
};

export default OptionsSection;

const styles = StyleSheet.create({
    flatListContainer: {
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        marginBottom: isWeb ? 20 : 0
    },
    title: {
        fontSize: fonts.body1,
        color: colors.primary300,
        paddingHorizontal: isWeb ? 0 : 15,
        paddingVertical: 15,
    },
});
