import React from 'react';
import { Image, StyleSheet } from 'react-native';
import FindTenantReferencePicture from "../../../assets/image/find-tenant-reference.png";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Modal from '../../../core/ui/Modal';
import Text from '../../../core/ui/Text';

const FindTenantReferenceModal = ({ modalVisible, setModalVisible }) => {
    return (
        <Modal setModalVisible={setModalVisible} modalVisible={modalVisible}>
            <Text style={styles.text}>Votre référence locataire est sur votre avis d’échéance</Text>
            <Image
                source={FindTenantReferencePicture}
                style={styles.image} />
            <Button
                onPress={() => setModalVisible(!modalVisible)}
                style={styles.buttonClose}
            >
                Ok
            </Button>
        </Modal>
    )
}

export default FindTenantReferenceModal

const styles = StyleSheet.create({
    text: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center",
        paddingHorizontal: 30,
        marginBottom: 15,
    },
    image: {
        borderWidth: 1,
        borderColor: colors.lightGrey,
        marginBottom: 15,
        width: '100%',
        height: undefined,
        aspectRatio: 1,
    },
    buttonClose: {
        alignSelf: "stretch",
    },
});
