import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useContext } from 'react';
import { RequestContext } from '../contexts/requestContext';

// The web version uses an array of objects for breadcrumbs, each with a title and code(for redirection purpose). The mobile version uses just an array of title.
const useBreadcrumbs = ({ index, shortTitle, isFromShortcutCard, code }) => {
    const { setBreadcrumbs, breadcrumbs } = useContext(RequestContext);

    useFocusEffect(
        useCallback(() => {
            if (index === 1 || isFromShortcutCard) {
                setBreadcrumbs([{ title: "Dans " + shortTitle, code }]);
            } else {
                setBreadcrumbs(prev => [...prev, { title: shortTitle, code }]);
            }
            setBreadcrumbs(prev => prev.slice(0, prev.findIndex(breadcrumb => breadcrumb.code === code) + 1));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [code, index, isFromShortcutCard, setBreadcrumbs, shortTitle])
    );

    return breadcrumbs;
};

export default useBreadcrumbs;