import React from 'react';
import { StyleSheet } from 'react-native';
import Modal from '../../../core/ui/Modal';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';

const ResetPasswordOkModal = ({ modalVisible, setModalVisible }) => {
    return (
        <Modal modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <Text style={styles.text}>Le mot de passe a bien été modifié</Text>
            <Text style={styles.desc}>Vous pouvez maintenant vous connecter avec votre nouveau mot de passe</Text>

            <Button
                onPress={() => setModalVisible(!modalVisible)}
                style={styles.buttonClose}
            >
                Ok
            </Button>
        </Modal >
    )
}

export default ResetPasswordOkModal

const styles = StyleSheet.create({
    title: {
        fontSize: fonts.h2,
        color: colors.primary300,
        marginTop: 20,
        textAlign: "center"
    },
    desc: {
        color: colors.primary200,
        marginTop: 20,
        marginBottom: 20,
        textAlign: "center"
    },
    buttonClose: {
        alignSelf: "stretch",
    },
});
