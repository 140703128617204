import React, { useEffect, useRef } from 'react';
import { ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';
import PeopleEnjoyingIcon from "../../../assets/image/people-enjoying-icon2.svg";
import { breakpoints } from '../../../core/constants/breakpoints';
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import { useContractInfo } from '../../../core/hooks/useContractInfo';
import useTrackScreenView from '../../../core/hooks/useTrackScreenView';
import Loader from '../../../core/ui/Loader';
import Text from '../../../core/ui/Text';
import ContractorCard from '../components/ContractorCard';
import LeaseInfoCard from '../components/LeaseInfoCard';
import OccupantCard from '../components/OccupantCard';

const MyContract = ({ route }) => {
    const { leasing, contractors, occupants, isLoading } = useContractInfo();

    const viewRef = useRef();

    const { width } = useWindowDimensions();
    const styles = makeStyles(width)

    useEffect(() => {
        if (!isLoading && route.params?.id === "occupants") {
            setTimeout(function () {
                viewRef.current?.scrollIntoView();
            }, 50);
        }
    }, [isLoading, route?.params])

    useTrackScreenView("Contract");

    if (isLoading) {
        return <Loader />
    }

    return (
        <View style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={[styles.container, customStyles.pageContainer]}>
                <Text style={customStyles.pageTitle}>Mon contrat</Text>
                <Text style={styles.subTitle}>Mes locations</Text>
                <View style={styles.row}>
                    {leasing.length === 0 ? (
                        <Text style={styles.fallbackText}>Aucune location.</Text>
                    ) : (
                        leasing.map((item, k) =>
                            <LeaseInfoCard
                                key={k}
                                icon={item.icon}
                                title={item.title}
                                address={item.address}
                                houseInfo={item.info}
                                style={[styles.childContainer, leasing.length === 1 && { width: "100%" }]}
                            />
                        )
                    )}
                </View>
                <View ref={viewRef}>
                    <Text style={styles.subTitle}>Locataires</Text>
                    <View style={styles.row}>
                        {contractors.length === 0 ? (
                            <Text style={styles.fallbackText}>Aucun contractant.</Text>
                        ) : (
                            contractors.map((contractor, k) =>
                                <ContractorCard
                                    key={k}
                                    contractPerson={contractor}
                                    style={[styles.childContainer, contractors.length === 1 && { width: "100%" }]}
                                />
                            )
                        )}
                        {occupants.map((occupant, k) =>
                            <OccupantCard
                                key={k}
                                contractPerson={occupant}
                                style={[styles.childContainer, occupants.length === 1 && { width: "100%" }]}
                            />
                        )}
                    </View>
                </View>
            </ScrollView>
            <PeopleEnjoyingIcon style={styles.peopleIcon} />
        </View >
    )
}

export default MyContract

const makeStyles = (width) => StyleSheet.create({
    container: {
        position: "relative",
        flexGrow: 1,
        paddingHorizontal: 15,
        paddingBottom: 30,
    },
    row: {
        flexDirection: width <= (breakpoints.medium + 100) ? "column" : "row",
        alignItems: width <= (breakpoints.medium + 100) ? null : "flex-start",
        flexWrap: width <= (breakpoints.medium + 100) ? null : "wrap",
        columnGap: 20,
        marginBottom: 20
    },
    childContainer: {
        width: width <= (breakpoints.medium + 100) ? null : "49%",
    },
    peopleIcon: {
        position: "absolute",
        zIndex: -1,
        bottom: 0,
        right: 0
    },
    subTitle: {
        color: colors.primary300,
        fontSize: fonts.body1,
    },
    fallbackText: {
        color: colors.primary200,
        fontSize: 13,
        textAlign: "left",
        marginTop: 5,
        marginBottom: 15,
    }
})