import React, { useContext, useEffect, useRef, useState } from 'react'
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import DropDownPicker from 'react-native-dropdown-picker'
import ACMLogo from "../../assets/image/ACM-web-logo.svg"
import ArrowDownIcon from "../../assets/image/arrow-down-web.svg"
import ArrowUpIcon from "../../assets/image/arrow-up-web.svg"
import LeftArrow from "../../assets/image/left-arrow-icon.svg"
import LogoutIcon from "../../assets/image/logout-icon.svg"
import { breakpoints } from '../constants/breakpoints'
import { colors } from '../constants/colors'
import { fonts } from '../constants/fonts'
import { ContractContext } from '../contexts/contractContext'
import { FirebaseContext } from "../contexts/firebaseContext"
import { UserContext } from '../contexts/userContext'
import Button from '../ui/Button'
import { getMainContractAddress, getMyContractPerson } from '../utils/parseContract'
import { CommunicationsContext } from '../contexts/communicationsContext'

const Header = () => {
    const { width } = useWindowDimensions();
    const { contracts, contract, loadContract } = useContext(ContractContext);
    const { loadCommunications } = useContext(CommunicationsContext);
    const { accessToken, signOut } = useContext(FirebaseContext);
    const { user, claims } = useContext(UserContext);

    const styles = makeStyles(width);

    const person = getMyContractPerson(contract, claims?.persons)?.person;

    const lastStoredContractId = localStorage.getItem("lastStoredContractId")

    const [dropDownValue, setDropDownValue] = useState(lastStoredContractId);
    const [openDropdown, setOpenDropDown] = useState(false);

    const dropdownRef = useRef();

    const dropDownItems = contracts.map(item => ({
        label: item.code + " " + item.label.trim() + " " + getMainContractAddress(item).streetName.trim(),
        value: item.id
    }));

    useEffect(() => {
        if (contracts && contracts.length > 0) {
            const findLastStoredContract = contracts.find(contract => contract.id === Number(lastStoredContractId));
            if (findLastStoredContract) {
                loadContract(findLastStoredContract.id)
                setDropDownValue(findLastStoredContract.id);
                loadCommunications(findLastStoredContract.code)
            } else {
                loadContract(contracts[0].id);
                setDropDownValue(contracts[0].id);
                loadCommunications(contracts[0].code)
            }
        }
    }, [contracts, lastStoredContractId])

    const handleDropdownValueChange = (value) => {
        if (!value || !contract) return;

        if (value !== contract.id) {
            loadContract(value);
            localStorage.setItem("lastStoredContractId", value);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                openDropdown
            ) {
                setOpenDropDown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDropdown]);

    const handleGoBackToACM = () => {
        window.location.href = "https://www.acmhabitat.fr";
    }

    const handleGoHome = () => {
        window.location.href = window.origin;
    }

    const handleLogoutButton = () => {
        signOut();
    }

    return (
        <View style={styles.container}>
            <View style={[styles.innerContainer, { flexDirection: width < 300 ? "column" : "row" }]}>
                <View style={styles.row}>
                    {(!user || (width >= breakpoints.mobile)) && (
                        <>
                            <Button
                                style={styles.goBackButton}
                                onPress={handleGoBackToACM}
                                startIcon={<LeftArrow width={17} />}
                                variant='text'
                                textStyle={styles.buttonText}
                            >
                                {width >= breakpoints.small && (
                                    "Retour\nsur le site ACM"
                                )}
                            </Button>
                            <Button
                                style={styles.goBackButton}
                                onPress={handleGoHome}
                                endIcon={<ACMLogo width={65} height={66} />}
                                variant='text'
                                textStyle={styles.buttonText}
                            >
                            </Button>
                        </>
                    )}
                    {(person && (width >= breakpoints.small)) && (
                        <Text style={[styles.text,
                        width <= 1090 && { maxWidth: 200 }
                        ]}>
                            Bonjour {"\n"}
                            {person?.title.trim()} {person?.name.trim()} {person?.lastName.trim()}
                        </Text>
                    )}
                </View>
                <View style={styles.row2}>
                    {user && (
                        <>
                            {width >= breakpoints.medium && (
                                <Text style={styles.text}>Votre contrat</Text>
                            )}
                            <View ref={dropdownRef}>
                                <DropDownPicker
                                    items={dropDownItems?.length > 0 ? dropDownItems : [{ label: "Aucun contrat disponible", value: "not-found" }]}
                                    listMode="SCROLLVIEW"
                                    open={openDropdown}
                                    value={dropDownValue}
                                    setOpen={setOpenDropDown}
                                    setValue={setDropDownValue}
                                    onChangeValue={handleDropdownValueChange}
                                    style={styles.dropdown}
                                    textStyle={styles.dropdownText}
                                    listItemContainerStyle={styles.listItemContainer}
                                    ArrowDownIconComponent={() => <ArrowDownIcon />}
                                    ArrowUpIconComponent={() => <ArrowUpIcon />}
                                    dropDownContainerStyle={{
                                        borderColor: colors.primary100,
                                    }}
                                    containerStyle={styles.dropdownContainer}
                                    labelProps={{
                                        numberOfLines: 1
                                    }}
                                    placeholder="Sélectionner un contrat"
                                />
                            </View>
                        </>
                    )}
                    {accessToken && (
                        <Button
                            style={styles.logoutButton}
                            endIcon={<LogoutIcon style={{ marginLeft: width >= breakpoints.medium ? 10 : 0 }} />}
                            onPress={handleLogoutButton}
                            variant='text'
                            textStyle={styles.buttonText}
                        >
                            {width >= breakpoints.medium && (
                                "Déconnexion"
                            )}
                        </Button>
                    )}
                </View>
            </View>
        </View>
    )
}

export default Header

const makeStyles = (width) => StyleSheet.create({
    container: {
        zIndex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.light,
        paddingHorizontal: 33,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 10,
        elevation: 3,
        minHeight: width >= breakpoints.medium ? 100 : 80,
    },
    innerContainer: {
        flex: 1,
        maxWidth: breakpoints.large + 100,
        justifyContent: "space-between",
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        columnGap: 25
    },
    row2: {
        flexDirection: "row",
        alignItems: "center",
        columnGap: 15
    },
    goBackButton: {
        alignSelf: "center"
    },
    logoutButton: {
        alignSelf: "center"
    },
    text: {
        color: colors.primary300,
        paddingRight: 5,
        fontSize: fonts.body1
    },
    buttonText: {
        fontSize: fonts.body1
    },
    dropdownText: {
        color: colors.primary400,
    },
    dropdownContainer: {
        maxWidth: 250
    },
    dropdown: {
        borderColor: colors.primary100,
    },
    listItemContainer: {
        flex: 1,
        borderTopWidth: 1,
        paddingVertical: 7,
        borderColor: colors.primary100
    },
})