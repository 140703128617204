import { Formik } from 'formik'
import React, { useContext, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import Button from '../../../core/ui/Button'
import Text from '../../../core/ui/Text'
import TextField from '../../../core/ui/TextField'
import ToggleButtonGroup from '../../../core/ui/ToggleButtonGroup'
import BubblesIcon from "../../../assets/image/interrogation-bubbles-icon.svg"
import { CommonActions } from '@react-navigation/native'
import { AxiosContext } from '../../../core/contexts/axiosContext'
import { ContractContext } from '../../../core/contexts/contractContext'
import { UserContext } from '../../../core/contexts/userContext'
import * as yup from 'yup'
import Spinner from '../../../core/ui/animations/Spinner';
import LoadingWhiteIcon from "../../../assets/image/loading-white-icon.svg";
import { customStyles } from '../../../core/constants/customStyles';
import reportError from '../../../core/utils/reportError';

const validationSchema = yup.object().shape({
    other: yup.boolean().required(),
    email: yup
        .string()
        .when('other', {
            is: true,
            then: () => yup.string()
                .matches(/^[^@]+@[^@]+\.[^@]{2,}$/, "Merci de saisir une adresse mail valide.")
                .required('Une adresse email est requise'),
            otherwise: () => yup.string().optional()
        }),
})

const ContactEmailChoice = ({ route, navigation }) => {
    const [userChoice, setUserChoice] = useState("");
    const [showEmailTextField, setShowEmailTextField] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const { authAxios } = useContext(AxiosContext);
    const { user } = useContext(UserContext);
    const { loadContracts } = useContext(ContractContext);

    const { data } = route?.params || {};
    const buttonGroupData = [user?.email, data?.person?.mail, data?.person?.mailCC]
        ?.filter(i => !!i)
        ?.filter((v, i, ar) => ar.indexOf(v) === i)
        ?.map((i, k) => {
            return {
                id: k,
                value: i,
                title: i
            }
        });
    buttonGroupData.push({
        id: 1000,
        value: "autre",
        title: "Autre",
    });

    const onButtonChange = (userChoice, setFieldValue) => {
        setUserChoice(userChoice);

        if (userChoice === "autre") {
            setShowEmailTextField(true);
            setFieldValue('other', true);
        } else {
            setShowEmailTextField(false);
            setFieldValue('email', "");
            setFieldValue('other', false);
        }
    }

    const onSubmit = async (values) => {
        if (!data?.person?.id) return;

        try {
            setIsLoading(true)
            await authAxios.patch(`ikos/persons/${data.person.id}`, {
                mail: values.email?.toLowerCase() || userChoice,
                requestedMail: values.email?.toLowerCase() || userChoice,
            })
            await loadContracts()

            navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [{
                        name: 'InscriptionComplete',
                        params: { contractId: data.contract?.id }
                    },],
                })
            );
        } catch (e) {
            reportError(true, e);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <ScrollView contentContainerStyle={[styles.container, customStyles.reducedPageContainer]}>
            <Formik
                initialValues={{ email: "", other: showEmailTextField }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleChange, handleSubmit, setFieldValue, values, errors, isValid, dirty }) => (
                    <>
                        <BubblesIcon />
                        <Text style={styles.title}>À quelle adresse mail pouvons-nous vous écrire ?</Text>

                        <ToggleButtonGroup
                            buttonsStyle={styles.buttonsStyle}
                            data={buttonGroupData}
                            value={userChoice}
                            onValueChange={(userChoice) => onButtonChange(userChoice, setFieldValue)} />

                        {showEmailTextField && (
                            <TextField
                                containerStyle={styles.emailInput}
                                placeholder={"Entrez votre email"}
                                keyboardType="email-address"
                                onChangeText={handleChange('email')}
                            />
                        )}
                        {errors.email && values.email !== "" && <Text style={styles.error}>{errors.email}</Text>}

                        <Button
                            style={styles.buttonsStyle}
                            onPress={handleSubmit}
                            disabled={userChoice === "" || (userChoice === "autre" && (!isValid || !dirty))}
                            endIcon={isLoading && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
                        >
                            Valider
                        </Button>
                    </>
                )}
            </Formik>
        </ScrollView>
    )
}

export default ContactEmailChoice

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 30,
        paddingTop: 50,
    },
    title: {
        fontSize: fonts.h2,
        color: colors.primary300,
        marginTop: 23,
        marginBottom: 40,
    },
    emailInput: {
        marginVertical: 10
    },
    buttonsStyle: {
        width: "100%"
    },
    error: {
        color: colors.error,
        marginBottom: 10,
        alignSelf: "flex-start"
    }
})