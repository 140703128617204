import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import AvatarIcon from "../../../assets/image/avatar-glass-icon.svg";
import PeopleEnjoying from "../../../assets/image/people-enjoying-icon-blue.svg";
import TextFieldIcon from "../../../assets/image/text-field-avatar-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';
import Constants from 'expo-constants';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .matches(/^[^@]+@[^@]+\.[^@]{2,}$/, "Merci de saisir une adresse mail valide.")
        .required('Une adresse email est requise'),
})

const ForgotPassword = ({ route, navigation }) => {
    const [error, setError] = useState(null);

    const { email } = route.params || { email: '' };

    const onSubmit = async (values) => {
        let { email } = values;
        email = email?.toLowerCase();

        // await auth().setLanguageCode('fr');
        await sendPasswordResetEmail(getAuth(), email, {
            url: `${Constants.expoConfig.extra.APP_WEB_URL}/auth/action/reset`,
            handleCodeInApp: true,
            iOS: {
                bundleId: `${Constants.expoConfig.schema}`,
            },
            android: {
                packageName: `${Constants.expoConfig.schema}`,
                installApp: true,
            },
        }).then(_ => {
            navigation.navigate("EmailConfirmation", {
                email,
                action: 'reset',
            })
        }).catch(err => {
            console.error(err);
            switch (err.code) {
                case 'auth/user-not-found':
                    setError('Votre identifiant et/ou votre mot de passe sont erronés.');
                    break;
                default:
                    setError(err.message);
                    break;
            }
        });
    }

    return (
        <View style={styles.container}>
            <Formik
                initialValues={{ email: email }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleChange, handleSubmit, values, errors, isValid, dirty }) => (
                    <View style={styles.formContainer}>
                        <AvatarIcon style={styles.avatar} aria-label="icône avatar" />
                        <Text style={styles.title}>Réinitialisation de votre mot de passe</Text>
                        <Text style={styles.desc}>Veuillez saisir votre adresse e-mail ci-dessous.{"\n"}Nous vous enverrons les instructions pour créer un nouveau mot de passe.</Text>
                        {!!error && <Text style={styles.error}>{error}</Text>}

                        <TextField
                            onChangeText={handleChange('email')}
                            value={values.email}
                            placeholder="Email"
                            keyboardType="email-address"
                            containerStyle={styles.textField}
                            startIcon={<TextFieldIcon color={!dirty ? colors.primary200 : colors.primary300} />}
                        />
                        {errors.email && values.email !== "" && <Text style={styles.error}>{errors.email}</Text>}

                        <Button
                            disabled={!isValid}
                            onPress={handleSubmit}
                            style={styles.submitButton}
                        >
                            Envoyer
                        </Button>
                    </View>
                )}
            </Formik>
            <PeopleEnjoying style={styles.backgroundImage} />
        </View>
    )
}

export default ForgotPassword

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.primary400,
        flex: 1
    },
    backgroundImage: {
        position: "absolute",
        bottom: -6,
        left: 0,
        zIndex: -1
    },
    formContainer: {
        padding: 50,
        backgroundColor: colors.light,
        borderRadius: 10,
        maxWidth: 528,
        width: "100%"
    },
    avatar: {
        alignSelf: "center"
    },
    title: {
        marginTop: 23,
        marginBottom: 20,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    desc: {
        marginBottom: 20,
        fontSize: fonts.body1,
        color: colors.primary200,
        textAlign: "center"
    },
    textField: {
        marginBottom: 10
    },
    error: {
        color: colors.error,
        marginBottom: 10
    },
    submitButton: {
        marginTop: 10,
        width: "100%"
    }
})