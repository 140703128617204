import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Alert from "../../../core/ui/Alert";
import { isWeb } from '../../../core/utils/responsive';
import CommunicationsModal from '../../../core/ui/CommunicationsModal';
import WarningIcon from '../../../assets/image/red-warning-icon.svg';

const AlertsZone = ({ data }) => {
    const [communicationsModalVisible, setCommunicationsModalVisible] = useState(false);
    const [currentAlert, setCurrentAlert] = useState(null);

    const handleAlertCardPress = (alert) => {
        setCurrentAlert(alert);
        setCommunicationsModalVisible(true);
    }

    return (
        <View>
            {data?.map((alert, index) => (
                <Alert
                    key={index}
                    title={alert.attributes.title}
                    content={alert.attributes.content}
                    summary={alert.attributes.summary}
                    containerStyle={styles.alert}
                    onPress={() => handleAlertCardPress(alert)}
                />
            ))}
            {currentAlert && (
                <CommunicationsModal
                    modalVisible={communicationsModalVisible}
                    setModalVisible={setCommunicationsModalVisible}
                    title={currentAlert.attributes.title}
                    content={currentAlert.attributes.content || currentAlert.attributes.summary}
                    icon={<WarningIcon />}
                />
            )}
        </View>
    )
}

export default AlertsZone

const styles = StyleSheet.create({
    alert: {
        marginHorizontal: 15,
        marginTop: isWeb ? 0 : 20,
        marginBottom: isWeb ? 20 : 0
    },
})