import { createStackNavigator } from '@react-navigation/stack';
import { colors } from '../../core/constants/colors';
import React, { useContext, useEffect } from 'react';
import LeftArrowIcon from '../../assets/image/left-arrow-icon.svg';
import Button from '../../core/ui/Button';
import CustomHeader from './components/CustomHeader';
import TenantReference from './screens/TenantReference';
import CheckTenantBirthDate from './screens/CheckTenantBirthDate';
import CheckTenantPersonCode from './screens/CheckTenantPersonCode';
import CheckTenantName from './screens/CheckTenantName';
import ContractFound from './screens/ContractFound';
import ContactEmailChoice from './screens/ContactEmailChoice';
import ContactNumberChoice from './screens/ContactNumberChoice';
import IdentificationFailed from './screens/IdentificationFailed';
import InscriptionComplete from './screens/InscriptionComplete';
import * as StatusBar from 'expo-status-bar';
import { StyleSheet } from 'react-native';
import { isWeb } from '../../core/utils/responsive';
import { ContractContext } from '../../core/contexts/contractContext';
import { useLinkTo } from '@react-navigation/native';

const Stack = createStackNavigator();

const ContractAdding = () => {
    const { loadContracts } = useContext(ContractContext);
    const linkTo = useLinkTo();

    useEffect(() => {
        if (isWeb) {
            loadContracts()
                .finally(() => linkTo("/rattacher-contrat/reference"))
        } else {
            StatusBar.setStatusBarStyle('dark');
            return () => {
                StatusBar.setStatusBarStyle('light');
            };
        }
    }, []);


    return (
        <Stack.Navigator
            screenOptions={{
                headerTintColor: styles.headerTintColor,
                cardStyle: styles.cardStyle,
                headerStyle: styles.headerStyle,
                title: null,
                headerLeft: ({ onPress }) => (
                    <Button
                        variant="text"
                        startIcon={<LeftArrowIcon style={styles.buttonIconStyle} />}
                        style={styles.goBackButton}
                        onPress={onPress}
                    >
                        Retour
                    </Button>
                ),
                initialRouteName: "TenantReference",
            }}
        >
            {/* Step 1 */}
            <Stack.Screen
                options={{
                    header: ({ navigation }) => {
                        return CustomHeader('1/3', navigation.goBack);
                    }
                }}
                name="TenantReference"
                component={TenantReference}
            />

            {/* Step 2 */}
            <Stack.Screen
                options={{
                    header: ({ navigation }) => {
                        return CustomHeader('2/3', navigation.goBack);
                    }
                }}
                name="CheckTenantBirthDate"
                component={CheckTenantBirthDate}
            />
            <Stack.Screen
                options={{
                    header: ({ navigation }) => {
                        return CustomHeader('2/3', navigation.goBack);
                    }
                }}
                name="CheckTenantPersonCode"
                component={CheckTenantPersonCode}
            />
            <Stack.Screen
                options={{
                    header: ({ navigation }) => {
                        return CustomHeader('2/3', navigation.goBack);
                    }
                }}
                name="CheckTenantName"
                component={CheckTenantName}
            />

            {/* Step 3a */}
            <Stack.Screen
                options={{
                    header: ({ navigation }) => {
                        const onPress = () => navigation.goBack();
                        return CustomHeader('3/3', onPress);
                    }
                }}
                name="ContractFound"
                component={ContractFound}
            />

            {/* Step 3b */}
            <Stack.Screen
                options={{ headerShown: true }}
                name="IdentificationFailed"
                component={IdentificationFailed}
            />

            {/* Step 4 */}
            <Stack.Screen
                options={{ headerShown: true }}
                name="ContactNumberChoice"
                component={ContactNumberChoice}
            />
            <Stack.Screen
                options={{ headerShown: true }}
                name="ContactEmailChoice"
                component={ContactEmailChoice}
            />

            {/* Step 5 */}
            <Stack.Screen
                options={{ headerShown: false }}
                name="InscriptionComplete"
                component={InscriptionComplete}
            />
        </Stack.Navigator>
    );
};

export default ContractAdding;

const styles = StyleSheet.create({
    headerTintColor: {
        color: colors.secondary300
    },
    cardStyle: {
        backgroundColor: colors.light
    },
    headerStyle: {
        elevation: 0,
        shadowOpacity: 0,
    },
    goBackButton: {
        marginLeft: 30
    },
    buttonIconStyle: {
        marginRight: 5,
    }
})