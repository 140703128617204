import React, { useState, useContext } from 'react';
import { ScrollView, StyleSheet, View } from "react-native";
import { colors } from "../../../core/constants/colors";
import { fonts } from "../../../core/constants/fonts";
import useRequestsData from '../../../core/hooks/useRequestsData';
import useTrackScreenView from '../../../core/hooks/useTrackScreenView';
import Text from "../../../core/ui/Text";
import TextButtonCard from '../../../core/ui/TextButtonCard';
import RequestCard from "../components/RequestCard";
import { isWeb } from '../../../core/utils/responsive';
import { useNavigation } from '@react-navigation/native';
import { RequestContext } from '../../../core/contexts/requestContext';

// In web version we retrieve component data from props, not route
const RequestDetails = ({ data, status, statusTitle }) => {
    const steps = data?.requestSteps
        ?.sort((a, b) => new Date(a.stepRank) - new Date(b.stepRank))
        ?.filter(i => i.endDate && i.stepType?.isVisible) || [];

    const { requestsConfigData } = useContext(RequestContext);
    const topicObject = requestsConfigData?.requestTopics?.find(topic => {
        return Number(topic.code) === Number(data?.requestTopic)
    })?.object;

    const [lastBubbleContainerHeight, setLastBubbleContainerHeight] = useState(0);

    const oldACMWebsiteExpirationDate = "2023-06-12";
    const isRequestFromInternet = data?.contactOrigin?.code === "INT" && new Date(data.creationDate) > new Date(oldACMWebsiteExpirationDate);

    const { inProgressRequests } = useRequestsData();
    const isAnotherRequestInProgress = inProgressRequests.some(request => request?.requestTopic === data?.requestTopic && request?.code !== data?.code);

    const navigation = useNavigation();
    const handleCreateNewRequest = () => {
        navigation.navigate("RequestForm", { topicFromRoute: data.requestTopic })
    }

    useTrackScreenView("RequestDetails");

    return (
        <ScrollView style={styles.scrollableContainer}>
            <RequestCard
                onPress={() => { }}
                code={data?.code}
                subject={topicObject || data?.requestType?.label}
                status={status}
                statusTitle={statusTitle}
                hideStartIcon={true}
                hideEndIcon={true}
                style={styles.requestCard}
            />

            <View style={styles.container}>
                <View accessibilityRole='progressbar' style={[styles.progressBar, { marginBottom: lastBubbleContainerHeight - 10 }]} />
                <View style={styles.bubblesContainer}>
                    {(
                        steps.length === 0 ? (
                            <View>
                                <View style={styles.row}>
                                    <View style={styles.dot} />
                                    <Text style={styles.title}>
                                        Demande effectuée le {new Date(data?.creationDate).toLocaleDateString('fr')}
                                    </Text>
                                </View>
                                {(
                                    data?.requestBody && data?.creationDate && isRequestFromInternet
                                ) && (
                                        <>
                                            <View style={styles.bubble}>
                                                <Text style={styles.message}>{data.requestBody}</Text>
                                                <Text style={styles.messageDate}>Envoyé le {new Date(data.creationDate).toLocaleDateString('fr')}</Text>
                                            </View>
                                        </>
                                    )}
                            </View>
                        ) : (
                            steps?.map((step, i) => {
                                return (
                                    <View key={i} onLayout={i > 0 ? (e) => setLastBubbleContainerHeight(e.nativeEvent.layout.height) : () => { }}>
                                        <View style={styles.row}>
                                            <View style={[
                                                styles.dot,
                                                (step.stepStatus?.code === "R") && { backgroundColor: colors.error },
                                            ]} />
                                            <Text style={[
                                                styles.title,
                                                (step.stepStatus?.code === "T") && { color: colors.secondary300 },
                                                (step.stepStatus?.code === "R") && { color: colors.error },
                                            ]}>
                                                {step.stepType?.label} le {new Date(step.endDate).toLocaleDateString('fr')}{step.stepDecision && step.stepDecision.isVisible ? ': ' + step.stepDecision.label : ''}
                                            </Text>
                                        </View>
                                        <View style={{ rowGap: 10 }}>
                                            {step.comment && (
                                                <View style={styles.bubble}>
                                                    <Text style={styles.message}>{step.comment}</Text>
                                                </View>
                                            )}
                                            {i === 0 && step.endDate && data?.requestBody && isRequestFromInternet && (
                                                <View style={styles.bubble}>
                                                    <Text style={styles.message}>{data.requestBody}</Text>
                                                    <Text style={styles.messageDate}>Envoyé le {step.endDate && new Date(step.endDate).toLocaleDateString('fr')}</Text>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                )
                            })
                        )
                    )}
                </View>
            </View>
            {!isAnotherRequestInProgress && data?.requestStatus?.code === "REJ" && (
                <TextButtonCard
                    onPress={handleCreateNewRequest}
                    buttonTitle="Renvoyer un document"
                    containerStyle={{ marginHorizontal: 15, marginVertical: 3 }}
                />
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    scrollableContainer: {
        flexGrow: 1,
        paddingTop: isWeb ? 0 : 20,
        ...(isWeb && {
            alignSelf: "stretch",
            backgroundColor: colors.light
        })
    },
    container: {
        flexDirection: "row",
        marginVertical: 25,
        paddingHorizontal: 15,
    },
    bubblesContainer: {
        paddingLeft: 16,
        marginTop: -6.5,
        rowGap: 22
    },
    progressBar: {
        width: 1,
        backgroundColor: colors.secondary300,
    },
    row: {
        flexDirection: "row",
        gap: 10,
    },
    dot: {
        marginTop: 6,
        marginLeft: -22.2,
        backgroundColor: colors.secondary300,
        height: 12,
        width: 12,
        borderRadius: 50
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginBottom: 22
    },
    desc: {
        color: colors.primary300,
        fontSize: fonts.body2,
        marginBottom: 20
    },
    bubble: {
        padding: 15,
        borderWidth: 1,
        borderColor: colors.primary100,
        borderRadius: 10,
        backgroundColor: colors.light,
    },
    message: {
        color: colors.primary300,
        fontSize: fonts.body2,
    },
    messageDate: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
        marginTop: 13
    },
    requestCard: {
        borderRadius: 0
    },
    spinner: {
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
    }
});

export default RequestDetails;
