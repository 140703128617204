import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native'
import CheckIcon from "../../../assets/image/check-icon.svg"
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import { customStyles } from "../../../core/constants/customStyles";
import Button from '../../../core/ui/Button'
import Text from '../../../core/ui/Text'
import SeparatorLine from "../../../core/ui/SeparatorLine";
import { isWeb } from "../../../core/utils/responsive";
import { DeviceType } from "expo-device";
import * as Device from "expo-device";

const PaymentComplete = ({ route, navigation }) => {

    const [isMobilePossible, setIsMobilePossible] = useState(false)

    useEffect(() => {
      const findDeviceType = async () => {
        const myDeviceType = await Device.getDeviceTypeAsync()
        setIsMobilePossible([DeviceType.PHONE, DeviceType.TABLET].includes(myDeviceType))
      }
      findDeviceType()
    }, [])

    const onPress = async () => {
        if (isWeb) {
          window.location.href = window.origin;
        } else {
          navigation.navigate('Home')
        }
    }

    return (
        <View style={[styles.container, customStyles.reducedPageContainer]}>
          <CheckIcon style={styles.icon} />
          <Text style={styles.title}>Votre paiement a bien été réalisé !</Text>
          <Button
            style={styles.button}
            onPress={onPress}
          >
              Retourner à la page d’accueil
          </Button>
          {isMobilePossible && (
            <>
              <SeparatorLine text="ou" style={{ width: '100%' }} />
              <Text style={styles.desc}>Vous pouvez revenir à l'application</Text>
            </>
          )}
        </View>
    )
}

export default PaymentComplete

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 30,
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: fonts.h2,
    color: colors.primary300,
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center"
  },
  desc: {
    color: colors.primary200,
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center"
  },
  button: {
    width: "100%"
  },
  icon: {
    alignSelf: "center",
    width: 150,
    height: 150
  },
})