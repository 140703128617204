import React from 'react';
import { StyleSheet, View } from 'react-native';
import AvatarIcon from "../../../assets/image/avatar-icon2.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Text from '../../../core/ui/Text';

const OccupantCard = ({ contractPerson, style }) => {
    const person = contractPerson.person;
    const born = ["M", "", null].includes(person.sex) ? "Né" : "Née";

    return (
        <View style={[styles.container, style]}>
            <View style={styles.row}>
                <AvatarIcon  aria-label="icône avatar" />
                <Text style={styles.name}>
                    {person.title.trim()} {person.name.trim()} {person.lastName.trim()}
                </Text>
                <View style={styles.statusContainer}>
                    <Text style={styles.statusText}>Occupant</Text>
                </View>
            </View>

            <Text style={styles.infosText}>
                {born} le {person.birthDate && new Date(person.birthDate).toLocaleDateString('fr')}
            </Text>
            <View style={styles.separatorLine} />
        </View>
    )
}

export default OccupantCard

const styles = StyleSheet.create({
    container: {
        padding: 20,
        marginTop: 10,
        borderRadius: 10,
        shadowColor: 'black',
        backgroundColor: colors.light,
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3
    },
    row: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    name: {
        flex: 1,
        color: colors.primary300,
        fontSize: fonts.body2,
        marginLeft: 15
    },
    statusContainer: {
        backgroundColor: colors.primary100,
        paddingVertical: 4,
        paddingHorizontal: 5,
        borderRadius: 7
    },
    statusText: {
        color: colors.primary300,
        fontSize: fonts.subTitle,
    },
    infosText: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
        marginTop: 10
    }
})