import { CommonActions, useIsFocused } from '@react-navigation/native';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import PeopleEnjoyingIcon from "../../../assets/image/people-enjoying-icon2.svg";
import PlaneIcon from "../../../assets/image/plane-icon.svg";
import { colors } from '../../../core/constants/colors';
import { ContractContext } from '../../../core/contexts/contractContext';
import { RequestContext } from '../../../core/contexts/requestContext';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import { isWeb } from '../../../core/utils/responsive';

const RequestSend = ({ navigation }) => {
    const { loadContract, contract } = useContext(ContractContext);
    const { setIsRequestLoading } = useContext(RequestContext);

    useEffect(() => {
        setIsRequestLoading(true);
        loadContract(contract.id)
            .then(() => {
                setIsRequestLoading(false)
            });
    }, [contract.id])

    const isFocused = useIsFocused();
    const [screenFocusCount, setScreenFocusCount] = useState(0);

    useEffect(() => {
        if (isFocused) {
            setScreenFocusCount(prevCount => prevCount + 1);
        }
    }, [isFocused])

    useEffect(() => {
        const resetNavigationToRequestsList = () =>
            navigation.dispatch(
                CommonActions.reset({
                    routes: [{ name: 'RequestsList' }],
                })
            );

        if (screenFocusCount >= 2) {
            resetNavigationToRequestsList();
        }
    }, [navigation, screenFocusCount]);

    return (
        <View style={styles.container}>
            <PlaneIcon width={150} aria-label="icône avion en papier" />
            <Text style={styles.title}>Votre demande a bien été envoyée et sera traitée dans les plus brefs délais.</Text>
            <Button
                style={styles.button}
                onPress={() => navigation.navigate("NEW_REQUEST")}
            >
                J&apos;ai une autre demande
            </Button>
            <Button
                variant='outlined'
                style={[styles.button, { backgroundColor: colors.light }]}
                onPress={() => navigation.navigate("Home")}
            >
                Revenir à l’écran d’accueil
            </Button>
            {isWeb && (
                <PeopleEnjoyingIcon style={styles.peopleIcon} aria-label="icône bonhommes en bâton" />
            )}
        </View>
    )
}

export default RequestSend

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 30,
    },
    title: {
        fontSize: 22,
        color: colors.primary300,
        marginVertical: 25,
        textAlign: "center"
    },
    button: {
        paddingHorizontal: 20,
        marginBottom: 10,
    },
    peopleIcon: {
        position: "absolute",
        zIndex: -1,
        bottom: 0,
        right: 0
    },
})