import React from 'react'
import { Pressable, StyleSheet, TextInput, View } from 'react-native'
import { colors } from '../constants/colors'
import { isWeb } from '../utils/responsive'

const TextField = ({ value, onChangeText, onKeyPress, maxLength, rows, keyboardType, placeholder, containerStyle, startIcon, endIcon, onEndIconPress, secureTextEntry, disable, accessibilityLabel }) => {
    return (
        <View style={[styles().textFieldContainer, containerStyle, rows && { height: rows * 31 }]} testID="text-field-container">
            <View style={[styles(rows, disable).textInputContainer]} testID="text-input-container">
                {startIcon && (
                    <Pressable
                        testID="start-icon-container"
                        style={styles().startIconContainer}
                    >
                        {startIcon}
                    </Pressable>
                )}
                <TextInput
                    accessibilityLabel={accessibilityLabel || (placeholder ? "Entrez votre " + placeholder : "")}
                    maxLength={maxLength}
                    testID="text-input"
                    keyboardType={keyboardType}
                    value={value}
                    onChangeText={disable ? () => null : onChangeText}
                    onKeyPress={onKeyPress}
                    editable={!disable}
                    placeholder={placeholder}
                    placeholderTextColor={colors.primary200}
                    style={[styles(rows).textInput, rows > 0 && { height: rows * 30 }]}
                    secureTextEntry={secureTextEntry && true}
                    multiline={rows > 0}
                />
            </View>
            {endIcon && (
                <Pressable
                    testID="end-icon-container"
                    style={styles().endIconContainer}
                    onPress={onEndIconPress}
                >
                    {endIcon}
                </Pressable>
            )}
        </View>
    )
}

export default TextField

const styles = (rows, disable) => StyleSheet.create({
    textFieldContainer: {
        position: "relative",
    },
    textInputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: colors.primary100,
        borderRadius: 10,
        backgroundColor: disable ? colors.primary100 : colors.light
    },
    textInput: {
        padding: 10,
        paddingTop: rows ? 12 : 10,
        paddingLeft: isWeb ? 7 : 10,
        color: colors.primary300,
        textAlignVertical: rows ? "top" : null,
        height: "100%",
        width: "100%",
        outlineColor: isWeb ? "#015FCC" : null,
    },
    startIconContainer: {
        paddingLeft: 10,
        paddingRight: isWeb ? 5 : 0,
    },
    endIconContainer: {
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        right: 0,
        height: "100%",
        padding: 0,
        width: 40,
    }
})
