import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { SvgUri } from 'react-native-svg';
import DefaultCarouselIcon from '../../assets/image/bubbles-icon.svg';
import { isWeb } from './responsive';

const getThumbnail = (thumbnailData, defaultIcon = <DefaultCarouselIcon style={styles.logo} />) => {
    const thumbnail = thumbnailData?.[0]?.attributes;

    if (!thumbnail || !thumbnail.url) {
        return defaultIcon;
    }

    if (!isWeb && thumbnail.mime === "image/svg+xml") {
        return <SvgUri style={styles.logo} uri={thumbnail.url} />;
    }

    return <Image style={styles.image} source={{ uri: thumbnail.url }} />;
}

export default getThumbnail

const styles = StyleSheet.create({
    logo: {
        width: isWeb ? "100%" : 55,
        height: isWeb ? "100%" : 55,
    },
    image: {
        width: isWeb ? "100%" : 70,
        height: '100%',
    }
})