import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React, { useContext, useEffect } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import BubbleChatIcon from '../../assets/image/bubble-chat-icon.svg';
import BurgerIcon from "../../assets/image/burger-icon.svg";
import CreditCardIcon from '../../assets/image/credit-card-icon.svg';
import FourSquareIcon from '../../assets/image/four-square-icon.svg';
import HouseIcon from '../../assets/image/house-icon.svg';
import { breakpoints } from '../../core/constants/breakpoints';
import { colors } from '../../core/constants/colors';
import { fonts } from '../../core/constants/fonts';
import { ContractContext } from '../../core/contexts/contractContext';
import { UserContext } from '../../core/contexts/userContext';
import { useLinkTo } from '@react-navigation/native';
import Loader from '../../core/ui/Loader';
import Contract from '../Contract';
import Home from '../Home';
import More from '../More';
import Payment from '../Payment';
import Request from '../Request';
import useCommunicationsZoneData from '../../core/hooks/useCommunicationsZoneData';
import Spinner from '../../core/ui/animations/Spinner';

const TopTab = createMaterialTopTabNavigator();

const iconStyle = {
    height: 24,
};

const NavigationTabs = () => {
    const { width } = useWindowDimensions();
    const styles = makeStyles(width)

    const { loadContracts, isInitialLoading, setIsInitialLoading, isCurrentContractLoading } = useContext(ContractContext);
    const { isCommunicationsLoading } = useCommunicationsZoneData();
    const { isLoading: userIsLoading } = useContext(UserContext);

    const linkTo = useLinkTo();

    useEffect(() => {
        setIsInitialLoading(true);
        loadContracts()
            .then(res => {
                if (res.length === 0) linkTo({ screen: "ContractAdding" });
            })
            .finally(() => setIsInitialLoading(false))
    }, [])

    const isDataLoading = (userIsLoading || isInitialLoading || isCommunicationsLoading || isCurrentContractLoading);

    return (
        <TopTab.Navigator
            screenOptions={{
                swipeEnabled: false,
                animationEnabled: false,
                tabBarShowLabel: width >= breakpoints.small,
                tabBarShowIcon: width < breakpoints.small,
                tabBarStyle: styles.tabBarStyle,
                tabBarInactiveTintColor: colors.light,
                tabBarLabelStyle: styles.tabBarLabelStyle,
                tabBarActiveTintColor: colors.secondary300,
                tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
            }}
        >
            <TopTab.Screen
                name="Home"
                component={isDataLoading ? Loader : Home}
                options={{
                    title: "Accueil",
                    tabBarIcon: ({ color }) => (
                        <FourSquareIcon aria-label="Accueil" color={color} {...iconStyle} />
                    ),
                    lazy: true
                }}
            />
            <TopTab.Screen
                name="Contract"
                component={isDataLoading ? Loader : Contract}
                options={{
                    title: "Mon contrat",
                    tabBarIcon: ({ color }) => (
                        <HouseIcon aria-label="Mon contrat" color={color} {...iconStyle} />
                    ),
                    lazy: true
                }}
            />
            <TopTab.Screen
                name="Request"
                component={isDataLoading ? Loader : Request}
                options={{
                    title: "Demandes",
                    tabBarIcon: ({ color }) => (
                        <BubbleChatIcon aria-label="Demandes" color={color} {...iconStyle} />
                    ),
                }}
            />
            <TopTab.Screen
                name="Payment"
                component={isDataLoading ? Loader : Payment}
                options={{
                    title: "Paiements",
                    tabBarIcon: ({ color }) => (
                        <CreditCardIcon aria-label="Paiements" color={color} {...iconStyle} />
                    ),
                    lazy: true
                }}
            />
            <TopTab.Screen
                name="More"
                component={isDataLoading ? Loader : More}
                options={{
                    title: "Plus",
                    tabBarIcon: ({ color }) => (
                        <BurgerIcon color={color} {...iconStyle} />
                    ),
                    lazy: true
                }}
            />
        </TopTab.Navigator>
    )
};

export default NavigationTabs;

const makeStyles = (width) => StyleSheet.create({
    tabBarIndicatorStyle: {
        backgroundColor: colors.secondary300,
        height: 3,
    },
    tabBarLabelStyle: {
        fontFamily: "Helvetica",
        fontSize: fonts.body1,
        textTransform: "none",
    },
    tabBarStyle: {
        backgroundColor: colors.primary400,
        width: width >= breakpoints.medium ? "55%" : "100%",
        alignSelf: "center"
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    }
})