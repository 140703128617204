import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import Modal from '../../../core/ui/Modal'
import Text from '../../../core/ui/Text'

const CallAstreinteModal = ({ modalVisible, setModalVisible, astreinteNumber }) => {
    const formattedNumber = astreinteNumber.replace(/(\d{2})(?=\d)/g, '$1.');

    return (
        <Modal containerStyle={{ maxWidth: 300 }} closeIcon modalVisible={modalVisible} setModalVisible={setModalVisible}>
            <Text style={styles.title}>
                Vous pouvez joindre l&apos;astreinte au &thinsp;
                <a href={"tel:" + astreinteNumber}>{formattedNumber}</a>
            </Text>
        </Modal>
    )
}

export default CallAstreinteModal

const styles = StyleSheet.create({
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        textAlign: "center"
    }
})