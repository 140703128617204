import React from 'react';
import { Pressable, Modal as RnModal, StyleSheet, View } from 'react-native';
import CrossIcon from "../../assets/image/cross-icon.svg";
import { colors } from '../constants/colors';
import IconButton from './IconButton';

const Modal = ({ modalVisible, setModalVisible, children, containerStyle, closeIcon }) => {
  return (
    <RnModal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(!modalVisible)}
      statusBarTranslucent={true}
    >
      <View style={styles.centeredView}>
        <Pressable
          style={styles.overlayContainer}
          accessibilityRole='button'
          accessibilityLabel='Fermer la fenêtre'
          onPress={() => setModalVisible(false)}
        />
        <View style={[styles.container, containerStyle]}>
          {children}
        </View>
        {closeIcon && (
          <IconButton
            style={styles.closeModalIcon}
            icon={<CrossIcon />}
            accessibilityLabel="Fermer la fenêtre"
            onPress={() => setModalVisible(false)}
          />
        )}
      </View>
    </RnModal >
  );
};

const styles = StyleSheet.create({
  overlayContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: `${colors.primary400}AD`,
  },
  centeredView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    maxWidth: 400,
    width: "80%",
    maxHeight: "85%",
    margin: "auto",
    paddingVertical: 30,
    paddingHorizontal: 20,
    marginVertical: 30,
    alignItems: "center",
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  closeModalIcon:{
    marginTop: -20
  }
});

export default Modal;
