import { CommonActions, useLinkTo } from '@react-navigation/native';
import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import CheckIcon from "../../../assets/image/check-icon.svg";
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import { ContractContext } from '../../../core/contexts/contractContext';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import { isWeb } from '../../../core/utils/responsive';

const InscriptionComplete = ({ route, navigation }) => {
    const { contractId } = route?.params || {};
    const { loadContract } = useContext(ContractContext);

    const linkTo = useLinkTo();

    const onPress = async () => {
        await loadContract(contractId);

        if (isWeb) {
            linkTo(`/accueil`);
        } else {
            navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [{
                        name: 'MultiContracts',
                    },  {
                        name: 'NavigationTabs',
                    },],
                })
            );
        }
    }

    return (
        <ScrollView contentContainerStyle={[styles.container, customStyles.reducedPageContainer]}>
            <CheckIcon style={styles.icon} />
            <Text style={styles.title}>Votre inscription est terminée !</Text>
            <Button
                style={styles.button}
                onPress={onPress}
            >
                Accéder à mon espace
            </Button>
        </ScrollView>
    )
}

export default InscriptionComplete

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 30,
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        fontSize: fonts.h2,
        color: colors.primary300,
        marginTop: 20,
        marginBottom: 20,
        textAlign: "center"
    },
    button: {
        width: "100%"
    },
    icon: {
        alignSelf: "center",
        width: 150,
        height: 150
    },
})