import { useContext } from 'react';
import { ContractContext } from '../contexts/contractContext';

const NOT_IN_PROGRESS_STATUS = ['AFCDEETAFF', 'ANN', 'TRT', 'CLO', 'REJ'];
const COMPLETED_STATUS = ['TRT', 'CLO', 'REJ'];

const useRequestsData = () => {
    const { contract } = useContext(ContractContext);

    const sortedRequests = contract?.contractRequests
        ?.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
        ?.filter(i => i.requestCategory?.isVisible || i.contactOrigin?.code === "INT")
        || [];

    const inProgressRequests = sortedRequests?.filter(
        (item) => !NOT_IN_PROGRESS_STATUS.includes(item.requestStatus?.code)
    ) || [];

    const completedRequests = sortedRequests?.filter(
        (item) => COMPLETED_STATUS.includes(item.requestStatus?.code)
    ) || [];

    return { inProgressRequests, completedRequests };
};

export default useRequestsData;
