import React, { useContext } from "react";
import { Formik } from 'formik';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import { colors } from '../../../core/constants/colors';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import Spinner from '../../../core/ui/animations/Spinner';
import Button from '../../../core/ui/Button';
import LoadingWhiteIcon from "../../../assets/image/loading-white-icon.svg";
import reportError from '../../../core/utils/reportError';
import { useNavigation } from '@react-navigation/native';
import BuildingSelectionDropdown from "../components/BuildingSelectionDropdown";
import * as yup from 'yup';
import { isWeb } from '../../../core/utils/responsive';
import { customStyles } from '../../../core/constants/customStyles'
import FormattedTextField from "../components/FormattedTextField";

const generateValidationSchema = (components) => {
    if (!components) {
        return null;
    }

    const schemaObject = {};

    components.forEach((component) => {
        if (component.screenID !== 2) {
            return;
        }

        if (component.isRequired || component.condition) {
            let validationSchema = yup.string();

            if (component.isRequired) {
                validationSchema = validationSchema.required('Ce champ est obligatoire.');
            }

            if (component.condition) {
                const testFunction = new Function('value', 'parent', `${component.condition};`);

                validationSchema = validationSchema.test(
                    component.code,
                    '',
                    function (value) {
                        const result = testFunction(value, this.parent);
                        if (!result.isValid) {
                            return new yup.ValidationError(result.errorMessages.join('\n'), null, component.code);
                        }
                        return result.isValid;
                    }
                );
            }

            schemaObject[component.code] = validationSchema;
        }

        if (component.__component === "formulaire.champ-building-unit") {
            // TODO: Add validation for building unit if needed
        }
    });

    return yup.object().shape(schemaObject);
};

const RequestDynamicForm = ({ route }) => {
    const navigation = useNavigation();

    const { authAxios } = useContext(AxiosContext);
    const { docId, ocrRes, reqData, requestTopic, contractBuildingUnits } = route?.params?.data || {};

    const dropDownItems = contractBuildingUnits.filter(item => (!item.endDate || new Date() < new Date(item.endDate)))?.map(item => {
        const buildingLabel = item.buildingUnit?.building?.label;
        const buildingDoor = "Porte " + item.buildingUnit?.door;
        const streetName = item.buildingUnit?.building?.address?.streetName + " - " + buildingDoor;
        const nature = item?.buildingUnit?.nature?.orgLabel;
        const resultString = `${buildingLabel}\n${streetName}\n${nature}`;
        return { value: item.buildingUnit.id, label: resultString, isMain: item.isMain };
    });

    const dropdownValue = dropDownItems.length === 1 ? dropDownItems[0].value : dropDownItems.find(item => item.isMain) ? dropDownItems.find(item => item.isMain).value : "";

    const initialValues = {};

    requestTopic?.components?.forEach((component) => {
        if (component.screenID === 2 && component.__component !== "formulaire.champ-building-unit") {
            initialValues[component.code] = ocrRes[component.code] || "";
        }
        if (component.screenID === 2 && component.__component === "formulaire.champ-building-unit") {
            initialValues[component.code] = dropdownValue;
        }
    });

    const onSubmit = async (formData) => {
        try {
            await authAxios.post("/ikos/requests", { ...reqData, 'data': formData, documents: [{ id: docId }] });

            navigation.navigate("RequestSend");
        } catch (err) {
            if (err?.response?.data?.message?.startsWith('Contract Already Ended')) {
                reportError(true, err?.response?.data?.message, "Votre contrat est déjà terminé. Vous ne pouvez plus effectuer de demande.");
            }
            else {
                if (err?.response?.data?.message?.startsWith('Request Limit Exceeded')) {
                reportError(true, err?.response?.data?.message, "Votre quota de création d'affaire pour la journée est atteint, veuillez retenter plus tard.");
            }
            else {
                reportError(true, err.message);
            }
            }
        }
    };

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : null}
            style={customStyles.keyBoardAvoidingViewContainer}
        >
            <ScrollView contentContainerStyle={[styles.formContainer, isWeb && customStyles.reducedPageContainer]}>
                <Text style={styles.title}>
                    Informations recueillies
                </Text>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                    validationSchema={generateValidationSchema(requestTopic?.components)}
                    validateOnMount={true}
                >
                    {({ handleChange, handleSubmit, values, errors, isValid, isSubmitting }) => (<>
                        {requestTopic?.components?.map((component, i) => (
                            <View key={i}>
                                {(component.screenID === 2 && component.__component !== "formulaire.champ-building-unit") && (
                                    <FormattedTextField
                                        component={component}
                                        handleChange={handleChange(component.code)}
                                        error={errors[component.code]}
                                        value={values[component.code]}
                                    />
                                )}
                                {(component.__component === "formulaire.champ-building-unit") && (
                                    <BuildingSelectionDropdown
                                        component={component}
                                        contractBuildingUnits={contractBuildingUnits}
                                        handleChange={handleChange(component.code)}
                                        error={errors[component.code]}
                                        value={values[component.code]}
                                        dropDownItems={dropDownItems}
                                    />
                                )}
                            </View>
                        ))}
                        <Button
                            disabled={!isValid || isSubmitting}
                            style={styles.button}
                            onPress={handleSubmit}
                            endIcon={isSubmitting && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
                        >
                            Envoyer ma demande
                        </Button>
                    </>
                    )}
                </Formik>
            </ScrollView>
        </KeyboardAvoidingView>
    )
}

export default RequestDynamicForm;

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
    },
    formContainer: {
        paddingHorizontal: 15,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        gap: 2
    },
    title: {
        color: colors.primary300,
        marginBottom: 15,
        marginTop: 20,
    },
    error: {
        color: colors.error,
        marginBottom: 10,
    },
    button: {
        marginVertical: 30,
    },
    spinner: {
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
    }
})