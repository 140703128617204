import React from 'react';
import { Platform, Pressable, StyleSheet, View } from "react-native";
import { colors } from "../constants/colors";
import Text from "./Text";

const Button = ({ disabled, style, onPress, variant = "contained", color = colors.secondary300, startIcon, endIcon, textStyle, children, accessibilityLabel, accessibilityRole = "button", textProps }) => {
    const validVariantValues = ['contained', 'outlined', 'text'];

    if (!validVariantValues.includes(variant)) {
        throw new Error(`Invalid variant value: ${variant}.\r\nValid values of variant prop are 'contained', 'outlined' or 'text'.`);
    }

    return (
        <Pressable
            accessibilityLabel={accessibilityLabel}
            accessibilityRole={accessibilityRole}
            disabled={disabled}
            onPress={disabled ? () => null : onPress}
            testID="button"
            style={({ pressed }) => [
                styles(color, variant).buttonContainer,
                pressed && styles().onPress,
                variant === "contained" && styles(color).contained,
                variant === "outlined" && styles(color).outlined,
                style,
                disabled && styles().disabled
            ]}
        >
            {startIcon && startIcon}
            <Text style={[styles(color, variant).title, textStyle, disabled && styles().disabled]} {...textProps}>
                {children}
            </Text>
            {endIcon &&
                <View testID="end-icon-container" style={styles().endIconContainer}>
                    {endIcon}
                </View>
            }
        </Pressable>
    )
}

export default Button;

const styles = (color, variant) => StyleSheet.create({
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: variant === "text" ? "flex-start" : "center",
        paddingHorizontal: variant === "text" ? 0 : 16,
        paddingVertical: 12,
        borderRadius: 20,
    },
    onPress: {
        opacity: .80,
        transform: [{ scale: 0.97 }],
    },
    contained: {
        backgroundColor: color,
    },
    outlined: {
        borderColor: color,
        borderWidth: 1,
    },
    title: {
        alignSelf: "center",
        textAlign: "left",
        fontSize: 14,
        color: variant === "contained" ? colors.light : (variant === "outlined" || variant === "text") ? color : "black",
    },
    endIconContainer: {
        marginTop: Platform.OS === "android" ? -3 : .5,
    },
    disabled: {
        backgroundColor: colors.grey,
        color: colors.light,
        borderColor: colors.grey,
    }
})