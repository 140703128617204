import { createStackNavigator } from '@react-navigation/stack'
import React, { useContext } from 'react'
import LeftArrowIcon from "../../assets/image/white-left-arrow-icon.svg"
import { colors } from '../../core/constants/colors'
import { RequestContext } from '../../core/contexts/requestContext'
import IconButton from '../../core/ui/IconButton'
import { isWeb } from '../../core/utils/responsive'
import { defaultHeaderStyles } from '../Home/components/Header'
import NewRequestMenu from './screens/NewRequestMenu'
import RequestDetails from './screens/RequestDetails'
import RequestDynamicForm from './screens/RequestDynamicForm'
import RequestForm from './screens/RequestForm'
import RequestSend from './screens/RequestSend'
import RequestsList from './screens/RequestsList'
const Stack = createStackNavigator();

const Request = ({ navigation }) => {
    const { requestsConfigData } = useContext(RequestContext);

    return (
        <Stack.Navigator
            screenOptions={{
                headerStyle: defaultHeaderStyles.headerStyle,
                headerTitleStyle: defaultHeaderStyles.headerTitleStyle,
                headerTintColor: colors.light,
                headerTitleAlign: "center",
                title: "Suivis de mes demandes",
                headerShown: !isWeb,
                headerLeft: ({ onPress }) => (
                    <IconButton
                        style={defaultHeaderStyles.leftArrowIcon}
                        icon={<LeftArrowIcon aria-label="icône flèche à gauche" />}
                        onPress={onPress}
                        accessibilityLabel="Retour"
                    />
                ),
            }}>

            <Stack.Screen
                name="RequestsList"
                component={RequestsList}
                options={{
                    headerLeft: () => (
                        <IconButton
                            style={defaultHeaderStyles.leftArrowIcon}
                            icon={<LeftArrowIcon aria-label="icône flèche à gauche" />}
                            onPress={() => navigation.goBack()}
                            accessibilityLabel="Retour"
                        />
                    ),
                }}
            />

            <Stack.Screen
                name="RequestDetails"
                component={RequestDetails}
            />

            {requestsConfigData?.requestMenus?.map(requestMenu =>
                <Stack.Screen
                    key={requestMenu.code}
                    name={requestMenu.code}
                    options={{
                        title: "Nouvelle demande",
                        animationEnabled: false,
                    }}
                >
                    {() => <NewRequestMenu requestTopics={requestsConfigData?.requestTopics} requestMenus={requestsConfigData?.requestMenus} {...requestMenu} />}
                </Stack.Screen>
            )}

            <Stack.Screen
                name="RequestForm"
                component={RequestForm}
                options={{
                    title: "Nouvelle demande",
                }}
            />
            <Stack.Screen
                name="RequestDynamicForm"
                component={RequestDynamicForm}
                options={{
                    title: "Nouvelle demande",
                }}
            />
            <Stack.Screen
                name="RequestSend"
                component={RequestSend}
                options={{
                    title: "Nouvelle demande",
                }}
            />
        </Stack.Navigator >
    )
}

export default Request
