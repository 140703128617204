import { useContext, useMemo } from 'react';
import { CommunicationsContext } from '../contexts/communicationsContext';
import { ContractContext } from '../contexts/contractContext';
import { UserContext } from '../contexts/userContext';
import { getMainContractBuilding, getMyContractPerson } from '../utils/parseContract';

const filterByZoneCode = (it, zoneCode) => it.attributes?.zone?.data?.attributes?.code === zoneCode;

const formatStringWithData = (str, data) => {
    try {
        return str.replace(/\$\{([^}]+)\}/g, (match, group) => {
            let value = group.trim().split('.').reduce((o, i) => o[i], data)
            let vDate = new Date(value).toLocaleDateString('fr')
            return vDate === 'Invalid Date' ? value : vDate;
        })
    } catch (e) {
        return str
    }
}
const formatCommunicationAttributes = (it, data) => {
    if (it.attributes?.title) it.attributes.title = formatStringWithData(it.attributes.title, data)
    if (it.attributes?.summary) it.attributes.summary = formatStringWithData(it.attributes.summary, data)
    if (it.attributes?.content) it.attributes.content = formatStringWithData(it.attributes.content, data)
    return it;
}

const useCommunicationsZoneData = () => {
    const { contract } = useContext(ContractContext);
    const { communications, isLoading } = useContext(CommunicationsContext);
    const { claims } = useContext(UserContext);

    const contract_building_unit = useMemo(
        () => getMainContractBuilding(contract),
        [contract]
    )
    const person = useMemo(
        () => getMyContractPerson(contract, claims?.persons)?.person,
        [contract, claims]
    )
    const person_relationship = useMemo(
        () => contract?.personRelationships?.sort((a, b) => {
            return (a && b && a.endDate && b.endDate) ? new Date(b.endDate) - new Date(a.endDate) : 0;
        })?.[0],
        [contract]
    )

    const formattedCommunications = communications?.data?.map(it => formatCommunicationAttributes(it, { person, person_relationship, contract, contract_building_unit })) || [];

    const alerts = formattedCommunications
        ?.filter(it => filterByZoneCode(it, "alerte")) || [];

    const carousel = formattedCommunications
        ?.filter(it => filterByZoneCode(it, "carrousel")) || [];

    const announces = formattedCommunications
        ?.filter(it => filterByZoneCode(it, "contenu")) || [];

    const clientAccount = formattedCommunications
        ?.filter(it => filterByZoneCode(it, "CPTE_CLIENT")) || [];

    const actionsToDo = formattedCommunications
        ?.filter(it => filterByZoneCode(it, "ACTIONS_TODO")) || [];

    return {
        alerts,
        carousel,
        announces,
        clientAccount,
        actionsToDo,
        isLoading
    };
};

export default useCommunicationsZoneData;
