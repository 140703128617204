import { useIsFocused, useNavigation, useNavigationState, useRoute } from '@react-navigation/native';
import { useMatomo } from 'matomo-tracker-react-native';
import React, { useContext, useEffect, useState } from 'react';
import { Linking, ScrollView, StyleSheet, View } from 'react-native';
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import { RequestContext } from '../../../core/contexts/requestContext';
import useBreadcrumbs from '../../../core/hooks/useBreadcrumbs.web';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import ToggleTextCardIconGroup from '../../../core/ui/ToggleTextIconCardGroup';
import isServiceOpen from '../../../core/utils/isServiceOpen';
import { isWeb } from '../../../core/utils/responsive';
import AstreinteModal from '../../Home/components/AstreinteModal';
import BreadcrumbsContainer from '../components/BreadcrumbsContainer';
import CallClientServiceModal from '../components/CallClientService.web';
import RequestForm from './RequestForm';

const SERVICE_RELATION_CLIENT_NUMBER = '0499920910';

const NewRequestMenu = ({ requestMenus, shortTitle, code, requestTopics, topics, menus, question }) => {
    const navigation = useNavigation();
    const index = useNavigationState(state => state.index)
    const routes = useNavigationState(state => state.routes)

    const { params } = useRoute();

    const breadcrumbs = useBreadcrumbs({
        navigation,
        index,
        routes,
        shortTitle,
        isFromShortcutCard: !!params?.isFromShortcutCard,
        code
    });

    const [astreinteModalVisible, setAstreinteModalVisible] = useState(false);
    const [callClientServiceModalVisible, setCallClientServiceModalVisible] = useState(false);

    const { setLastMenuCode } = useContext(RequestContext);

    const childMenus = requestMenus
        ?.filter((item) => menus?.includes(item.code))
        .sort((a, b) => {
            return (a && b && a.code && b.code) ? menus.indexOf(a.code) - menus.indexOf(b.code) : 0;
        })
        || [];

    const childTopics = topics && topics.length > 0
        ? (requestTopics?.filter((item) => topics?.includes(item.code)) || [])
            .map(topic => ({
                id: topic.id,
                code: topic.code,
                shortTitle: topic.title,
                style: { code: "list" },
            }))
            .sort((a, b) => topics.indexOf(a.code) - topics.indexOf(b.code))
        : [];

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setLastMenuCode(code);
        });

        return unsubscribe;
    }, [code, navigation]);

    const isFocused = useIsFocused();
    const { trackAction } = useMatomo();
    useEffect(() => {
        if (isFocused && code === 'NEW_REQUEST') trackAction({ name: 'NewRequest' })
    }, [code, isFocused])

    const handleContactService = () => {
        if (isServiceOpen()) {
            if (isWeb) {
                setCallClientServiceModalVisible(true);
            } else {
                Linking.openURL(`tel://${SERVICE_RELATION_CLIENT_NUMBER}`)
                    .catch(e => console.error(e));
            }
        } else {
            setAstreinteModalVisible(true);
        }
    }

    const onChildMenuPress = (menuCode) => {
        navigation.navigate(menuCode);
    }

    const onChildTopicPress = (topicCode) => {
        navigation.navigate("RequestForm", { topicFromRoute: topicCode });
    }

    return (
        <>
            {(childMenus && childMenus.length === 0) ? (
                <RequestForm topics={topics} />
            ) : (
                <ScrollView contentContainerStyle={[styles.container, isWeb && customStyles.pageContainer]}>
                    {(!isWeb && breadcrumbs.length > 0) && (
                        <Text style={styles.subTitle}>
                            {breadcrumbs.join(" / ")}
                        </Text>
                    )}
                    <Text style={[styles.title, breadcrumbs && breadcrumbs.length === 0 && { marginTop: 24 }, isWeb && customStyles.pageTitle]}>
                        {question}
                    </Text>
                    {isWeb && (
                        <View style={{ marginBottom: 30, }}>
                            <BreadcrumbsContainer breadcrumbs={breadcrumbs} />
                        </View>
                    )}
                    <View style={styles.cardIconGroup}>
                        <ToggleTextCardIconGroup
                            onValueChange={onChildMenuPress}
                            data={childMenus}
                            noParent={isWeb}
                        />
                        <ToggleTextCardIconGroup
                            onValueChange={onChildTopicPress}
                            data={childTopics}
                            noParent={isWeb}
                        />
                    </View>
                    <View style={styles.bottomViewContainer}>
                        <Button onPress={handleContactService} style={styles.button} color={isServiceOpen() ? colors.secondary300 : colors.error} variant='outlined'>
                            {isServiceOpen() ? (
                                "Appeler le service de la relation client"
                            ) : (
                                "Une urgence? Contactez l’astreinte"
                            )}
                        </Button>
                        <AstreinteModal
                            modalVisible={astreinteModalVisible}
                            setModalVisible={setAstreinteModalVisible}
                        />
                        <CallClientServiceModal
                            clientServiceNumber={SERVICE_RELATION_CLIENT_NUMBER}
                            modalVisible={callClientServiceModalVisible}
                            setModalVisible={setCallClientServiceModalVisible}
                        />
                    </View>
                </ScrollView>
            )}
        </>
    )
}

export default React.memo(NewRequestMenu)

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        paddingHorizontal: 15,
    },
    cardIconGroup: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    subTitle: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
        marginTop: 20
    },
    title: {
        fontSize: 22,
        color: colors.primary300,
        marginBottom: 20,
        marginTop: 4,
    },
    button: {
        backgroundColor: colors.light,
    },
    bottomViewContainer: {
        flexGrow: 1,
        justifyContent: "flex-end",
        marginVertical: 40,
    },
})