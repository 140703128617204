import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';
import Button from './Button';
import Text from './Text';

const TextButtonCard = ({ title, buttonTitle, onPress, containerStyle }) => {
    return (
        <View style={[styles.container, containerStyle]}>
            {title && <Text style={styles.title}>{title}</Text>}
            <Button onPress={onPress}>
                {buttonTitle}
            </Button>
        </View>
    );
};

export default TextButtonCard;

const styles = StyleSheet.create({
    container: {
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.light,
        paddingVertical: 15,
        borderRadius: 10,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body2,
        marginBottom: 20,
        marginHorizontal: 20,
        textAlign: "center"
    },
})