import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import RightArrow from "../../../assets/image/right-arrow-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import CommunicationsModal from "../../../core/ui/CommunicationsModal";
import { isWeb } from '../../../core/utils/responsive';
import handleOpenCommunication from '../../../core/utils/handleOpenCommunication';
import { useNavigation } from '@react-navigation/native';

const AnnouncesZoneCard = ({ title, content, icon, pdfUrl }) => {
    const [communicationsModalVisible, setCommunicationsModalVisible] = useState(false);

    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <View style={styles.imgContainer}>
                {icon}
            </View>
            <View style={styles.textContainer}>
                <Text style={styles.title} numberOfLines={2} >
                    {title}
                </Text>
                <Button
                    variant='text'
                    style={styles.button}
                    endIcon={<RightArrow style={styles.icon} />}
                    onPress={() => handleOpenCommunication({
                        content,
                        setModalVisible: setCommunicationsModalVisible,
                        pdf: pdfUrl,
                        handleShowPdf: () => navigation.navigate('PdfPreview', { title, uri: pdfUrl })
                    })}
                    accessibilityLabel="En savoir plus la communication"
                >
                    En savoir plus
                </Button>
                <CommunicationsModal
                    modalVisible={communicationsModalVisible}
                    setModalVisible={setCommunicationsModalVisible}
                    title={title}
                    content={content}
                    icon={icon}
                />
            </View>
        </View>
    )
};

export default AnnouncesZoneCard;

const styles = StyleSheet.create({
    container: {
        flexDirection: isWeb ? "column" : "row",
        height: isWeb ? "100%" : null,
        width: isWeb ? "100%" : null,
        borderRadius: isWeb ? null : 10,
        paddingTop: isWeb ? null : 10,
        paddingBottom: isWeb ? null : 5,
        paddingHorizontal: isWeb ? null : 10,
        gap: isWeb ? 0 : 10,
        backgroundColor: colors.light,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        marginBottom: 8,
    },
    imgContainer: isWeb ? {
        alignItems: "center",
        justifyContent: "center",
        height: 200,
        width: "100%",
        overflow: 'hidden',
        backgroundColor: colors.secondary100
    } : {
        height: 65,
        width: 65,
        overflow: 'hidden',
        margin: 5,
        marginTop: 0
    },
    textContainer: {
        padding: isWeb ? 10 : null,
        flex: 1,
        justifyContent: "space-between",
    },
    image: {
        width: isWeb ? "100%" : 60,
        height: isWeb ? "100%" : 60,
    },
    title: {
        fontSize: fonts.body2,
        color: colors.primary300
    },
    button: {
        paddingTop: 0,
        paddingBottom: isWeb ? 0 : null
    },
    icon: {
        marginTop: 3.5,
        marginLeft: 5
    }
});