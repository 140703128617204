import { useNavigation } from '@react-navigation/native'
import { Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, Text, View } from 'react-native'
import DropDownPicker from 'react-native-dropdown-picker'
import ACMWomanIcon from "../../../assets/image/acm-woman-icon.svg"
import ArrowDownIcon from "../../../assets/image/arrow-down-icon.svg"
import LoadingWhiteIcon from "../../../assets/image/loading-white-icon.svg"
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import { AxiosContext } from '../../../core/contexts/axiosContext'
import { ContractContext } from '../../../core/contexts/contractContext'
import { RequestContext } from '../../../core/contexts/requestContext'
import Button from '../../../core/ui/Button'
import SeparatorLine from '../../../core/ui/SeparatorLine'
import TextField from '../../../core/ui/TextField'
import Spinner from '../../../core/ui/animations/Spinner'
import reportError from '../../../core/utils/reportError'
import { fullHeight, isWeb } from '../../../core/utils/responsive';
import { customStyles } from '../../../core/constants/customStyles'
import BreadcrumbsContainer from '../components/BreadcrumbsContainer'
import TakePictureCard from '../components/TakePictureCard'
import PickFileCard from '../components/PickFileCard'
import * as yup from 'yup';

export function handleSubject(breadcrumbs, currentTopic) {
    const defaultSubject = currentTopic?.connectors[0]?.subject;
    if (defaultSubject) return defaultSubject;

    let breadcrumbTitles = (breadcrumbs && breadcrumbs.length > 0)
        ? (isWeb ? breadcrumbs.map(item => item.title) : breadcrumbs)
            .map((item, index) => index > 0 ? item : item.replace('Dans ', ''))
            .concat(((breadcrumbs && isWeb ? breadcrumbs.map(item => item.title) : breadcrumbs) || []).includes(currentTopic?.title) ? [] : [currentTopic?.title])
            .map(i => i.trim())
            .filter(Boolean)
            .reverse()
        : [];

    if (breadcrumbTitles.length === 0) return currentTopic?.title || "-";

    let lastFittingTitle = "";
    breadcrumbTitles.reduce((totalLength, title) => {
        totalLength += title.length + 3;
        if (totalLength < 144) lastFittingTitle = title;
        return totalLength;
    }, 0)

    const generatedSubject = breadcrumbTitles
        .slice(0, breadcrumbTitles.indexOf(lastFittingTitle) + 1)
        .reverse()
        .join(' > ')

    return generatedSubject || "-";
}

const validationSchema = yup.object().shape({
    requestBody: yup.string()
        .max(144, 'Vous avez atteint le nombre de caractères maximales')
});

const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

const RequestForm = ({ topics: topicsFromProps, route }) => {
    const navigation = useNavigation();

    const { authAxios } = useContext(AxiosContext);
    const { lastMenuCode, breadcrumbs } = useContext(RequestContext);
    const { contract } = useContext(ContractContext);
    const { requestsConfigData } = useContext(RequestContext);

    const { topicFromRoute } = route?.params || {};
    const topicsCode = topicsFromProps || topicFromRoute

    const [openDropdown, setOpenDropDown] = useState(false);
    const [dropDownValue, setDropDownValue] = useState(topicFromRoute || (topicsFromProps && topicsFromProps.length === 1 && topicsFromProps[0]));
    const [isSubmitting, setIsSubmitting] = useState(false);

    let currentTopics = [];
    if (Array.isArray(topicsCode)) {
        currentTopics = requestsConfigData?.requestTopics
            ?.filter((item) => topicsCode?.includes(item?.code))
            .sort((a, b) => (a && b && a.code && b.code) ? topicsCode.indexOf(a.code) - topicsCode.indexOf(b.code) : 0) || [];
    } else {
        currentTopics = requestsConfigData?.requestTopics?.filter((item) => topicsCode === item?.code) || [];
    }

    const dropDownItems = currentTopics.map(topic => ({
        label: topic?.title,
        value: topic?.code
    }));

    const currentTopic = currentTopics?.find(topic => topic?.code === dropDownValue);

    const isCommentRequired = currentTopic
        ?.components
        ?.find(({ __component }) => __component === "formulaire.commentaire")
        ?.isRequired || false;

    const isFileRequired = currentTopic
        ?.components
        ?.find(({ __component }) => __component === "formulaire.fichier")
        ?.isRequired || false;

    const isOcr = currentTopic
        ?.components
        ?.find(({ __component }) => __component === "formulaire.fichier")
        ?.ocr ? true : false;

    const ocrType = currentTopic
        ?.components
        ?.find(({ __component }) => __component === "formulaire.fichier")
        ?.ocr
        ?.code || '';

    const [pickedDocument, setPickedDocument] = useState("");
    const [allowedFilesError, setAllowedFilesError] = useState("");

    function handlePickedDocument(pickedDocument) {
        setPickedDocument(pickedDocument);
    }

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);

            let { requestBody } = values;
            let ocrScreenData = null;

            if (pickedDocument) {
                requestBody = `${requestBody || ''}\nContient PJ`.trim()
            }

            const reqData = {
                "contract": contract?.code,
                "requestMenu": lastMenuCode,
                "requestTopic": currentTopic?.code,
                "requestType": currentTopic?.connectors[0].requestType,
                "requestStatus": "ENR",
                "subject": handleSubject(breadcrumbs, currentTopic),
                ...(requestBody && { "requestBody": requestBody }),
                "contactOrigin": "INT",
                "contactMode": "INT",
                "contactLocation": "INT"
            }

            if (pickedDocument) {
                const documentType = currentTopic
                    ?.components
                    ?.find(({ __component }) => __component === "formulaire.fichier")
                    ?.documentType || "affaire_pj";

                const formData = new FormData();

                formData.append('request', null);
                formData.append('contract', contract?.code);
                formData.append('documentType', documentType);

                const documents = Array.isArray(pickedDocument) ? pickedDocument : [pickedDocument];
                const isMultiple = documents.length > 1;

                documents.forEach((file) => {
                    const name = file.uri.split("/").pop();
                    if (isWeb) {
                        const base64Data = file.uri.split(',')[1]; // Remove the prefix
                        const contentType = file.uri.split(';')[0].split(':')[1]; // Get content type
                        const blob = base64ToBlob(base64Data, contentType); // Convert base64 to blob
                        const webFile = new File([blob], file.name || name, { type: contentType });
                        formData.append(isMultiple ? 'files' : 'file', webFile);
                    } else {
                        // For mobile
                        const fileType = file.mimeType || (file.type + "/" + name.split(".").pop());
                        formData.append(isMultiple ? 'files' : 'file', {
                            uri: file.uri,
                            name: file.name || name,
                            type: fileType,
                        });
                    }
                });

                const url = isMultiple ? "/documents/combined" : "/documents";
                const docRes = await authAxios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });

                if (isOcr) {
                    const ocrRes = await authAxios.post(`documents/${docRes.data.id}/analys/${ocrType}`);

                    ocrScreenData = {
                        docId: docRes.data.id,
                        formData: formData,
                        reqData: reqData,
                        ocrRes: ocrRes.data,
                        requestTopic: currentTopic,
                        contractBuildingUnits: contract.contractBuildingUnits,
                    };
                } else {
                    await authAxios.post("/ikos/requests", { ...reqData, documents: [{ id: docRes.data.id }] });
                }
            } else {
                await authAxios.post("/ikos/requests", reqData);
            }

            setIsSubmitting(false);

            if (!isOcr) {
                navigation.navigate("RequestSend");
            } else {
                navigation.navigate("RequestDynamicForm", { data: ocrScreenData });
            }
        } catch (err) {
            if (err?.response?.data?.message?.startsWith('Format de fichier non supporté: ')) {
                reportError(true, err?.response?.data?.message, "Le fichier doit être une image ou un document pdf/docx.");
            }
            if (err?.response?.data?.message?.startsWith('Contract Already Ended')) {
                reportError(true, err?.response?.data?.message, "Votre contrat est déjà terminé. Vous ne pouvez plus effectuer de demande.");
            }
            if (err?.response?.data?.message?.startsWith('Request Limit Exceeded')) {
                reportError(true, err?.response?.data?.message, "Votre quota de création d'affaire pour la journée est atteint, veuillez retenter plus tard.");
            }
            else {
                reportError(true, err.message);
            }
            setIsSubmitting(false);
        }
    }

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : null}
            style={customStyles.keyBoardAvoidingViewContainer}
        >
            <ScrollView contentContainerStyle={[styles.container, isWeb && customStyles.reducedPageContainer]}>
                <Text style={styles.subTitle}>
                    {isWeb ? (
                        <BreadcrumbsContainer breadcrumbs={breadcrumbs} />
                    ) : (
                        <Text style={styles.subTitle}>
                            {breadcrumbs.join(" / ")}
                        </Text>
                    )}
                </Text>
                <Formik enableReinitialize={true} onSubmit={onSubmit} initialValues={{ requestBody: "" }} validationSchema={validationSchema}>
                    {({ handleChange, handleSubmit, values, isValid, errors }) => (
                        <>
                            <Text style={styles.title}>Quel est votre choix ?</Text>
                            <View style={{ zIndex: 1 }}>
                                <DropDownPicker
                                    testID='dropDownPicker'
                                    listMode="SCROLLVIEW"
                                    open={openDropdown}
                                    value={dropDownValue}
                                    items={dropDownItems?.length > 0 ? dropDownItems : [{ label: "Aucun élément disponible", value: "not-found" }]}
                                    setOpen={setOpenDropDown}
                                    setValue={setDropDownValue}
                                    ArrowDownIconComponent={() => <ArrowDownIcon />}
                                    ArrowUpIconComponent={() => <ArrowDownIcon style={{ transform: [{ rotate: "180deg" }] }} />}
                                    placeholder="Choisir"
                                    style={styles.dropDown}
                                    textStyle={{ color: colors.primary300 }}
                                    listItemContainerStyle={styles.listItemContainer}
                                    dropDownContainerStyle={styles.dropDownContainer}
                                    scrollViewProps={{ nestedScrollEnabled: true }}
                                    maxHeight={fullHeight - 200}
                                />
                            </View>
                            {currentTopics
                                ?.filter(topic => topic?.code === dropDownValue)
                                ?.map((topic, i) => (
                                    <View key={i}>
                                        {topic?.components?.map((component, i) => (
                                            <View key={i}>
                                                {(component.__component === "formulaire.conseil") && (
                                                    <>
                                                        <View style={styles.cardInfo}>
                                                            <ACMWomanIcon />
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={styles.cardTitle}>
                                                                    {component.title}
                                                                </Text>
                                                                <Text style={styles.cardInfoText}>
                                                                    {component.content}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </>
                                                )}

                                                {(component.__component === "formulaire.commentaire") && (
                                                    <>
                                                        <View style={styles.row}>
                                                            <Text style={styles.title2}>{component.label || "Informations complémentaires"}</Text>
                                                            {isCommentRequired && (
                                                                <Text style={[styles.error, { fontSize: 20 }]}>*</Text>
                                                            )}
                                                        </View>
                                                        <TextField
                                                            containerStyle={styles.textField}
                                                            rows={component.isLong ? 3 : 0}
                                                            placeholder={component.placeHolder || "Votre texte ici..."}
                                                            onChangeText={handleChange('requestBody')}
                                                            value={values?.requestBody}
                                                            maxLength={145}
                                                        />
                                                        {errors.requestBody && (
                                                            <Text style={styles.error}>{errors.requestBody}</Text>
                                                        )}
                                                    </>
                                                )}

                                                {(component.__component === "formulaire.fichier") && (
                                                    <>
                                                        {isFileRequired && (
                                                            <View style={styles.row}>
                                                                <Text style={styles.title2}>{component.label || "Une photo ou une pièce jointe est obligatoire"}</Text>
                                                                <Text style={[styles.error, { fontSize: 20 }]}>*</Text>
                                                            </View>
                                                        )}
                                                        {component?.label === "Photo de la robinetterie" && (
                                                            <Text style={styles.title}>Veuillez nous fournir une photo de votre dernière attestation d&apos;assurance en cours de validité </Text>
                                                        )}
                                                        {(component.allowTakingPicture) && (
                                                            <TakePictureCard
                                                                component={component}
                                                                pickedDocument={pickedDocument}
                                                                onDocumentPicked={handlePickedDocument}
                                                                containerStyle={styles.pickFileCard}
                                                            />
                                                        )}
                                                        {((component.allowTakingPicture && component.allowFileUpload)) && (
                                                            <SeparatorLine text="ou" style={styles.separator} />
                                                        )}
                                                        {component.allowFileUpload && (
                                                            <>
                                                                <PickFileCard
                                                                    setAllowedFilesError={setAllowedFilesError}
                                                                    component={component}
                                                                    pickedDocument={pickedDocument}
                                                                    onDocumentPicked={handlePickedDocument}
                                                                    containerStyle={styles.pickFileCard}
                                                                />
                                                                {allowedFilesError && <Text style={styles.error}>{allowedFilesError}</Text>}
                                                            </>
                                                        )}

                                                    </>
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                ))
                            }
                            {isOcr ? (
                                <View style={styles.spinner}>
                                    <Button
                                        disabled={!pickedDocument || !dropDownValue || !isValid || (!pickedDocument && isFileRequired) || (values?.requestBody === '' && isCommentRequired) || isSubmitting}
                                        style={styles.button}
                                        onPress={handleSubmit}
                                        endIcon={isSubmitting && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
                                    >
                                        Suivant
                                    </Button>
                                    {isSubmitting && <Text style={styles.analyseMessage}>Analyse en cours...</Text>}
                                </View>
                            )
                                : <Button
                                    disabled={!dropDownValue || !isValid || (values?.requestBody === '' && isCommentRequired) || (!pickedDocument && isFileRequired) || isSubmitting || dropDownValue === "not-found"}
                                    style={[styles.button, { marginBottom: 25 }]}
                                    onPress={handleSubmit}
                                    endIcon={isSubmitting && <Spinner style={{ marginLeft: 5 }} ><LoadingWhiteIcon /></Spinner>}
                                >
                                    Envoyer ma demande
                                </Button>
                            }
                        </>
                    )}
                </Formik>
            </ScrollView>
        </KeyboardAvoidingView >
    )
}

export default RequestForm;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 15,
        flexGrow: 1
    },
    cardInfo: {
        flexDirection: "row",
        gap: 10,
        backgroundColor: colors.lightGrey,
        padding: 10,
        marginVertical: 10,
        borderWidth: 1,
        borderColor: colors.secondary300,
    },
    cardTitle: {
        color: colors.primary300,
        fontWeight: "800",
        marginBottom: 3
    },
    cardInfoText: {
        color: colors.primary300,
        fontSize: fonts.body2
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        gap: 2
    },
    title: {
        color: colors.primary300,
        marginBottom: 15,
        marginTop: 20,
    },
    title2: {
        color: colors.primary300,
        marginBottom: 15,
        marginTop: 10,
    },
    textField: {
        marginBottom: 10
    },
    dropDown: {
        borderColor: colors.primary100,
        marginBottom: 10,
    },
    dropDownContainer: {
        borderColor: colors.primary100,
        position: 'relative',
        top: -11
    },
    listItemContainer: {
        minHeight: 50,
        height: "auto",
        borderTopWidth: 1,
        borderColor: colors.primary100
    },
    pickFileCard: {
        marginVertical: 10
    },
    separator: {
        marginVertical: 5
    },
    error: {
        color: colors.error,
        marginBottom: 10,
    },
    button: {
        marginTop: 30,
    },
    spinner: {
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
        marginTop: 20
    },
    analyseMessage: {
        color: colors.grey,
        fontSize: fonts.body2,
        marginVertical: 15,
    }
})