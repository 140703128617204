import { Entypo } from '@expo/vector-icons';
import React, { useState } from 'react';
import { Alert, ScrollView, ImageBackground, Pressable, StyleSheet, View } from 'react-native';
import CameraIcon from "../../../assets/image/camera-icon.svg";

import PictureModal from "./PictureModal";
import { colors } from '../../../core/constants/colors';
import IconButton from '../../../core/ui/IconButton';
import Button from '../../../core/ui/Button';
import Text from "../../../core/ui/Text";
import { isWeb } from '../../../core/utils/responsive';

const TakePictureCard = ({ pickedDocument, component, containerStyle, onDocumentPicked }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [takedPictures, setTakedPictures] = useState([]);

    const handleShowPictureModal = () => {
        if (!Array.isArray(pickedDocument) && pickedDocument !== "") return Alert.alert("Seulement une seule pièce jointe peut être ajoutée.");
        setModalVisible(true);
    };

    const handleDeletePicture = (uri) => {
        const updatedPictures = takedPictures.filter((picture) => picture.uri !== uri);
        setTakedPictures(updatedPictures);
        onDocumentPicked(updatedPictures);

        if (updatedPictures.length === 0) {
            setModalVisible(false);
        }
    };

    const handlePictureSelected = (newPicture) => {
        const updatedPictures = [...takedPictures, newPicture];
        setTakedPictures(updatedPictures);
        onDocumentPicked(updatedPictures);
        setModalVisible(false);
    };

    return (
        <View style={[styles.container, containerStyle]}>
            {takedPictures.length > 0 ? (
                <>
                    <ScrollView horizontal style={styles.imageScroll}>
                        {takedPictures.map((picture, index) => (
                            <View key={index} style={styles.imageContainer}>
                                <ImageBackground
                                    style={styles.image}
                                    source={{ uri: picture.uri }}
                                    resizeMode="contain"
                                />
                                <IconButton
                                    onPress={() => handleDeletePicture(picture.uri)}
                                    icon={<Entypo name="circle-with-cross" size={24} color={colors.primary400} />}
                                    style={styles.icon}
                                />
                            </View>
                        ))}
                    </ScrollView>
                    <Button
                        onPress={handleShowPictureModal}
                        style={styles.addButton}
                        startIcon={<Entypo name="plus" size={24} color={colors.light} />}
                    >
                        Ajouter une autre photo
                    </Button>
                </>
            ) : (
                <Pressable
                    onPress={handleShowPictureModal}
                    style={styles.initialButton}
                    testID="image-picker-card"
                    accessibilityLabel={isWeb ? 'Ajouter une photo' : 'Prendre une photo'}
                    accessibilityRole='button'
                >
                    <View style={{ padding: 20, alignItems: "center" }}>
                        <CameraIcon />
                        <Text style={styles.text}>{isWeb ? 'Ajouter une photo' : 'Prendre une photo'}</Text>
                    </View>
                </Pressable>
            )}
            <PictureModal
                component={component}
                setTakedPicture={handlePictureSelected}
                onDocumentPicked={handlePictureSelected}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
            />
        </View>
    );
};

export default TakePictureCard;

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: colors.secondary300,
        borderStyle: "dashed",
    },
    imageScroll: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    imageContainer: {
        marginRight: 10,
        position: "relative",
    },
    image: {
        width: 100,
        height: 100,
    },
    icon: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    addButton: {
        alignSelf: 'stretch',
        marginTop: 10,
    },
    initialButton: {
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
    },
    text: {
        color: colors.secondary300,
        marginTop: 8,
    },
});
