import React, { useState } from "react";
import { StyleSheet, Text, View } from 'react-native';
import { colors } from '../../../core/constants/colors';
import TextField from '../../../core/ui/TextField';

const FormattedTextField = ({ component, handleChange, value, error }) => {
    const [allowChangeText, setAllowChangeText] = useState(true);

    const onChangeText = (text) => {
        // Remove non-numeric characters from the input
        const input = text.replace(/[^0-9]/g, '');

        // Insert slashes after jj, mm
        let formattedInput = input;
        if (allowChangeText) {
            if (input.length >= 2) {
                formattedInput = `${input.slice(0, 2)}/${input.slice(2)}`;
            }
            if (input.length >= 4) {
                formattedInput = `${formattedInput.slice(0, 5)}/${formattedInput.slice(5)}`;
            }
            handleChange(formattedInput);
        }
    };

    const onKeyPress = (nativeEvent) => {
        // remove slashes before mm and aaaa
        if (nativeEvent.key === 'Backspace') {
            if (value.length === 3) {
                handleChange(value.slice(0, 2));
                setAllowChangeText(false);
            } else if (value.length === 6) {
                handleChange(`${value.slice(0, 2)}/${value.slice(3, 5)}`);
                setAllowChangeText(false);
            } else {
                setAllowChangeText(true);
            }
        } else {
            setAllowChangeText(true);
        }
    };

    return (
        <>
            <View style={styles.row}>
                <Text style={styles.title}>{component.label || "Champ texte"}</Text>
                {component.isRequired && (
                    <Text style={[styles.error, { fontSize: 20 }]}>*</Text>
                )}
            </View>
            {component.__component === "formulaire.champ-date" ?
                <TextField
                    containerStyle={styles.textField}
                    placeholder={component.placeholder || "JJ/MM/YYYY"}
                    onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent)}
                    onChangeText={(text) => onChangeText(text)}
                    value={value === "DD/MM/YYYY" ? "" : value}
                    keyboardType="numeric"
                /> : <TextField
                    containerStyle={styles.textField}
                    placeholder={component.placeholder || "Votre texte ici..."}
                    onChangeText={handleChange}
                    value={value}
                />
            }
            {error && <Text style={styles.error}>{error}</Text>}
        </>
    )
}
export default FormattedTextField;

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        gap: 2
    },
    title: {
        color: colors.primary300,
        marginBottom: 15,
        marginTop: 10,
    },
    textField: {
        marginBottom: 10
    },
    error: {
        color: colors.error,
        marginBottom: 10,
    }
})