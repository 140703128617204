import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import LeftArrowIcon from "../../assets/image/white-left-arrow-icon.svg";
import { colors } from '../../core/constants/colors';
import useTrackScreenView from '../../core/hooks/useTrackScreenView';
import IconButton from '../../core/ui/IconButton';
import { isWeb } from '../../core/utils/responsive';
import { defaultHeaderStyles } from '../Home/components/Header';
import MoreOptions from './screens/MoreOptions';
import PdfPreview from '../Home/screens/PdfPreview';

const Stack = createStackNavigator();

const More = ({ navigation }) => {
    useTrackScreenView('PlusMenu');

    return (
        <Stack.Navigator
            screenOptions={{
                gestureEnabled: false,
                headerStyle: defaultHeaderStyles.headerStyle,
                headerTitleStyle: defaultHeaderStyles.headerTitleStyle,
                headerTintColor: colors.light,
                headerTitleAlign: "center",
                headerShown: !isWeb,
                title: "Plus d'options",
                headerLeft: () => (
                    <IconButton
                        style={defaultHeaderStyles.leftArrowIcon}
                        icon={<LeftArrowIcon />}
                        onPress={() => navigation.goBack()}
                        accessibilityLabel="Retour"
                    />
                ),
            }}>
            <Stack.Screen
                name="MoreOptions"
                component={MoreOptions}
            />
            <Stack.Screen name="PdfPreview" component={PdfPreview}
                options={{
                    headerStyle: defaultHeaderStyles.headerStyle,
                    headerTitleStyle: defaultHeaderStyles.headerTitleStyle,
                    headerTintColor: colors.light,
                    headerTitleAlign: "center",
                    title: "Aperçu du PDF",
                    headerLeft: ({ onPress }) => (
                        <IconButton
                            style={defaultHeaderStyles.leftArrowIcon}
                            icon={<LeftArrowIcon />}
                            onPress={onPress}
                            accessibilityLabel="Retour"
                        />
                    ),
                }}
            />
        </Stack.Navigator>
    )
}

export default More