import { useLinkTo, useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'
import AgencyIcon from "../../../assets/image/agency-icon.svg"
import HouseAvatarIcon from "../../../assets/image/avatar-house-icon.svg"
import FilesIcon from "../../../assets/image/files-icon.svg"
import KeyIcon from "../../../assets/image/key-icon.svg"
import { breakpoints } from '../../../core/constants/breakpoints'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import Text from '../../../core/ui/Text'
import TextIconCard from '../../../core/ui/TextIconCard'
import ClientServiceCard from './ClientServiceCard'
import AgencyModal from './AgencyModal'
import ClientAccountZone from './ClientAccountZone'

const ClientAccountSection = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const linkTo = useLinkTo();
  const navigation = useNavigation();

  const { width } = useWindowDimensions();
  const styles = makeStyles(width);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>ACM et moi</Text>
      <View style={styles.row}>
        <View style={styles.cardContainer}>
          <ClientAccountZone />
          <TextIconCard
            onPress={() => linkTo('/contrat')}
            title="Mes locations"
            icon={<KeyIcon />}
          />
          <TextIconCard
            onPress={() => linkTo('/contrat?id=occupants')}
            title="Mes occupants"
            icon={<HouseAvatarIcon />}
          />
          <TextIconCard
            onPress={() => navigation.navigate("20", { isFromShortcutCard: true })}
            title="Changement de situation"
            icon={<FilesIcon />}
          />
          <TextIconCard
            onPress={() => setModalVisible(true)}
            title="Mon agence"
            icon={<AgencyIcon />}
          />
        </View>
        <View style={styles.clientServiceContainer}>
          <ClientServiceCard />
        </View>
      </View>
      <AgencyModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </View>
  )
}

export default ClientAccountSection

const makeStyles = (width) => StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: width <= 635 ? 0 : 15,
  },
  title: {
    color: colors.primary300,
    fontSize: fonts.body1,
    marginTop: 20,
    marginBottom: 10,
    marginLeft: width < 635 ? 15 : 0,
    fontWeight: 600
  },
  row: {
    flexDirection: "row",
    justifyContent: width <= 664 ? "center" : "space-between",
    alignItems: "center",
    flexWrap: width <= 800 ? "wrap" : null,
    rowGap: width >= breakpoints.medium ? 0 : 10,
    columnGap: 15
  },
  clientServiceContainer: {
    height: width <= 664 ? null : "100%",
    flex: 1,
    minWidth: 230,
  },
  cardContainer: {
    flexDirection: "row",
    justifyContent: width <= 360 ? "space-evenly" : "space-between",
    flexWrap: "wrap",
    maxWidth: 390,
    width: "100%",
    minHeight: 173,
    paddingHorizontal: width <= breakpoints.medium ? 15 : 0
  },
})