import { useEffect, useRef } from "react";

function useDidUpdateEffect(callback, dependencies) {
  const hasComponentMountedRef = useRef(false);

  useEffect(() => {
    if (hasComponentMountedRef.current) {
      return callback();
    }
    hasComponentMountedRef.current = true;
  }, dependencies);
}

export default useDidUpdateEffect;
