import React, { } from 'react';
import { StyleSheet, View } from 'react-native';
import BubblesIcon from "../../../assets/image/interrogation-bubbles-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import { useNavigation } from '@react-navigation/native';

const NewRequest = () => {
    const navigation = useNavigation();

    const handleCreateNewRequest = () => {
        navigation.navigate("NEW_REQUEST");
    };

    return (
        <View style={styles.container}>
            <BubblesIcon style={styles.icon} aria-label="icône bulles de dialogue" />
            <Text style={styles.title}>
                Besoin d’assistance ?
            </Text>
            <Text style={styles.desc}>
                Effectuez vos demandes administratives,{"\n"}
                sollicitez notre intervention technique,{"\n"}
                signalez-nous un dysfonctionnement{"\n"}
                en quelques secondes.
            </Text>
            <Button
                onPress={handleCreateNewRequest}
                accessibilityLabel="Crée une nouvelle demande"
                accessibilityRole='link'
            >
                Nouvelle demande
            </Button>
        </View>
    )
}

export default NewRequest;

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        marginHorizontal: 25,
    },
    title: {
        fontSize: fonts.h2,
        color: colors.primary300,
        marginTop: 25
    },
    desc: {
        fontSize: fonts.body1,
        color: colors.primary300,
        marginVertical: 25,
        textAlign: "center"
    },
    icon: {
        marginTop: 45
    }
})