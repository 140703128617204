import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import LeftArrowIcon from "../../assets/image/white-left-arrow-icon.svg"
import { colors } from '../../core/constants/colors'
import IconButton from '../../core/ui/IconButton'
import { defaultHeaderStyles } from '../Home/components/Header'
import { isWeb } from '../../core/utils/responsive'
import PaymentsList from './screens/PaymentsList'
import PaymentMake from './screens/PaymentMake';
import InvoicePreview from './screens/InvoicePreview';

const Stack = createStackNavigator();

const Payment = ({ navigation }) => {
    return (
        <Stack.Navigator screenOptions={{
            headerStyle: defaultHeaderStyles.headerStyle,
            headerTitleStyle: defaultHeaderStyles.headerTitleStyle,
            headerTintColor: colors.light,
            headerTitleAlign: "center",
            title: "Mes paiements",
            headerShown: !isWeb,
            headerLeft: () => (
                <IconButton
                    style={defaultHeaderStyles.leftArrowIcon}
                    icon={<LeftArrowIcon />}
                    onPress={() => navigation.goBack()}
                    accessibilityLabel="Retour"
                />
            ),
        }}>
            <Stack.Screen
                name="PaymentsList"
                component={PaymentsList}
            />
            <Stack.Screen
                name="InvoicePreview"
                component={InvoicePreview}
                options={{
                    headerLeft: ({ onPress }) => (
                        <IconButton
                            style={defaultHeaderStyles.leftArrowIcon}
                            icon={<LeftArrowIcon />}
                            onPress={onPress}
                        />
                    ),
                }}
            />

            <Stack.Screen
                name="PaymentMake"
                component={PaymentMake}
                options={{
                    title: 'Payer',
                    headerLeft: ({ onPress }) => (
                        <IconButton
                            style={defaultHeaderStyles.leftArrowIcon}
                            icon={<LeftArrowIcon />}
                            onPress={onPress}
                        />
                    ),
                    headerRight: null,
                }}
            />
        </Stack.Navigator>
    )
}

export default Payment