import { useLinkTo, useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import { StyleSheet, View } from "react-native";
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import LeftArrowIcon from "../../../assets/image/left-arrow-icon.svg";
import { FirebaseContext } from '../../../core/contexts/firebaseContext';
import { UserContext } from '../../../core/contexts/userContext';
import Button from "../../../core/ui/Button";
import ProgressBar from "./ProgressBar";
import { customStyles } from '../../../core/constants/customStyles';
import { isWeb } from '../../../core/utils/responsive';

const CustomHeader = (progressStatus, onPress) => {
    const { signOut } = useContext(FirebaseContext);

    const { claims } = useContext(UserContext);

    const linkTo = useLinkTo();

    const handleGoBack = () => {
        if (progressStatus === "1/3" && !claims?.contracts?.length) {
            signOut();
        } else {
            if (isWeb) {
                linkTo("/accueil");
            } else {
                onPress();
            }
        }
    }

    return (
        <SafeAreaInsetsContext.Consumer>
            {(insets) => <View style={[styles(insets).container, customStyles.pageContainer]}>
                <ProgressBar progressStatus={progressStatus} />
                <Button
                    variant='text'
                    startIcon={<LeftArrowIcon style={styles().buttonIconStyle} />}
                    onPress={handleGoBack}
                    accessibilityLabel="Retour"
                >
                    Retour
                </Button>
            </View>}
        </SafeAreaInsetsContext.Consumer>
    )
}

export default CustomHeader

const styles = (insets) => StyleSheet.create({
    container: {
        paddingTop: insets?.top + 10,
        paddingHorizontal: 30,
    },
    buttonIconStyle: {
        marginRight: 5,
    }
})
