import React, { useState } from 'react';
import { Image, Pressable, StyleSheet, View } from 'react-native';
import { SvgUri } from 'react-native-svg';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';
import { isWeb } from '../utils/responsive';
import Text from './Text';

const ToggleTextIconCardGroup = ({ value, onValueChange, data, containerStyle, noParent }) => {
    const [activeCardValue, setActiveCardValue] = useState(value);

    const handleCardPress = (cardValue) => {
        setActiveCardValue(cardValue);
        onValueChange && onValueChange(cardValue)
    };

    const renderCardGroup = () => {
        return (
            <>
                {data?.map((item, i) => (
                    <Pressable
                        key={i}
                        onPress={() => handleCardPress(item.value ?? item.code)}
                        style={({ pressed }) =>
                            [
                                {
                                    // borderColor: activeCardValue === item.value ? colors.secondary300 : colors.primary300
                                },
                                pressed && styles.whenPressed,
                                styles.cardContainer,
                                (item?.style?.code === "list" && !isWeb) && { width: "100%" },
                                (item?.style?.code === "list" && isWeb) && { maxWidth: 374 }
                            ]}
                        accessibilityRole='button'
                    >
                        {(item.style?.code === "picto" && item.pictoUrl) && (
                            <View style={styles.iconContainer}>
                                {isWeb ? (
                                    <Image style={styles.logo} source={{ uri: item.pictoUrl }} />
                                ) : (
                                    <SvgUri uri={item.pictoUrl} />
                                )}
                            </View>
                        )}

                        {item.icon && (
                            <View style={styles.iconContainer}>
                                {item.icon}
                            </View>
                        )}
                        <Text
                            style={[{
                                // color: activeCardValue === item.value ? colors.secondary300 : colors.primary300
                            },
                            styles.title]}
                        >
                            {item.title ?? item.shortTitle}
                        </Text>
                    </Pressable>
                ))}
            </>
        );
    };

    if (noParent) {
        return renderCardGroup();
    }

    return (
        <View
            style={[
                styles.container,
                containerStyle,
            ]}
            testID="toggle-card-container"
        >
            {renderCardGroup()}
        </View>
    );
};

export default ToggleTextIconCardGroup;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    cardContainer: {
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
        width: isWeb ? "100%" : "48%",
        maxWidth: isWeb ? 200 : null,
        marginHorizontal: isWeb ? 5 : null,
        paddingVertical: 25,
        borderWidth: 1,
        marginBottom: 10,
        backgroundColor: colors.light,
        borderRadius: 10,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3,
        borderColor: colors.primary300
    },
    title: {
        fontSize: fonts.body2,
        color: colors.primary300,
        textAlign: "center",
        paddingHorizontal: 5
    },
    logo: {
        width: 60,
        height: 60,
    },
    iconContainer: {
    },
    onPress: {
        opacity: .80,
        transform: [{ scale: .97 }]
    }
})