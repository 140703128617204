import { StyleSheet, View } from 'react-native'
import React from 'react'
import { Link } from '@react-navigation/native'
import ArrowRight from "../../../assets/image/arrow-right-breadcrumbs.svg"
import { colors } from '../../../core/constants/colors'

const BreadcrumbsContainer = ({ breadcrumbs }) => {
    return (
        <View style={styles.breadcrumbsContainer}>
            {(breadcrumbs.map((breadcrumb, i) =>
                <View style={styles.breadcrumb} key={breadcrumb.code}>
                    {i >= 1 && (
                        <ArrowRight aria-label="icône flèche à droite" style={styles.arrowRight} />
                    )}
                    <Link
                        to={{ screen: breadcrumb.code }}
                        style={[
                            styles.breadcrumbTitle,
                            i === breadcrumbs.length - 1 && {
                                textDecorationLine: "underline"
                            }
                        ]}
                    >
                        {breadcrumb.title}
                    </Link>
                </View>
            ))}
        </View>
    )
}

export default BreadcrumbsContainer

const styles = StyleSheet.create({
    breadcrumbsContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        rowGap: 4,
        marginVertical: 20,
    },
    breadcrumb: {
        flexDirection: "row",
    },
    breadcrumbTitle: {
        color: colors.primary300,
    },
    arrowRight: {
        width: 6,
        height: 6,
        marginTop: 6,
        marginLeft: 10,
        marginRight: 10,
    },
})