import AsyncStorage from '@react-native-async-storage/async-storage';
import { setTimeout } from '@testing-library/react-native/build/helpers/timers';
import Constants from 'expo-constants';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import AvatarIcon from "../../../assets/image/avatar-glass-icon.svg";
import TextFieldIcon from "../../../assets/image/text-field-avatar-icon.svg";
import EyeIcon from "../../../assets/image/text-field-eye-icon.svg";
import LockIcon from "../../../assets/image/text-field-lock-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';
import ResetPasswordOkModal from '../components/ResetPasswordOkModal';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, confirmPasswordReset, updatePassword } from 'firebase/auth';
import PeopleEnjoying from '../../../assets/image/people-enjoying-icon-blue.svg';
import { CommonActions } from '@react-navigation/native';

const validationSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, ({ min }) => `Le mot de passe doit contenir au minimum ${min} caractères`)
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d#?!@$%^&*-]{8,}$/, `Le mot de passe doit contenir au minimum 8 caractères, au moins une lettre et un chiffre`)
        .required('Un mot de passe est requis'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Le mot de passe saisi n’est pas identique sur les deux champs')
        .required('Le mot de passe de confirmation est requis'),
})

const NewUserConnection = ({ route, navigation }) => {
    const [linkEmail, setLinkEmail] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [securedTextEntry, setSecuredTextEntry] = useState(true)
    const [layout, setLayout] = useState('signIn');
    const [modalVisible, setModalVisible] = useState(false);

    const { apiKey, mode, oobCode, continueUrl } = route.params || {};

    useEffect(() => {
        if (!['signIn', 'resetPassword'].includes(mode)) navigation.goBack()
        else if (!oobCode) navigation.goBack()
    }, [mode, navigation, oobCode])

    useEffect(_ => {
        AsyncStorage.getItem('SignInLinkEmail').then(email => setLinkEmail(email || ''));
        AsyncStorage.getItem('SignInLinkEmail').then(email => setEmail(email || ''));
    }, [])

    useEffect(() => {
        setLayout(mode || 'signIn');
    }, [mode]);

    const onSubmitSignIn = useCallback(async (values) => {
        let { password } = values;

        const absPath = `${Constants.expoConfig.extra.GOOGLE_SIGNIN_URL}/${route.path}`;
        if (isSignInWithEmailLink(getAuth(), absPath)) {
            await signInWithEmailLink(
                getAuth(), email, absPath
            ).then(async _ => {
                await AsyncStorage.removeItem('SignInLinkEmail');
                setTimeout(async () => await updatePassword(getAuth().currentUser, password), 500);
            }).catch(err => {
                console.error(err);
                switch (err.code) {
                    case 'auth/expired-action-code':
                    case 'auth/invalid-action-code':
                        setError('Le lien est expiré, merci d\'en regénérer un autre.');
                        break;
                    case 'auth/email-already-in-use':
                        setError('Un compte est déjà créé pour l\'email que vous souhaitez utiliser. Merci de contacter ACM Habitat.');
                        break;
                    case 'auth/weak-password':
                        setError('Le mot de passe ne répond pas aux exigences, merci d\'en saisir un autre.');
                        break;
                    default:
                        setError(err.message);
                        break;
                }
            });
        } else {
            setError('Le lien est expiré, merci d\'en regénérer un autre.');
        }
    }, [email, route.path])

    const onSubmitResetPassword = useCallback(async (values) => {
        let { password } = values;

        await confirmPasswordReset(getAuth(), oobCode, password)
            .then(async _ => {
                setModalVisible(true)
            })
            .catch(err => {
                console.error(err);
                switch (err.code) {
                    case 'auth/expired-action-code':
                    case 'auth/invalid-action-code':
                        setError('Le lien est expiré, merci d\'en regénérer un autre.');
                        break;
                    case 'auth/weak-password':
                        setError('Le mot de passe ne répond pas aux exigences, merci d\'en saisir un autre.');
                        break;
                    default:
                        setError(err.message);
                        break;
                }
            });
    }, [oobCode])

    return (
        <View style={styles.container}>
            <Formik
                initialValues={{ password: '', confirmPassword: '' }}
                onSubmit={layout === 'signIn' ? onSubmitSignIn : onSubmitResetPassword}
                validationSchema={validationSchema}
            >
                {({ handleChange, handleSubmit, values, errors, isValid, dirty }) => (
                    <View style={styles.formContainer}>
                        <AvatarIcon style={styles.avatar} />
                        <Text style={styles.title}>Connexion par email</Text>
                        {layout === 'signIn' && <Text style={styles.desc}>C’est votre première connexion, choisissez votre mot de passe.</Text>}
                        {layout === 'resetPassword' && <Text style={styles.desc}>Réinitialiser votre mot de passe</Text>}
                        {!!error && <Text style={styles.error}>{error}</Text>}

                        {layout === 'signIn' && <TextField
                            disable={linkEmail !== ''}
                            value={email}
                            containerStyle={styles.textField}
                            onChangeText={(v) => setEmail(v)}
                            startIcon={<TextFieldIcon color={colors.primary300} />}
                        />}

                        <TextField
                            onChangeText={handleChange('password')}
                            value={values.password}
                            placeholder="Mot de passe"
                            containerStyle={styles.textField}
                            startIcon={<LockIcon color={values.password === "" ? colors.primary200 : colors.primary300} />}
                            endIcon={<EyeIcon color={values.password === "" ? colors.primary200 : colors.primary300} />}
                            onEndIconPress={() => setSecuredTextEntry(!securedTextEntry)}
                            secureTextEntry={securedTextEntry}
                        />
                        {errors.password && values.password !== "" && <Text style={styles.error}>{errors.password}</Text>}

                        <TextField
                            onChangeText={handleChange('confirmPassword')}
                            value={values.confirmPassword}
                            placeholder="Confirmer votre mot de passe"
                            containerStyle={styles.textField}
                            startIcon={<LockIcon color={values.confirmPassword === "" ? colors.primary200 : colors.primary300} />}
                            endIcon={<EyeIcon color={values.confirmPassword === "" ? colors.primary200 : colors.primary300} />}
                            onEndIconPress={() => setSecuredTextEntry(!securedTextEntry)}
                            secureTextEntry={securedTextEntry}
                        />
                        {errors.confirmPassword && values.confirmPassword !== "" && <Text style={styles.error}>{errors.confirmPassword}</Text>}

                        <Button
                            disabled={!isValid || !dirty}
                            onPress={handleSubmit}
                            style={styles.submitButton}
                        >
                            Suivant
                        </Button>

                        <ResetPasswordOkModal modalVisible={modalVisible} setModalVisible={() => { setModalVisible(false); navigation.goBack() }} />
                    </View>
                )}
            </Formik>
            <PeopleEnjoying style={styles.backgroundImage} />
        </View>
    )
}

export default NewUserConnection

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.primary400,
        flex: 1
    },
    backgroundImage: {
        position: "absolute",
        bottom: -6,
        left: 0,
        zIndex: -1
    },
    formContainer: {
        padding: 50,
        backgroundColor: colors.light,
        borderRadius: 10,
        maxWidth: 528,
        width: "100%"
    },
    avatar: {
        alignSelf: "center"
    },
    title: {
        marginTop: 20,
        marginBottom: 40,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    desc: {
        marginBottom: 20,
        fontSize: fonts.body1,
        color: colors.primary200
    },
    error: {
        color: colors.error,
        marginBottom: 10
    },
    textField: {
        marginBottom: 10
    },
    submitButton: {
        marginTop: 10,
        width: "100%"
    }
})