import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Modal from '../../../core/ui/Modal';
import Text from '../../../core/ui/Text';
import DueNoticeCard from './DueNoticeCard';

const PaymentMonitoringModal = ({ payments, modalVisible, setModalVisible }) => {
    return (
        <Modal
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
            containerStyle={styles.container}
            closeIcon
        >
            <Text style={styles.title}>Avis d&apos;échéances</Text>
            <ScrollView contentContainerStyle={styles.scrollViewContent} style={{ width: "100%", paddingBottom: 20 }}>
                {payments?.slice(0, 12).map((item) => (
                    <DueNoticeCard
                        key={item.id}
                        style={styles.dueNoticeCard}
                        title={`Echéance de ${new Date(item.creationDate).toLocaleDateString('fr', {
                            month: 'long',
                            year: new Date(item.creationDate).getFullYear() !== new Date().getFullYear() ? '2-digit' : undefined
                        })}`}
                        file={{ id: item.id, fileName: item.fileName, availableForDownload: !!item.storageCode }}
                    />
                ))}
            </ScrollView>
        </Modal>
    )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: 527,
        paddingBottom: 0,
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginVertical: 10,
        marginLeft: 15,
        alignSelf: "flex-start",
        fontWeight: 600
    },
    scrollViewContent: {
        paddingHorizontal: 7,
        flex: 1,
        paddingRight: 18,
    },
    dueNoticeCard: {
        margint: 8,
    },
    closeModalIcon: {
        bottom: -70,
        position: "absolute"
    },
});

export default PaymentMonitoringModal;
