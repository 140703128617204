import React, { useState } from 'react';
import { FlatList, ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';
import BottomArrowIcon from "../../../assets/image/bottom-arrow-icon.svg";
import { breakpoints } from '../../../core/constants/breakpoints';
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import usePaymentsInfo from '../../../core/hooks/usePaymentsInfo';
import useTrackScreenView from '../../../core/hooks/useTrackScreenView';
import Button from '../../../core/ui/Button';
import Loader from '../../../core/ui/Loader';
import Text from '../../../core/ui/Text';
import DueNoticeCard from "../components/DueNoticeCard";
import PaymentCard from '../components/PaymentCard';
import PaymentMonitoringCard from "../components/PaymentMonitoringCard";
import PaymentMonitoringModal from '../components/PaymentMonitoringModal';
import AdditionalPaymentCard from '../components/AdditionalPaymentCard';

const PaymentsList = () => {
    const { payments, paymentRecords, isLoading } = usePaymentsInfo();

    const { width } = useWindowDimensions();
    const styles = makeStyles(width);

    const [modalVisible, setModalVisible] = useState(false);

    useTrackScreenView("Payment");

    if (isLoading) {
        return <Loader />
    }

    return (
        <ScrollView contentContainerStyle={[styles.container, customStyles.pageContainer]} >
            <Text style={customStyles.pageTitle}>Mes paiements</Text>
            <View style={styles.cardsContainer}>
                <View style={[styles.childContainer, width > breakpoints.medium && { maxHeight: 110, justifyContent: 'center' }]}>
                    <PaymentCard data={paymentRecords} showSecondButton={false} />
                </View>
                <View style={styles.childContainer}>
                    <Text style={styles.paymentText}>Avis d&apos;échéances</Text>
                    <FlatList
                        data={payments?.slice(0, 1)}
                        renderItem={({ item }) => <DueNoticeCard
                            title={`Echéance de ${new Date(item.creationDate).toLocaleDateString('fr', {
                                month: 'long',
                                year: new Date(item.creationDate).getFullYear() !== new Date().getFullYear() ? '2-digit' : undefined
                            })}`}
                            file={{ id: item.id, fileName: item.fileName, availableForDownload: !!item.storageCode }}
                        />}
                        keyExtractor={item => item.id}
                        scrollEnabled={true}
                    />
                    {payments.length > 1 ? (
                        <Button
                            variant='text'
                            color={colors.primary200}
                            style={styles.showMoreButton}
                            textStyle={styles.showMoreText}
                            endIcon={<BottomArrowIcon aria-label="icône flèche vers le bas" />}
                            onPress={() => setModalVisible(true)}
                        >
                            Voir les 12 dernières périodes
                        </Button>
                    ) : (
                        <Text style={styles.fallbackText}>Aucun avis d&apos;échéance.</Text>
                    )}
                </View>
            </View>

            <View style={styles.row}>
                <Text style={styles.paymentMonitoringText}>Suivi des règlements</Text>
                {paymentRecords.length > 0 && (
                    <Text style={styles.sumText}>Montants</Text>
                )}
            </View>
            <FlatList
                data={paymentRecords}
                renderItem={({ item }) => <PaymentMonitoringCard
                    title={item.description}
                    price={item.amount}
                    date={item.operationDate}
                    isPending={item.isPending}
                />}
                keyExtractor={item => item.id}
                scrollEnabled={true}
            />
            {paymentRecords.length === 0 && (
                <Text style={[styles.fallbackText, { marginLeft: 15 }]}>Aucun réglement.</Text>
            )}

            <PaymentMonitoringModal payments={payments} modalVisible={modalVisible} setModalVisible={setModalVisible} />
        </ScrollView>
    )
}

export default PaymentsList

const makeStyles = (width) => StyleSheet.create({
    container: {
        flexGrow: 1,
        paddingHorizontal: 15,
        paddingBottom: 30,
    },
    cardsContainer: {
        flexDirection: width <= breakpoints.medium ? "column" : "row",
        columnGap: 20,
    },
    childContainer: {
        flex: width <= breakpoints.medium ? null : 1
    },
    title: {
        color: colors.primary300,
        fontSize: fonts.h2,
        marginTop: 20,
        marginBottom: 10,
        paddingHorizontal: 15,
    },
    paymentText: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginTop: width <= breakpoints.medium ? 20 : -7,
        marginBottom: 10,
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
    },
    paymentMonitoringText: {
        color: colors.primary300,
        fontSize: fonts.body1,
        marginTop: 20,
        marginBottom: 10,
    },
    sumText: {
        color: colors.primary200,
        fontSize: 13,
        textAlign: "center",
        marginTop: 20,
        marginBottom: 10,
    },
    fallbackText: {
        color: colors.primary200,
        fontSize: 13,
        textAlign: "left",
        marginTop: 0,
        marginBottom: 15,
    },
    showMoreText: {
        color: colors.primary200,
        fontSize: 13,
        textAlign: "center",
    },
    showMoreButton: {
        paddingVertical: 0,
        marginTop: 10,
        marginBottom: 10,
    },
})