import { useNavigation } from '@react-navigation/native';
import { useMatomo } from 'matomo-tracker-react-native';
import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import { API_URL } from '../../../core/contexts/axiosContext';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import { isWeb } from '../../../core/utils/responsive';
import { saveAs } from 'file-saver';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import slugify from 'slugify';

const DueNoticeCard = ({ title, file, style }) => {
  const navigation = useNavigation();

  const { authAxios } = useContext(AxiosContext)

  const { trackDownload } = useMatomo();

  const handleInvoiceFile = () => {
    trackDownload({ download: `${API_URL}/documents/${file.id}/download` })

    if (isWeb) {
      handleDownloadFileWeb({
        id: file.id,
        fileName: file.fileName?.replace(/\s+/g, '-')?.replace(/-+/g, '-') || `${new Date().getTime()}.pdf`
      })
      return;
    }

    handlePreview({
      id: file.id,
      fileName: file.fileName?.replace(/\s+/g, '-')?.replace(/-+/g, '-') || `${new Date().getTime()}.pdf`
    })
  }

  const handlePreview = (file) => {
    navigation.navigate('InvoicePreview', { title, file })
  }

  const handleDownloadFileWeb = async (file) => {
    try {
      const blob = await authAxios.get(`/documents/${file.id}/download`, {
        responseType: 'blob'
      })
        .then(res => res.data)
      saveAs(blob, file.filename || slugify(title, { lower: true }))
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <View style={[styles.container, style]}>
      <View style={styles.textContainer}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.buttonContainer}>
        {file.availableForDownload && (
          <Button
            variant='text'
            textStyle={styles.downloadButtonText}
            onPress={handleInvoiceFile}
          >
            Télécharger
          </Button>
        )}
      </View>
    </View>
  )
}

export default DueNoticeCard

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.light,
    borderRadius: 10,
    paddingVertical: 15,
    paddingHorizontal: 15,
    shadowColor: 'black',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
    marginRight: 3,
    marginBottom: 3,
    minHeight: 72,
  },
  textContainer: {
    flexDirection: "column",
    gap: 5,
  },
  title: {
    color: colors.primary200,
    fontSize: isWeb ? fonts.body1 : fonts.subTitle,
  },
  downloadButtonText: {
    color: colors.secondary300,
    fontSize: fonts.body2
  },
  downloadFileIcon: {
    marginLeft: 5
  }
})