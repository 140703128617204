import { StyleSheet, View } from 'react-native'
import React from 'react'
import Text from '../../../core/ui/Text'
import Button from '../../../core/ui/Button'
import { colors } from '../../../core/constants/colors'
import { fonts } from '../../../core/constants/fonts'
import { useNavigation } from '@react-navigation/native'

const ContractAddSection = () => {
  const navigation = useNavigation()

  return (
    <View style={styles.container}>
      <Text style={styles.desc}>Vous avez un autre contrat?</Text>
      <Button
        onPress={() => navigation.navigate("ContractAdding")}
        style={styles.button}
      >
        Rattacher un autre contrat
      </Button>
    </View>
  )
}

export default ContractAddSection

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginTop: 25,
    marginBottom: 25,
    paddingTop: 25,
    paddingBottom: 25,
  },
  title: {
    textAlign: "center",
    color: colors.primary300,
    fontSize: fonts.body2,
  },
  desc: {
    color: colors.primary200,
    fontSize: fonts.subTitle,
  },
  button: {
    marginTop: 15,
    paddingVertical: 8
  }
})