import { Linking, StyleSheet, View, useWindowDimensions } from 'react-native'
import React, { useState } from 'react';
import Text from '../../../core/ui/Text'
import Button from '../../../core/ui/Button'
import { colors } from '../../../core/constants/colors'
import HappyStickMan from "../../../assets/image/happy-stick-man.svg"
import { breakpoints } from '../../../core/constants/breakpoints'
import DownloadMobileAppModal from './DownloadMobileAppModal';

const DownloadMobileApp = () => {
    const { width } = useWindowDimensions();
    const styles = makeStyles(width);

    const [modalVisible, setModalVisible] = useState(false);

    const handleGoToStore = () => {
        setModalVisible(true);
    }

    return (
        <View style={styles.container}>
            <Text style={styles.title}>La nouvelle app ACM est disponible !</Text>
            <Button onPress={handleGoToStore} style={styles.button} variant='outlined'>Télécharger</Button>
            <HappyStickMan style={styles.headerImage} />
            <DownloadMobileAppModal
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
            />
        </View>
    )
}

export default DownloadMobileApp

const makeStyles = (width) => StyleSheet.create({
    container: {
        backgroundColor: colors.primary400,
        borderRadius: width >= breakpoints.medium ? 10 : 0,
        marginTop: width >= breakpoints.medium ? 0 : -20,
        marginBottom: width <= breakpoints.medium ? 30 : 0,
        padding: 20,
        rowGap: 10,
        alignItems: "flex-start",
        overflow: "hidden",
    },
    title: {
        color: colors.light
    },
    button: {
        alignSelf: "flex-start"
    },
    headerImage: {
        position: "absolute",
        zIndex: -1,
        right: 0,
        bottom: -10
    },
})