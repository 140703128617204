import React from 'react'
import { StyleSheet, View } from 'react-native'
import LoadingIcon from "../../assets/image/loading-icon.svg"
import Spinner from './animations/Spinner'

const Loader = ({ style }) => {
    return (
        <View style={[styles.spinnerContainer, style]} accessibilityLabel='Chargement en cours'>
            <Spinner>
                <LoadingIcon aria-label="icône chargement" width={40} height={40} />
            </Spinner>
        </View>
    )
}

export default Loader

const styles = StyleSheet.create({
    spinnerContainer: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
})