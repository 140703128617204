import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet } from 'react-native';
import * as yup from 'yup';
import AvatarIcon from "../../../assets/image/avatar-glass-icon.svg";
import RightArrowIcon from "../../../assets/image/right-arrow-icon.svg";
import { colors } from '../../../core/constants/colors';
import { customStyles } from '../../../core/constants/customStyles';
import { fonts } from '../../../core/constants/fonts';
import { AxiosContext } from '../../../core/contexts/axiosContext';
import { UserContext } from '../../../core/contexts/userContext';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import TextField from '../../../core/ui/TextField';
import FindTenantReferenceModal from '../components/FindTenantReferenceModal';

const validationSchema = yup.object().shape({
    contractRef: yup
        .string()
        .matches(/^\d{6}\/\d{2}$/, 'Merci de saisir la référence locataire sous le bon format')
        .matches(/^[\d/]+$/, 'Seuls les chiffres et les slash "/" sont autorisés dans la référence locataire')
        .max(9, 'La référence locataire ne doit pas dépasser 9 caractères')
        .required('Votre référence locataire est requise pour continuer'),
})

const TenantReference = ({ navigation }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const { authAxios } = useContext(AxiosContext);
    const { user } = useContext(UserContext);

    const onSubmit = async (values) => {
        const data = {
            contractRef: values.contractRef
        }

        const response = await authAxios.post(`users/${user.id}/persons`, data)
            .then(res => res.data)
            .catch(err => err.response.data)

        const isProfessional = response.challenge?.includes('personCode');

        if (isProfessional) {
            navigation.navigate("CheckTenantPersonCode", { data, response });
        } else {
            navigation.navigate("CheckTenantBirthDate", { data, response })
        }

    }

    const [allowChangeText, setAllowChangeText] = useState(true);

    const onChangeText = (text, setFieldValue) => {
        // Remove non-numeric characters from the input
        const input = text.replace(/[^0-9]/g, '');
        // If the input is longer than 6 digits, insert the slash
        let formattedInput = input;
        if (allowChangeText) {
            if (input.length >= 6) {
                formattedInput = `${input.slice(0, 6)}/${input.slice(6)}`;
            }
            setFieldValue("contractRef", formattedInput)
        }
    }

    const onKeyPress = (nativeEvent, setFieldValue, values) => {
        let input = values.contractRef;
        // Remove the slash ("/") when deleting if length is 7
        if (
            nativeEvent.key === 'Backspace' &&
            input.length === 7
        ) {
            setFieldValue('contractRef', input.slice(0, 6));
            setAllowChangeText(false);
        } else {
            setAllowChangeText(true);
        }
    }

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : null}
            style={[customStyles.keyBoardAvoidingViewContainer, customStyles.reducedPageContainer]}
        >
            <Formik
                initialValues={{ contractRef: '' }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, setFieldValue, values, errors, isValid, dirty }) => (
                    <ScrollView contentContainerStyle={styles.container}>
                        <AvatarIcon />
                        <Text style={styles.title}>Recherchons ensemble votre contrat</Text>

                        <Text style={styles.label}>Référence locataire</Text>
                        <TextField
                            maxLength={9}
                            onChangeText={(text) => onChangeText(text, setFieldValue)}
                            onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, setFieldValue, values)}
                            value={values.contractRef}
                            placeholder="123456/78"
                            containerStyle={styles.textField}
                            keyboardType="numeric"
                        />
                        {errors.contractRef && values.contractRef !== "" && <Text style={styles.error}>{errors.contractRef}</Text>}

                        <Button
                            variant='text'
                            style={styles.openModalButton}
                            endIcon={<RightArrowIcon style={styles.buttonIconStyle} />}
                            onPress={() => setModalVisible(true)}
                        >
                            Comment trouver ma référence locataire
                        </Button>
                        <FindTenantReferenceModal
                            modalVisible={modalVisible}
                            setModalVisible={setModalVisible}
                        />

                        <Button
                            disabled={!isValid || !dirty}
                            onPress={handleSubmit}
                            style={styles.submitButton}
                        >
                            Suivant
                        </Button>
                    </ScrollView>
                )}
            </Formik>
        </KeyboardAvoidingView>

    )
}

export default TenantReference

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 30,
        paddingTop: 15
    },
    title: {
        marginTop: 23,
        marginBottom: 40,
        textAlign: "center",
        fontSize: fonts.h2,
        color: colors.primary300
    },
    label: {
        fontSize: fonts.body1,
        color: colors.primary300
    },
    textField: {
        marginVertical: 10
    },
    error: {
        color: colors.error,
        marginBottom: 10,
    },
    openModalButton: {
        fontSize: fonts.body1,
        color: colors.secondary300,
        justifyContent: "flex-start",
        padding: 0
    },
    submitButton: {
        marginTop: 10,
        width: "100%"
    },
    buttonIconStyle: {
        marginTop: 2,
        marginLeft: 5,
    },
})