import React, { useContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import AvatarIcon from "../../../assets/image/avatar-icon.svg";
import AvatarIcon2 from "../../../assets/image/avatar-icon2.svg";
import BinIcon from "../../../assets/image/bin-icon.svg";
import GoogleIcon from "../../../assets/image/google-icon.svg";
import AppleIcon from "../../../assets/image/apple-icon.svg";
import FacebookIcon from "../../../assets/image/facebook-icon.svg";
import RightArrow from "../../../assets/image/right-arrow-icon.svg";
import { colors } from '../../../core/constants/colors';
import { fonts } from '../../../core/constants/fonts';
import Button from '../../../core/ui/Button';
import Text from '../../../core/ui/Text';
import IconButton from '../../../core/ui/IconButton';
import UpdateContractorInfoModal from "./UpdateContractorInfoModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { UserContext } from '../../../core/contexts/userContext';

const ContractorCard = ({ contractPerson, style }) => {
    const [updateContractorModalVisible, setUpdateContractorModalVisible] = useState(false);
    const [personToUpdate, setPersonToUpdate] = useState(null);
    const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    const person = contractPerson.person;
    const born = ["M", "", null].includes(person.sex) ? "Né" : "Née";

    const { user, claims } = useContext(UserContext);
    const me = user;

    return (
        <View style={[styles.container, style]}>
            <View style={styles.row}>
                <AvatarIcon2  aria-label="icône avatar" />
                <Text style={styles.name}>
                    {person.title.trim()} {person.name.trim()} {person.lastName.trim()}
                </Text>
                <View style={styles.statusContainer}>
                    <Text style={styles.statusText}>Contractant</Text>
                </View>
            </View>

            <Text style={styles.infosText}>
                {born} le {person.birthDate && new Date(person.birthDate).toLocaleDateString('fr')}
            </Text>

            <View style={styles.centeredRow}>
                <Text style={styles.infos}>Email : </Text>
                <Text style={[styles.infos, !person.mail && styles.toComplete]}>{person.mail || 'À compléter'}</Text>
            </View>
            <View style={styles.centeredRow}>
                <Text style={styles.infos}>N° portable : </Text>
                <Text style={[styles.infos, !person.mobilePhone && styles.toComplete]}>{person.mobilePhone || 'À compléter'}</Text>
            </View>
            <View style={[styles.centeredRow, styles.row]}>
                <Text style={styles.infos}>N° domicile : </Text>
                <Text style={[styles.infos, !person.homePhone && styles.toComplete, { flex: 1 }]}>{person.homePhone || 'À compléter'}</Text>
                {claims?.persons?.includes(person.code) &&
                    <Button
                        variant="text"
                        endIcon={<RightArrow style={styles.icon} />}
                        style={styles.editButton}
                        onPress={() => { setPersonToUpdate({ person }); setUpdateContractorModalVisible(true) }}
                        accessibilityLabel="modifier les infos du contractant"
                    >
                        Modifier
                    </Button>
                }
            </View>

            {person.userPersons?.length > 0 &&
                <View>
                    <View style={styles.separatorLine} />
                    <Text style={styles.associateUser}>Utilisateur associé</Text>

                    {person.userPersons.map(i => i.user)?.map(user =>
                        <View style={[styles.centeredRow, styles.row]} key={user.id}>
                            {user?.provider?.[0] === 'google.com' && <GoogleIcon width={25} />}
                            {user?.provider?.[0] === 'apple.com' && <AppleIcon width={25} />}
                            {user?.provider?.[0] === 'facebook.com' && <FacebookIcon width={23} style={{ marginHorizontal: 1 }} />}
                            {user?.provider?.[0] === 'password' && <AvatarIcon height={35} width={23} style={{ marginHorizontal: 1 }} />}
                            <View style={[{ flex: 1 }]}>
                                <Text style={styles.userEmail}>{user?.email}</Text>
                                <Text style={styles.lastConnection}>
                                    {"Dernière connexion le "}
                                    {user?.lastLoginDate && new Date(user?.lastLoginDate).toLocaleDateString('fr')}
                                    {" à "}
                                    {user?.lastLoginDate && new Date(user?.lastLoginDate).toLocaleTimeString('fr', { timeStyle: 'short' })}
                                </Text>
                            </View>

                            {me?.id?.toString() !== user?.id?.toString() &&
                                <IconButton
                                    onPress={() => { setUserToDelete({ user, person }); setDeleteConfirmationModalVisible(true) }}
                                    icon={<BinIcon aria-label="icône poubelle" />}
                                    accessibilityLabel="supprimer l'utilisateur associé"
                                />
                            }
                        </View>
                    )}
                </View>
            }

            <UpdateContractorInfoModal
                modalVisible={updateContractorModalVisible}
                setModalVisible={setUpdateContractorModalVisible}
                inputData={personToUpdate}
            />
            <DeleteConfirmationModal
                modalVisible={deleteConfirmationModalVisible}
                setModalVisible={setDeleteConfirmationModalVisible}
                inputData={userToDelete}
            />
        </View>
    )
}

export default ContractorCard

const styles = StyleSheet.create({
    container: {
        padding: 20,
        marginTop: 10,
        borderRadius: 10,
        shadowColor: 'black',
        backgroundColor: colors.light,
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 3
    },
    row: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    centeredRow: {
        flexDirection: "row",
        // alignItems: "baseline",
    },
    name: {
        flex: 1,
        color: colors.primary300,
        fontSize: fonts.body2,
        marginLeft: 15
    },
    statusContainer: {
        backgroundColor: colors.secondary100,
        paddingVertical: 4,
        paddingHorizontal: 5,
        borderRadius: 7
    },
    statusText: {
        color: colors.secondary300,
        fontSize: fonts.subTitle,
    },
    infosText: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
        marginTop: 10,
        marginBottom: 5,
    },
    infos: {
        fontSize: fonts.body2,
        color: colors.primary300,
        marginVertical: 6,
    },
    toComplete: {
        color: colors.error,
    },
    editButton: {
        paddingVertical: 6,
    },
    separatorLine: {
        width: "100%",
        marginTop: 5,
        borderColor: colors.primary100,
        borderTopWidth: 1,
    },
    associateUser: {
        color: colors.primary300,
        fontSize: fonts.body2,
        marginVertical: 5
    },
    userEmail: {
        color: colors.primary300,
        fontSize: fonts.body2,
        marginLeft: 10,
        padding: 0
    },
    lastConnection: {
        color: colors.primary200,
        fontSize: fonts.subTitle,
        marginLeft: 10
    },
    icon: {
        marginTop: 3.5,
        marginLeft: 5
    }
})